import React from 'react'
import styled from 'styled-components'

import { Input } from "trainfes-components-library"
import Editor from "../../../components/editor"

import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css';

import * as Action from "../reducer/actions"

export const ModalNewProtocol = ({ t, state, dispatch }) => {
    return (
        <Container>
            <InputsContainer>
                <Input
                    placeholder={t('formProtocol.name')}
                    value={ state.protocolName }
                    onChange={ ({ target }) => dispatch({ type: Action.SET_FIELDS, payload: { field: "protocolName", value: target.value } }) }
                />
                <Input
                    placeholder={t('formProtocol.complement')}
                    value={ state.complementName }
                    onChange={ ({ target }) => dispatch({ type: Action.SET_FIELDS, payload: { field: "complementName", value: target.value } }) }
                />
            </InputsContainer>

            <SubTitle>{t('desc')}</SubTitle>
            <Editor 
                value={ state.protocolDescription } 
                onChange={ (value) => dispatch({ type: Action.SET_FIELDS, payload: { field: "protocolDescription", value } }) }
            />

            <SubTitle>{t('og')}</SubTitle>
            <Editor 
                value={ state.protocolObjGeneral } 
                onChange={ (value) => dispatch({ type: Action.SET_FIELDS, payload: { field: "protocolObjGeneral", value } }) }
            />
            
            <SubTitle>{t('oe')}</SubTitle>
            <Editor 
                value={ state.protocolObjSpecific } 
                onChange={ (value) => dispatch({ type: Action.SET_FIELDS, payload: { field: "protocolObjSpecific", value } }) }
            />

            <SubTitle>{t('np')}</SubTitle>
            <Editor 
                value={ state.protocolPublicNote } 
                onChange={ (value) => dispatch({ type: Action.SET_FIELDS, payload: { field: "protocolPublicNote", value } }) }
            />

            <SubTitle>{t('diagnosis')}</SubTitle>
            <TagsInput 
                value={ state.protocolTags } 
                onChange={(value) => dispatch({ type: Action.SET_FIELDS, payload: { field: "protocolTags", value } })} 
            />
        </Container>
    )
}

const SubTitle = styled.h6`
    margin: 1rem 0 0.5rem 0;
    color: #281b65;
`;

const Container = styled.div`
`;

const InputsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
`;