import styled from "styled-components";

// Modals
export const LabelTitle = styled.p`
    color: #281b65;
    font-size: 12px;
    font-weight: 500;
    margin: 15px 0 4px 0;
`;

export const HighlightedText = styled.p`
    margin: ${props => (props.last ? "0" : "0 0 0 20px")};
    font-size: 12px;
    color: ${props => (props.highlight ? "#49C39E" : "#9aa2a8")};
`;