export const LOADING = "[MediaCategories] Loading";

export const SET_CATEGORIES = "[MediaCategories] Set Categories";
export const SET_EVALUATIONS = "[MediaCategories] Set Evaluations";

export const SET_VARS_DATA = "[MediaCategories] Set Variables Data";

export const OPEN_MODAL = "[MediaCategories] Open Modals";

export const OPEN_EDIT = "[MediaCategories] Open Edit";
export const OPEN_PREVIEW = "[MediaCategories] Open Preview";
export const CLOSE_EDIT = "[MediaCategories] Close Edit";

export const REMOVE = "[MediaCategories] Delete Evaluation";

export const CLEAR_STATE = "[MediaCategories] Clear state";
export const CLEAR_FIELDS = "[MediaCategories] Clear fields";
export const SET_INPUT_FIELD = "[MediaCategories] Set Input fields";
export const SET_FIELDS = "[MediaCategories] Set fields";
export const EDITING_STATE = "[MediaCategories] EDITING_STATE";
