import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import Drawer from "../components/drawer";
import Avatar from "../components/avatar";
import { connect } from "../store";
// import { useTranslation } from "react-i18next";

const Navbar = (props) => {

  return (
    <>
      <nav className="navbar mx-auto">
        <Grid>
          <Hidden mdUp>
            <Drawer />
          </Hidden>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <div style={{ marginLeft: 20 }}>
            <Avatar />
          </div>
        </Grid>
      </nav>
    </>
  );
};

export default connect(Navbar);
