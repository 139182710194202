import React, { Component } from "react";

import TimePicker from "../../timePicker";
import Counter from "../../counter";
import { withTranslation } from "react-i18next";
import SentadoParado from "../../../assets/img/bloques/sitting-stationary.svg";
import Parado from "../../../assets/img/bloques/stationary.svg";
import ParadoSentado from "../../../assets/img/bloques/stationary-sitting.svg";

import {
  InputBorder,
  Textarea,
  Container,
  Row,
  Col,
  RadioTabTwo,
  Button,
  Slider,
  Modal,
} from "trainfes-components-library";

import * as S from "./Bipedestacion.styles";
import * as M from "../styles/Modos.styles";
import * as C from "../styles/configbutton.styles";

import { TableMuscles as WalkfesTable } from "../components/TableMuscles/bipedestacion/Walkfes";
import { TableMuscles as FES4WearTable } from "../components/TableMuscles/bipedestacion/FES4Wear";
import { TableMuscles as FES6AdvanceTable } from "../components/TableMuscles/bipedestacion/FES6Advance";
import { TableMuscles as FES6PROTable } from "../components/TableMuscles/bipedestacion/FES6PRO";
import { TabsContainer } from "../bike/bike.styles";
import { secondsToDate } from "../../../lib/modes.helpers";
import { ConfigIcon } from "../../../assets/icons";
import { MuscleSelector } from "../components/MuscleSelector";
import { musclesFiltered } from "../helpers/musclesFilter.helper";
import {
  musclesFES4,
  musclesFES6Advance,
  musclesFES6Pro,
  musclesWalkfes,
} from "./Bipedestacion.helpers";

var self;

class Bipedestacion extends Component {
  constructor() {
    super();
    this.state = {
      channels: [
        { ch: 1, name: "", index: 100 },
        { ch: 2, name: "", index: 100 },
        { ch: 3, name: "", index: 100 },
        { ch: 4, name: "", index: 100 },
        { ch: 5, name: "", index: 100 },
        { ch: 6, name: "", index: 100 },
      ],
      pausa_bipedestaciones: { hours: 0, minutes: 0, seconds: 0 },
      bipedestaciones: 0,
      sentado_parado: { hours: 0, minutes: 0, seconds: 0 },
      parado: { hours: 0, minutes: 0, seconds: 0 },
      parado_sentado: { hours: 0, minutes: 0, seconds: 0 },

      // Temp -> temporal para configuración de modal.
      frecuenciaTemp: 35,
      ancho_pulsoTemp: 200,

      frecuencia: 35,
      ancho_pulso: 200,

      time: "00:00:00",

      active_tab: "1",

      public_name: "",
      technical_name: "",
      description: "",
      musclesSelected: [
        {
          ch: 1,
          img: null,
          showImage: false,
        },
        {
          ch: 2,
          img: null,
          showImage: false,
        },
        {
          ch: 3,
          img: null,
          showImage: false,
        },
        {
          ch: 4,
          img: null,
          showImage: false,
        },
        {
          ch: 5,
          img: null,
          showImage: false,
        },
        {
          ch: 6,
          img: null,
          showImage: false,
        },
      ],
    };
  }

  componentDidUpdate() {
    this.values();
  }

  componentDidMount() {
    self = this;

    this.setState({ musclesSelected: musclesWalkfes(this.props.t) });

    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const {
        bipedestaciones,
        parado,
        parado_sentado,
        pausa_bipedestaciones,
        sentado_parado,
        technical_name,
        public_name,
        description,
        musclesSelected,
        config_channels,
        time,
        frecuencia,
        ancho_pulso,
        active_tab,
      } = this.props.data.params;

      this.setState(
        {
          bipedestaciones,
          parado,
          parado_sentado,
          pausa_bipedestaciones,
          sentado_parado,
          config_channels,
          technical_name: technical_name || "",
          public_name: public_name || "",
          description: description || "",
          time: time || "00:00:00",
        },
        () => this.setState({ time: this.getTotalTime() })
      );

      if (active_tab) {
        this.setState({ active_tab });
      } else {
        this.setState({ musclesSelected: musclesWalkfes(this.props.t) });
      }

      if (frecuencia) {
        this.setState({ frecuencia, frecuenciaTemp: frecuencia });
      }

      if (ancho_pulso) {
        this.setState({ ancho_pulso, ancho_pulsoTemp: ancho_pulso });
      }

      if (musclesSelected) {
        if (active_tab === "1")
          return this.setState({ musclesSelected: musclesWalkfes(this.props.t) });
        if (active_tab === "2")
          return this.setState({ musclesSelected: musclesFES4(this.props.t) });
        if (active_tab === "3")
          return this.setState({ musclesSelected: musclesFES6Advance(this.props.t) });
        if (active_tab === "4")
          return this.setState({ musclesSelected: musclesFES6Pro(this.props.t) });

        return this.setState({ musclesSelected });
      }

      this.getTotalTime();
    }
  }

  setMusclesSelected(data) {
    self.setState({ musclesSelected: data });
  }

  setConfigChannels(data) {
    self.setState({ config_channels: data });
  }

  setChannels(data) {
    self.setState({ channels: data });
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  values() {
    if (this.props.values !== undefined) {
      this.props.values({
        ...this.state,
        channels: this.state.channels.map((e) => (e.name !== "" ? true : false)),
        config_channels: this.state.channels.filter((e) => e.name !== ""),
      });
    }
  }

  getSeconds(state) {
    return state.seconds + state.minutes * 60 + state.hours * 3600 + 0;
  }

  getTotalTime() {
    const pausa_bipedestacionesSeconds =
      this.getSeconds(this.state.pausa_bipedestaciones) * (this.state.bipedestaciones - 1);

    const sentado_paradoSeconds = this.getSeconds(this.state.sentado_parado);

    const paradoSeconds = this.getSeconds(this.state.parado);

    const parado_sentadoSeconds = this.getSeconds(this.state.parado_sentado);

    const totalSeconds =
      (paradoSeconds + sentado_paradoSeconds + parado_sentadoSeconds) * this.state.bipedestaciones +
      pausa_bipedestacionesSeconds;

    this.setState({ time: secondsToDate(totalSeconds) });
    return secondsToDate(totalSeconds);
  }

  setShowConfigModal(bool) {
    self.setState({ showConfigModal: bool });
  }

  setFrecuencyTemp(value) {
    self.setState({ frecuenciaTemp: value });
  }

  setPulseWidthTemp(value) {
    self.setState({ ancho_pulsoTemp: value });
  }

  handleConfigSubmit() {
    self.setState({
      ancho_pulso: self.state.ancho_pulsoTemp,
      frecuencia: self.state.frecuenciaTemp,
    });
    self.setShowConfigModal(false);
  }

  render() {
    const { t } = this.props;

    const tableRender = {
      1: <WalkfesTable t={t} />,
      2: <FES4WearTable t={t} />,
      3: <FES6AdvanceTable t={t} />,
      4: <FES6PROTable t={t} />,
    };

    return (
      <M.ScreenContainer>
        <Container>
          <Row cols={12} gap={"50"}>
            <Col xs={5}>
              <S.BipedestacionForm>
                <InputBorder
                  placeholder={t("tech_name")}
                  value={this.state.technical_name}
                  onChange={({ target }) => this.setTechnicalName(target.value)}
                />
                <InputBorder
                  placeholder={t("public_name")}
                  value={this.state.public_name}
                  onChange={({ target }) => this.setPublicName(target.value)}
                  showRequired={false}
                />
                <Textarea
                  placeholder={t("description")}
                  value={this.state.description}
                  onChange={({ target }) => this.setDescription(target.value)}
                />
              </S.BipedestacionForm>
              <TabsContainer>
                <RadioTabTwo
                  label="Walkfes"
                  id="Walkfes"
                  value={"1"}
                  onChange={({ target }) => {
                    this.setState({ active_tab: target.value });
                    this.setState({ musclesSelected: musclesWalkfes(t) });
                  }}
                  checked={this.state.active_tab === "1"}
                />
                {/* <RadioTabTwo
                  label="FES-4 Wear"
                  id="FES-4 Wear"
                  value={"2"}
                  onChange={({ target }) => {
                    this.setState({ active_tab: target.value });
                    this.setState({ musclesSelected: musclesFES4(t) });
                  }}
                  checked={this.state.active_tab === "2"}
                /> */}
                <RadioTabTwo
                  label="FES-6 Advanced"
                  id="FES-6 Advanced"
                  value={"3"}
                  onChange={({ target }) => {
                    this.setState({ active_tab: target.value });
                    this.setState({ musclesSelected: musclesFES6Advance(t) });
                  }}
                  checked={this.state.active_tab === "3"}
                />
                {/* <RadioTabTwo
                  label="FES-6 Pro"
                  id="FES-6 Pro"
                  value={"4"}
                  onChange={({ target }) => {
                    this.setState({ active_tab: target.value });
                    this.setState({ musclesSelected: musclesFES6Pro(t) });
                  }}
                  checked={this.state.active_tab === "4"}
                /> */}
              </TabsContainer>
              {tableRender[this.state.active_tab]}
            </Col>

            <Col xs={7}>
              <C.ConfigTimeContainer style={{ marginBottom: "15px" }}>
                <M.TimeContainer>
                  <M.TimeTotal>{t("total_time")}:</M.TimeTotal>
                  <M.Box padding={"10px"}>
                    <M.TimeText>{this.state.time}</M.TimeText>
                  </M.Box>
                </M.TimeContainer>
                <C.ConfigButton onClick={() => this.setShowConfigModal(true)}>
                  <ConfigIcon />
                </C.ConfigButton>
              </C.ConfigTimeContainer>

              <S.Params>
                <M.Box padding={"0 15px 0 15px"}>
                  <M.BoxImage>
                    <img src={SentadoParado} className="img-fluid" alt="" />
                    <M.ComponentContainer>
                      <M.GridTitle>
                        {t("timeof")} {t("seated")} - {t("stopped")}
                      </M.GridTitle>
                      <div>
                        <Counter
                          data={this.state.sentado_parado.seconds}
                          title="Seg."
                          min={0}
                          max={3}
                          values={(value) => {
                            this.setState(
                              {
                                sentado_parado: { hours: 0, minutes: 0, seconds: value },
                              },
                              () => this.getTotalTime()
                            );
                          }}
                        />
                      </div>
                    </M.ComponentContainer>
                  </M.BoxImage>
                </M.Box>

                <M.Box padding={"0 15px 0px 15px"}>
                  <M.BoxImage>
                    <img src={Parado} className="img-fluid" alt="" />
                    <M.ComponentContainer>
                      <M.GridTitle>
                        {t("timeof")} {t("stopped")}
                      </M.GridTitle>
                      <TimePicker
                        data={this.state.parado}
                        seconds={true}
                        minutes={true}
                        values={(value) => {
                          this.setState({ parado: value }, () => this.getTotalTime());
                        }}
                      />
                    </M.ComponentContainer>
                  </M.BoxImage>
                </M.Box>

                <M.Box padding={"0px 15px 0px 15px"}>
                  <M.BoxImage>
                    <img src={ParadoSentado} className="img-fluid" alt="" />
                    <M.ComponentContainer>
                      <M.GridTitle>
                        {t("timeof")} {t("stopped")} - {t("seated")}
                      </M.GridTitle>
                      <Counter
                        data={this.state.parado_sentado.seconds}
                        title="Seg."
                        min={0}
                        max={3}
                        values={(value) =>
                          this.setState(
                            {
                              parado_sentado: { hours: 0, minutes: 0, seconds: value },
                            },
                            () => this.getTotalTime()
                          )
                        }
                      />
                    </M.ComponentContainer>
                  </M.BoxImage>
                </M.Box>

                <M.Box padding={"10px 15px 0px 15px"}>
                  <M.GridTitle>{t("number_bidepestation")}</M.GridTitle>
                  <M.SliderContainer margin="0">
                    <Counter
                      data={this.state.bipedestaciones}
                      title="0 - 100"
                      min={0}
                      max={100}
                      values={(value) =>
                        this.setState({ bipedestaciones: value }, () => this.getTotalTime())
                      }
                    />
                  </M.SliderContainer>
                </M.Box>

                <M.Box padding={"10px 15px 0px 15px"}>
                  <M.GridTitle>{t("pause_bipedestation")}</M.GridTitle>
                  <M.SliderContainer margin="0">
                    <TimePicker
                      data={this.state.pausa_bipedestaciones}
                      minutes={true}
                      seconds={true}
                      values={(value) =>
                        this.setState({ pausa_bipedestaciones: value }, () => this.getTotalTime())
                      }
                    />
                  </M.SliderContainer>
                </M.Box>
              </S.Params>

              <M.Title>{t("select_muscles")}</M.Title>

              {musclesFiltered(this.state.active_tab, this.state.musclesSelected).map(
                (channel, i) => (
                  <MuscleSelector
                    noModify
                    key={i}
                    index={i}
                    data={channel}
                    musclesSelected={this.state.musclesSelected}
                    setMusclesSelected={this.setMusclesSelected}
                    configChannels={this.state.config_channels || null}
                    setConfigChannels={this.setConfigChannels}
                    channels={this.state.channels}
                    setChannels={this.setChannels}
                  />
                )
              )}
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.showConfigModal}
          buttons={
            <>
              <Button
                type="ghost"
                width="142px"
                label={t("cancel")}
                onClick={() => {
                  this.setFrecuencyTemp(this.state.frecuencia);
                  this.setPulseWidthTemp(this.state.ancho_pulso);
                  this.setShowConfigModal(false);
                }}
              />
              <Button width="142px" label={t("save")} onClick={this.handleConfigSubmit} />
            </>
          }
        >
          <C.ModalConfigContainer>
            <M.Box padding={"10px 15px 0px 15px"}>
              <M.GridTitle>{t("frecuency")} (HZ)</M.GridTitle>
              <M.SliderIndicator>{this.state.frecuenciaTemp || 0}</M.SliderIndicator>
              <M.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.frecuenciaTemp}
                  min={5}
                  max={60}
                  onChange={(value) => this.setFrecuencyTemp(value)}
                />
              </M.SliderContainer>
            </M.Box>

            <M.Box padding={"10px 15px 0px 15px"}>
              <M.GridTitle>{t("pulse_width")} (US)</M.GridTitle>
              <M.SliderIndicator>{this.state.ancho_pulsoTemp || 0}</M.SliderIndicator>
              <M.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.ancho_pulsoTemp}
                  min={20}
                  max={400}
                  onChange={(value) => this.setPulseWidthTemp(value)}
                />
              </M.SliderContainer>
            </M.Box>
          </C.ModalConfigContainer>
        </Modal>
      </M.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(Bipedestacion);
