import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Slider from '../slider';
import UploadFiles from '../uploadFiles/index';
import TimePicker from '../timePicker';
import { withTranslation } from 'react-i18next'

// eslint-disable-next-line no-unused-vars
var frecuencia, ancho_pulso; var currentFile = null; var currentName = null;

class Tens extends Component {

    constructor() {
        super();
        this.state = {
            name: "",
            description: "",
            files: [],
            video: "",
            type: true,
            frecuencia: 35,
            ancho_pulso: 200,
            tiempo_canales: null,
            tiempo_trabajo: { hours: 0, minutes: 0, seconds: 0 },
            channels: []
        }

        this.currentName = React.createRef();
    }

    values() {
        let data = Object.assign({}, this.state);
        data['config_channels'] = this.state.channels.filter(e => e.index !== 100);
        if (this.props.values !== undefined) this.props.values(data);
    }

    componentDidUpdate() {
        this.values();
    }


    componentDidMount() {
        if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
            const { name, description, tiempo_trabajo, frecuencia, ancho_pulso, files } = this.props.data.params;
            this.setState({
                name,
                description,
                tiempo_trabajo,
                frecuencia,
                ancho_pulso,
                files
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} md={5} className="pl-4">
                    <p className="title-calendar mb-2">{t('name')}</p>
                    <Grid item xs={12}>
                        <TextField
                            className="w-100"
                            id="outlined-margin-dense1"
                            margin="dense"
                            variant="outlined"
                            value={this.state.name}
                            onChange={value => this.setState({ name: value.target.value })}
                        />
                    </Grid>
                    <p className="title-calendar mt-3 mb-2">{t('description')}</p>
                    <Grid item xs={12}>
                        <TextField
                            className="w-100"
                            id="outlined-margin-dense1"
                            margin="dense"
                            multiline
                            rows={7}
                            variant="outlined"
                            value={this.state.description}
                            onChange={value => this.setState({ description: value.target.value })}
                        />
                    </Grid>
                    <Grid container direction="row">
                        <p className="title-calendar my-3">{t('adjustments')}</p>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="border-tf py-2 px-3">
                            <p className="fz-10 color-sub-text m-0">{t('trainningsTime')}</p>
                            <TimePicker data={this.state.tiempo_trabajo} hours={true} minutes={true} seconds={true} values={value => this.setState({ tiempo_trabajo: value })} />
                        </div>
                    </Grid>
                    <Grid container direction="row">
                        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className="mt-2">
                            <Grid item xs={6} className="pr-1">
                                <div className="border-tf py-2 px-3">
                                    <p className="m-0 fz-10 color-sub-text">{t('frecuency')}</p>
                                    <p className="m-0 color-primario fz-16"><strong>{this.state.frecuencia}</strong></p>
                                    <Slider title="Frecuencia" min={5} max={60} defaultValue={this.state.frecuencia} onChange={value => this.setState({ frecuencia: value })} />
                                </div>
                            </Grid>
                            <Grid item xs={6} className="pl-1">
                                <div className="border-tf py-2 px-3">
                                    <p className="m-0 fz-10 color-sub-text">{t('pulse_width')}</p>
                                    <p className="m-0 color-primario fz-16"><strong>{this.state.ancho_pulso}</strong></p>
                                    <Slider title="Ancho de pulso" min={20} max={400} defaultValue={this.state.ancho_pulso} onChange={value => this.setState({ ancho_pulso: value })} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={7} className="px-4">
                    <UploadFiles value={this.state.files} title={t('uploads')} onChange={f => this.setState({ files: f })} />
                </Grid>
            </Grid>
        )
    }
}


export default withTranslation('sessionStudio')(Tens);