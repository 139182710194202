import styled from 'styled-components';
import { Col } from '../../components/grilla/grid.component';

export const TitleBar = styled.div`
    background: #281b65;
    padding: 20px 10px 20px 10px;
    color: white;
    height: 45px;
    display:flex;
    align-items: center;
`

export const ColBloks = styled.div`
    background: #f3f8ff;
    padding: 1rem;
    max-height: 83vh;
    overflow-y: auto;
    height:100%;
`

export const ColFunc = styled.div`
    background: #fff;
    padding: 1rem;
    max-height: 83vh;
    height:100%;
    overflow-y: auto;
`

export const ItemBlock = styled.div`
    width: 85px;
    height: 85px;
    background: #ffffff;
    color: #281b65;
    border-radius: 3px;
    padding: 5px;
    cursor: move;
    letter-spacing: 0.7px;
    position: relative;
    margin-bottom: 15px;
    text-align: center;

    p{
        color: rgb(40, 27, 101);
        font-size: 8px;
        margin-bottom: 0.5rem;
        letter-spacing: 0.7px;
    }
`


export const IconUseFes = styled.div`
    position: absolute;
    right: 1px;
    bottom: 0px;

    img {
        vertical-align: middle;
        border-style: none;
    }
`

export const IconBlock = styled.div`
    font-size: 40px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`

export const ItemFlux = styled.div`
    border: 0.5px solid ${props => props && props.isNew ? "#FDAD34" : "#D8E6F4"};
    box-shadow: 0px 1px 70px rgba(231, 236, 244, 0.5);
    border-radius: 3px;
    background: #FFFFFF;
    box-sizing: border-box;
    // padding: 15px;
    margin-bottom: 15px;
`

export const IndexPosition = styled.div`
    width: 15.94px;
    height: 15.94px;
    border-radius: 100px;
    background: #FDAD34;
    color:#fff;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 10px;
`

export const ColCenter = styled(Col)`
    display:flex;
    justify-content: center;
    min-height: 65px;
    flex-direction: column;
    position:relative;
    span{ font-size: 30px;}
`

export const NameItem = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.850962px;
    color: #281B65;
    height:18px;
    text-align: left;
`

export const TitleItems = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.850962px;
    color: #8598AD;
    height:18px;
`

export const TextItem = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.850962px;
    color: #000000;
    text-align: ${props => props && props.aling ? props.aling : 'center'};
`

export const TextItemTime = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.850962px;
    color: #FDAD34;
    text-align: center;
`

export const ButtonIconClose = styled.button`
    border: none;
    background: #fff;
    outline: 0;

    span{
        color:#ACB7CB;
        font-size: 10px;
    }

    &:hover span {
        color:#281B65;
    }

    &:hover svg path {
        fill:#281B65;
    }
`


export const InfoDaDContainer = styled.div`
    width:100%;
    height: 200px;
    position: relative;
`

export const InfoDaDBox = styled.div`
    position:absolute;
    left:0;
    right:0;
    margin: 0 auto;
    top: 250px;
    width:400;
    text-align: center;

    span{
        font-size: 35px;
    }

    p {
        font-size: 14px;
    }
`