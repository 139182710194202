import React, { useEffect, useReducer } from 'react';
import { connect } from '../../store';
import { useTranslation } from 'react-i18next';
import { useGetCategoriesEvaluations } from '../../hooks/useGetCategoriesEvaluations';

import {
	Container,
	Row,
	Col, ButtonCircle, Dropdown,
	SearchBar,
	TableServices,
	Modal,
	Button,
    Input,
    Textarea,
} from "trainfes-components-library";

import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import { IconsContainer, TableHeader } from '../../styles/views.styles';
import { evaluationsReducer, initialState } from './reducer';
import { CLEAR_STATE, SET_FIELDS, CLOSE_MODAL } from './reducer/actions';
import { filterValidation } from '../../lib/textValidation';
import { Delete } from '../../assets/icons/pages';
import { LabelTitle } from '../../styles/common.styles';

const Evaluations = (props) => {
    const { t } = useTranslation('evaluations');
    const { 
        CategoriesEvaluationsQuery, 
        addCategoriesEvaluations, 
        deleteCategoriesEvaluations,
        customLoading 
    } = useGetCategoriesEvaluations();

    const [state, dispatch] = useReducer(evaluationsReducer, initialState);

    const createVariable = () => {
		if (customLoading) return;
	
		const trimmedName = state.name.trim();
		const trimmedDesc = state.desc.trim();
		if (!trimmedName) {
			props.notify({ type: 'error', title: t('evaluations'), text: t('notify.name') });
			return false;
		}
		if (!trimmedDesc) {
			props.notify({ type: 'error', title: t('evaluations'), text: t('notify.desc') });
			return false;
		}
			
		try {
			addCategoriesEvaluations({ name: trimmedName, desc: trimmedDesc });
			dispatch({ type: CLEAR_STATE });
			props.notify({ type: 'success', title: t('evaluations'), text: t('notify.evaluation_created') });
		} catch (error) {
			props.notify({ type: 'error', title: t('evaluations'), text: t('notify.error_creating') });
		}
	}
	
	

    const handleOpenDelete = ( id ) => {
        dispatch({ type: SET_FIELDS, payload: { field: "idCurrent", value: id } })
		dispatch({ type: SET_FIELDS, payload: { field: "removeModal", value: true } })
    }

    const handleDeleteEvaluation = () => {
        if(customLoading) return;

        try {
            deleteCategoriesEvaluations( state.idCurrent )
            dispatch({ type: CLEAR_STATE })
            props.notify({ type: 'success', title: t('evaluations'), text: t('notify.evaluation_removed') })
        } catch (error) {
            props.notify({ type: 'error', title: t('evaluations'), text: t('notify.error_deleting') })
        }
    }

    const columns = [
		{ key: "name", text: t("name"), alignContent: "center", alignTitle: "center" },
		{ key: "desc", text: t("desc"), alignContent: "center", alignTitle: "center" },
		{
		  key: "controls", text: t("actions"), type: "custom", alignContent: "center", alignTitle: "center",
		  elem: (payload) => (
			<IconsContainer>
			  <Delete onClick={ () => handleOpenDelete( payload._id ) } />
			</IconsContainer>
		  ),
		},
	];

    const filterOptions = [
		{ label: t("name"), value: "name", key: "name" },
		{ label: t("desc"), value: "desc", key: "desc" },
	];

    useEffect(() => {
		handleFilter();
    }, [ state.inputFilter, CategoriesEvaluationsQuery.data ]);
	
	  const handleFilter = () => {
		let data = Object.assign( [], CategoriesEvaluationsQuery.data );
	
		if (state.inputFilter === "") {
		  return dispatch({
			type: SET_FIELDS,
			payload: {
			  field: "filteredEvaluations",
			  value: data.reverse(),
			},
		  });
		}

		if (state.dropdownFilter === "cat") {
			return dispatch({
			  type: SET_FIELDS,
			  payload: {
				field: "filteredEvaluations",
				value: data.reverse().filter((e) => {
					return filterValidation( e.cat.name ).includes( filterValidation( state.inputFilter ) )
				} ),
			},
		});
		  }
		
		return dispatch({
				type: SET_FIELDS,
				payload: {
				  field: "filteredEvaluations",
				  value: data.reverse().filter((e) => {
					return filterValidation( e[ state.dropdownFilter ] ).includes( filterValidation( state.inputFilter ) )
				  } ),
				},
			  })
	}

    return (
        <>
        <Container>
            <Row cols={12}>
                <Col xs={12}>
                    <CardLayoutPlatform
                        noPaddingBottom
                        loading={ customLoading }
                        title={ t('categories.evaluation_categories') }
                        floatButton={
                            <ButtonCircle onClick={ 
                                () => dispatch({ type: SET_FIELDS, payload: { field: "newDataModal", value: true } })  
                            } />
                        }
                    >
                        <TableHeader>
                            <p>{t("search_by")}</p>
                            <Dropdown
								height={38}
								options={filterOptions}
								value={ filterOptions.filter( (e) => e.value === state.dropdownFilter)[0] }
								onChange={(e) =>
									dispatch({ type: SET_FIELDS, payload: { field: "dropdownFilter", value: e.value } })
								}
							/>
							<SearchBar
								borderRadius="6px"
								height="38px"
								placeholder={t("search")}
								onChange={(e) =>
									dispatch({ type: SET_FIELDS, payload: { field: "inputFilter", value: e.target.value } })
								}
							/>
                        </TableHeader>

                        <TableServices
							data={ state.filteredEvaluations }
							columns={ columns }
							searchKeys="title mode"
							autoHeight={false}
							perPage={10}
							pagination={true}
							filterSelect={false}
							filterInput={false}
							perPageSelect={false}
							textEmptyData="Sin datos para mostrar"
						/>
                    </CardLayoutPlatform>
                </Col>
            </Row>
        </Container>

        <Modal
			title={ t("really_want_to_delete") + "?" }
			isOpen={ state.removeModal }
			buttons={
			<>
				<Button
					type="ghost"
					label={t("categories.cancel")}
					onClick={ () => dispatch({ type: CLOSE_MODAL }) }
				/>
				<Button
					label={t("categories.remove")}
					onClick={ handleDeleteEvaluation }
					disabled={ customLoading }
				/>
			</>
			}
		>
      	</Modal>

		<Modal
			title={ t('new_cat_eval') }
			isOpen={ state.newDataModal }
			buttons={
			<>
				<Button
					type="ghost"
					label={t("categories.cancel")}
					onClick={() => dispatch({ type: CLOSE_MODAL })}
				/>
				<Button
					label={t("categories.create")}
					onClick={ createVariable }
					disabled={ customLoading }
				/>
			</>
			}
		>
            <Input 
                placeholder={t('cat_eval_name')}
                onChange={ 
					({ target }) => dispatch({ type: SET_FIELDS, payload: { field: "name", value: target.value } })
				}
    		/>

            <LabelTitle>{t("desc")}</LabelTitle>
            <Textarea
                placeholder=""
                onChange={ 
                    ({ target }) => dispatch({ type: SET_FIELDS, payload: { field: "desc", value: target.value } })
                }
            ></Textarea>
      	</Modal>
        </>
    )

}

export default connect(Evaluations);