const createTraslateTraining = "Entrenamiento con";
const createTraslateBlock = "bloque de";

const registerModes = (t) => [
    {
        name: t("secuential"),
        useFes: true,
        desc: `${createTraslateTraining} ${t("secuential")}`,
        icon: "icon-sequential",
        type: "function",
        mode: "secuential",
        params: {},
    },
    {
        name: t("sensor"),
        useFes: true,
        desc: `${createTraslateTraining} ${t("sensor")}`,
        icon: "icon-sensor",
        type: "function",
        mode: "sensor",
        params: {},
    },
    {
        name: t("bike"),
        useFes: true,
        desc: `${createTraslateTraining} ${t("bike")}`,
        icon: "icon-bike",
        type: "function",
        mode: "bike",
        params: {},
    },

    {
        name: t("rowing"),
        useFes: true,
        desc: `${createTraslateTraining} ${t("rowing")}`,
        icon: "icon-rowing",
        type: "function",
        mode: "rowing",
        params: {},
    },
    {
        name: t("bipedestation"),
        useFes: true,
        desc: `${createTraslateTraining} ${t("bipedestation")}`,
        icon: "icon-bipedestation",
        type: "function",
        mode: "bipedestacion",
        params: {},
    },
    {
        name: t("general"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("general")}`,
        icon: "icon-evaluation",
        type: "function",
        mode: "general",
        params: {},
    },
    {
        name: t("tens"),
        useFes: true,
        desc: `${createTraslateBlock} ${t("tens")}`,
        icon: "icon-pain",
        type: "function",
        mode: "tens",
        params: {},
    },
    {
        name: t("flexibility"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("flexibility")}`,
        icon: "icon-flexibility",
        type: "function",
        mode: "flexibility",
        params: {},
    },
    {
        name: t("neuro"),
        useFes: true,
        desc: `${createTraslateBlock} ${t("neuro")}`,
        icon: "icon-stimulation",
        type: "function",
        mode: "neuromodulation",
        params: {},
    },
    {
        name: t("cardio"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("cardio")}`,
        icon: "icon-heart",
        type: "function",
        mode: "cardiovascular",
        params: {},
    },
    {
        name: t("warning"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("warning")}`,
        icon: "icon-alert",
        type: "function",
        mode: "warnings",
        params: {},
    },
    {
        name: t("pausa"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("pausa")}`,
        icon: "icon-pause",
        type: "function",
        mode: "pause",
        params: {},
    },
    {
        name: t("pdf_files"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("pdf_files")}`,
        icon: "icon-training-therapist",
        type: "function",
        mode: "records",
        params: {},
    },
    {
        name: t("video"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("video")}`,
        icon: "icon-video",
        type: "function",
        mode: "video",
        params: {},
    },

    {
        name: t("walkfes"),
        useFes: true,
        desc: `${createTraslateBlock} ${t("walkfes")}`,
        icon: "icon-march",
        type: "function",
        mode: "march",
        params: {},
    },
    {
        name: t("treadmill"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("treadmill")}`,
        icon: "icon-treadmill",
        type: "function",
        mode: "treadmill",
        params: {},
    },
    {
        name: t("biofeedback"),
        useFes: true,
        desc: `${createTraslateBlock} ${t("biofeedback")}`,
        icon: "icon-feedback",
        type: "function",
        mode: "biofeedback",
        params: {},
    },
    {
        name: t("cicloergometro"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("cicloergometro")}`,
        icon: "icon-cicloergometro",
        type: "function",
        mode: "cicloergometro",
        params: {},
    },
    {
        name: t("evaluations"),
        useFes: false,
        desc: `${createTraslateBlock} ${t("evaluations")}`,
        icon: "icon-form",
        type: "function",
        mode: "evaluations",
        params: {},
    },
];

export default registerModes;

// type: "function"

// name, useFes, desc, icon, mode, params, type, validators, module/block,
