import styled from "styled-components";

// Linea 143
export const CardRecords = styled.div``;

export const CardContainer = styled.div`
    border-radius: 4px;
    border: ${({ active }) =>
        active ? "0.5px solid #6d59c9" : "0.5px solid #ced4da"};
    box-shadow: 0px 1px 70px rgba(231, 236, 244, 0.5);
    margin: 10px 14px;
    padding: 14px 20px;
    overflow-x: hidden;

    &:hover {
        border: 1px solid #6d59c9;
    }
`;

export const SearchContainer = styled.div`
    margin: 14px 14px 0 14px;
`;

export const CardHeader = styled.div`
    align-items: center;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;

    & p {
        font-weight: 500;
        letter-spacing: 0.850962px;
        margin: 0;
    }

    & > .header--info {
        margin-right: 15px;
        overflow: hidden;

        & > ._title {
            color: #281b65;
            font-size: 16px;
            font-weight: 600;
        }
        
        & > ._sub-title {
            color: #182024;
            font-weight: 400;
            font-size: 14px;
            margin-top: 3px;
        }
    }

    & > ._icon {
        display: flex;
        position: absolute;
        right: 0;
        height: 10px;
        margin: auto 0;
        transform: rotate(180deg);
        transition: 0.3s;
        width: 10px;
        cursor: pointer;
    }

    & > ._active {
        transform: rotate(0deg);
        transition: 0.3s;
    }
`;

export const CardBody = styled.div`
    margin-top: 35px;

    & .padding-0 {
        padding: 0;
    }

    & ._icon {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
`;

export const DescriptionTitle = styled.h6`
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.850962px;
    color: #281b65;
`;

export const Hr = styled.hr`
    margin: 5px 0 5px 0;
`;

export const Description = styled.p`
    margin: 0 0 15px 0;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.850962px;
    color: #182024;
`;
