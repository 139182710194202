export const initialState = {
    isEditing: false,
    idCurrent: null, // id selected to Edit or Delete.

    isPosting: false,
  
    name:           "",
    description:    "",
    color:          "#5943C1",
    isMedic:        false,
    
    //   Modals
    removeModal: false,
    newSpecialityModal: false,
  
    //   Filters
    dropdownFilter: "name",
    inputFilter: "",
  
    filteredSpecialities: [],
  };
  
  export const CLEAR_STATE =        "[Specialities] Clear state to initial";
  export const EDITING_STATE =      "[Specialities] Set editing state";
  export const SET_FIELDS =         "[Specialities] Set fields";
  export const SET_INPUT_FIELDS =   "[Specialities] Set inputs fields";
  export const SET_SUBSIDIARY =     "[Specialities] Set Subsidiary";
  export const SET_SPECIALITIES =   "[Specialities] Set Specialities";
  export const SET_OPEN_MODAL =     "[Specialities] Set Open modals";

  
  export const reducer = (state, action) => {
    switch (action.type) {
      case EDITING_STATE:
        return {
          ...state,
          isEditing: true,
          idCurrent: action.payload._id,

          newSpecialityModal: true,

          name: action.payload.name               || "",
          color: action.payload.color             || "#5943C1",
          isMedic: action.payload.isMedic         || false,
          description: action.payload.desc || "",
        };
  
      case CLEAR_STATE:
        return {
          ...state,
          isEditing: false,
          idCurrent: null, // id selected to Edit or Delete.

          isPosting: false,
    
          name: "",
          description: "",
          color: "#5943C1",
          isMedic: false,
        
          specialities: [],
    
          removeModal: false,
          newSpecialityModal: false,
    
          // dropdownFilter: "name",
          // inputFilter: "",
          // filteredSpecialities: [],
        };
  
      case SET_FIELDS:
        return {
          ...state,
          [action.payload.field]: action.payload.value,
        };
  
      case SET_INPUT_FIELDS:
        return {
          ...state,
          [action.payload.input]: action.payload.value,
        };
  
      case SET_OPEN_MODAL:
        return {
          ...state,
          [action.payload.modal]: action.payload.value,
        };
  
      default:
        return state;
    }
  };
  