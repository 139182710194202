import React, { useState, useEffect, useCallback } from "react";
import { connect } from "../../store";
import { useHistory } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(theme => ({

    root: {
        minWidth: '550px',
        maxWidth: '700px',
        padding: "20px"
    },
    buttons: {
        padding: '16px 24px',
    },
    buttonsIcons: {
        color: '#281B65',
        fontSize: 30,
        width: '60px',
        height: '60px',
        background: '#FFFFFF',
        boxShadow: '0px 5px 30px rgba(221, 221, 221, 0.6)',
        borderRadius: '3px',
        padding: '20px',
        border: 'none',
        margin: '10px',
        marginTop: '40px',
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            backgroundColor: '#281B65',
            color: '#FFF',
        },
    },


}));

export const ModalPrompt = (props) => {
  const { t } = useTranslation("global");
  const [active, setActive] = useState(false);
  const classes = useStyles();

  useEffect(() => setActive(props.open), [props.open]);

  const handleClose = () => {
    if (props.cancel !== undefined) props.cancel();
    setActive(false);
  };

  const action = () => {
    if (props.action !== undefined) props.action();
    setActive(false);
  };

  return (
    <Dialog
      open={active}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent className={classes.root}>
        {props.children}
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <button className="btn-tf" onClick={() => handleClose()}>
          {props.textCancel || t("prompt.cancel")}
        </button>
        {props.onlyCancel !== true ? (
          <button className="btn-white-tf" onClick={() => action()}>
            {props.textAction || t("prompt.accept")}
          </button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export const RouterPrompt = (props) => {
  const { onOK, onCancel, title, okText, cancelText, message } = props;
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (props.store.active_prompt) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, props.store.active_prompt]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <ModalPrompt
      open={showPrompt}
      title={title}
      action={handleOK}
      textAction={okText}
      cancel={handleCancel}
      textCancel={cancelText}
    >
      {message || props.store.message_prompt}
    </ModalPrompt>
  ) : null;
};

export default connect(RouterPrompt);
