import styled from 'styled-components'

export const FlexContent = styled.div`
    display:flex;
    width: 100%;
    height: 100%;
    min-height: 60px;
    justify-content: ${props => props && props.justifyContent ? `${props.justifyContent}` : "initial"};
    align-items: ${props => props && props.alignItems ? `${props.alignItems}` : "initial"};
`

export const WrapperTabFlow = styled.div`
    background: #FFF;
    border-radius: 3px;
    padding: 0 15px;
`

export const HeaderTabFlow = styled.div`
    text-align: ${props => props && props.textAlign ? props.textAlign : "left"};
    background: ${props => props && props.background ? props.background : '#E4EEFA'};
    padding: ${props => props && props.padding ? props.padding : '15px 0'};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #273142;
`

export const WrapperTabFlowItem = styled.div`
    padding: 12px 0;
`

export const WrapperTabFlowItemHeader = styled.div`
    padding: 0px 0;
`

export const WrapperTabFlowItemSession = styled.div`
    padding: 0px 0;
`

export const PlanningStatusName = styled.div`
    border-radius: 3px;
    background:#fff;
    border: 0.5px solid #9CB2CD;
    border-left: 4px solid ${props => props && props.color ? props.color : "#9CB2CD"};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #273142;
    padding: 12px;
    width: 100%;
    display:flex;
    align-items: center;
`

export const ComplianceTotalBar = styled.div`
    width: 100%;
    border-radius: 3px;
    background:#eee;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

export const ComplianceBarProgess = styled.div`
    width: ${props => props && props.percent ? `${props.percent}%` : 0};
    background: ${props => props && props.color ? props.color : '#FAFAFA'};
    transition: all 0.9s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justifyContent: center;
    color: #FFF;
    height: 100%;
    p { margin: 0; font-size: 8px; font-weight: 600; text-align:center;}
    p.amount { margin: 0; font-size: 10px; font-weight: 600; text-align:center;}
    div{display:flex; justify-content:center;align-items: center; flex-direction: column;padding-top:8px;}
`


export const SessionStatusName = styled.div`
    border-radius: 3px;
    background:#fff;
    border: 0.5px solid #9CB2CD;
    border-left: 4px solid ${props => props && props.color ? props.color : "#9CB2CD"};
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #273142;
    padding: 12px;
    width: 100%;
    display:flex;
    align-items: center;
`

export const SessionStatusBar = styled.div`
    border-radius: 3px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #273142;
    padding: 5px;
    width: calc(100% - 20px);
    display:flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background: ${props => props && props.color ? props.color : "#4666DD"}
`


export const DropdownMain = styled.div`
    max-height: ${props => props && props.active ? "100000px" : "0px"};
    height: auto;
    overflow: hidden;
    // transition: max-height 1s ease;
    animation: ${props => props && props.active ? "animateToBottom" : "animateFromBottom"};  .3s;

    @keyframes animateFromBottom {
        from {
          transform: translate3d(0, 10px, 0);
          opacity: 0
        }
        to {
          transform: translate3d(0, 0, 0);
          opacity: 1
        }
      }
      
    @keyframes animateToBottom {
        from {
            transform: translate3d(0, 0, 0);
            opacity: 1
        }
        to {
            transform: translate3d(0, 10px, 0);
            opacity: 0
        }
    }
`

export const ButtonMore = styled.button`
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    color:#FFF;
    width:100%;
    padding: 12px 8px;
`

export const BoxStatus = styled.div`
    background: ${props => props && props.color ? props.color : "#4666DD"};
    width:100%;
    border:none;
    height: 46px;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#fff;
    border-radius: 3px;
`

export const BoxDataUser = styled.div`
    background: #FFF;
    height: 46px;
    display: flex;
    align-items:center;
`

export const HeaderWrapperTabFlow = styled.div`
    background: #E4EEFA;
    color: #273142;
    font-weight: 700;
    padding: 0 15px;
    margin-top: 15px;
`