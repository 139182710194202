class DragAndDrop {
  static dragstart_handler(ev, data, self) {
    data["id"] = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    ev.dataTransfer.effectAllowed = "copy";
    ev.dataTransfer.setData("text/plain", JSON.stringify(data));
    self.changeBackgroundColor(true);
  }

  static dragover_handler(ev) {
    ev.preventDefault();
  }

  static dragEnd(ev, self) {
    self.changeBackgroundColor(false);
  }

  static drop_handler(ev, self) {
    ev.preventDefault();
    let data = ev.dataTransfer.getData("text");
    ev.dataTransfer.dropEffect = "copy";
    data = JSON.parse(data);
    if (data.type === "function") self.openParams(data);
  }

  static dragstart(ev, data) {
    // this.state.flux.find((e, i) => {
    //     if (e.id === data.id) {
    //         this.setState({ active: i });
    //     }
    // });
    ev.dataTransfer.setData("text/plain", JSON.stringify(data));
    ev.dataTransfer.effectAllowed = "move";
    ev.dataTransfer.clearData();
  }

  static dragover(ev) {
    ev.dataTransfer.dropEffect = "move";
    ev.preventDefault();
    // let target = ev.target;
    // let id = target.getAttribute("tr");
    // this.state.flux.find((e, i) => {
    //     if (e.id === id) {
    //         this.setState({ position: i });
    //     }
    // });
    return false;
  }

  static drop(ev, self) {
    ev.preventDefault();
    // eslint-disable-next-line no-unused-vars
    let data = ev.dataTransfer.getData("text");
    data = JSON.parse(data);
    let newData = self.state.flux.slice();
    newData.splice(
      self.state.active,
      0,
      newData.splice(self.state.position, 1)[0]
    );
    self.setState({ flux: newData });
  }

  static dragenter(ev) {}
}

export default DragAndDrop;

export class DaD {
  static onDragStart(ev, data, type, cb) {
    data["id"] = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    data["type"] = type;
    ev.dataTransfer.effectAllowed = "copy";
    ev.dataTransfer.setData("text/plain", JSON.stringify(data));
    if (cb !== undefined) cb();
  }

  static onDragEnd(ev, cb) {
    if (cb !== undefined) cb();
  }

  static onDrop(ev, cb) {
    ev.preventDefault();
    const data = JSON.parse(ev.dataTransfer.getData("text"));
    let target = null;

    if (data.type === "move") {
      ev.dataTransfer.dropEffect = "move";
      ev.preventDefault();
      target = ev.target;
    }

    if (cb !== undefined) cb(data, target);
  }

  static onDragOver(ev) {
    ev.preventDefault();
  }
}
