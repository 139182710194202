import { rutValidation } from "../../lib/validation.helpers";

export const FORM_INITIAL_VALUE = {
  nationality: "",
  tenantId: "",
  rut: "",
  name: "",
  lastname: "",
  email: "",
  phone: "",
  other_address: "",
  genre: "",
  birthdate: "",
  clinic: "",
  insurance_company: "",
  treating_doctor: "",
  years_injury: "",
  injury_years: "",
  rol: "patient",
  reference: "",
};

export const fase1 = [
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
];

export const filterValidation = (e) => {
  return e.split(" ")
          .join("")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
}

export const formValidation = ( state, notify, data, t ) => {

  if (!state.currentRol) {
    notify({
      type: "error",
      title: t("create_patient:notify.role_required"),
      text: t("create_patient:notify.role_required"),
    });
    return;
  }

  if (!rutValidation("CL", data.rut)) {
    notify({
      type: "error",
      title: t("create_patient:notify.invalid_id"),
      text: t("create_patient:notify.invalid_id"),
    });
    return;
  }

  if (state.country.trim() === "") {
    notify({
      type: "error",
      title: t("create_patient:notify.err"),
      text: t("create_patient:notify.country"),
    });
    return;
  }

  if (state.region.trim() === "") {
    notify({
      type: "error",
      title: t("create_patient:notify.err"),
      text: t("create_patient:notify.state"),
    });
    return;
  }

  if (state.address.trim() === "") {
    notify({
      type: "error",
      title: t("create_patient:notify.err"),
      text: t("create_patient:notify.address"),
    });
    return;
  }

  return true
}