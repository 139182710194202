import React, { cloneElement, Children } from 'react'
import { ContainerStyled, RowStyled, ColStyled } from './grid.styled'

export const Container = ({ children, ...props }) => {
    return (
        <ContainerStyled {...props}>
            {children}
        </ContainerStyled>
    )
}


export const Row = ({ children, gap, cols, gap_bottom, ...props }) => {
    return (
        <RowStyled {...props}>
            {Children.map(children, ((child, i) => cloneElement(child, { gap: gap, cols: cols, gap_bottom: gap_bottom })))}
        </RowStyled>
    )
}

Row.defaultProps = {
    gap: 24,
    cols: 12,
    gap_bottom: 0
}

export const Col = ({ children, ...props }) => {
    return (
        <ColStyled {...props}>
            {children}
        </ColStyled>
    )
}