import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "trainfes-components-library";
import API from "../../../../services/api";
import LoaderContainer from "../../../../components/loader/container";
import { connect } from "../../../../store";
import CardComponent from "./components/Card";
import FindElectrodes from "../../components/FindElectrodesModal";
import { CardsContainer } from "../../Library.styles";

const api = new API();

const INITIAL_STATE = {
  name: "",
  category: null,
  description: "",
  left: null,
  normal: null,
  right: null,
};

const Electrodes = (props) => {
  const { showModal, setShowModal } = props;

  const { t } = useTranslation("library");
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);

  const handleGetData = () => {
    setLoading(true);
    api
      .getAllPositioning()
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const [positioning, setPositioning] = useState(INITIAL_STATE);

  const handleValidationElectrodes = () => {
    const { name, description, left, normal, right, category } = positioning;
    if (positioning.left && positioning.left !== "null") {
      if (!positioning.left.type.includes("image")) {
        props.notify({
          type: "error",
          title: t("notify.error_title"),
          text: t("notify.image_type"),
        });
        return;
      }
    } 
    if (positioning.right && positioning.right !== "null") {
      if (!positioning.right.type.includes("image")) {
        props.notify({
          type: "error",
          title: t("notify.error_title"),
          text: t("notify.image_type"),
        });
        return;
      }
    }
    if (positioning.normal && positioning.normal !== "null") {
      if (!positioning.normal.type.includes("image")) {
        props.notify({
          type: "error",
          title: t("notify.error_title"),
          text: t("notify.image_type"),
        });
        return;
      }
    }

    if (positioning.left && positioning.left !== "null") {
      if ( !positioning.left.name.includes(".jpg") &&
        !positioning.left.name.includes(".jpeg") &&
        !positioning.left.name.includes(".png") &&
        !positioning.left.name.includes(".gif") &&
        !positioning.left.name.includes(".svg") &&
        !positioning.left.name.includes(".webp") &&
        !positioning.left.name.includes(".bmp") &&
        !positioning.left.name.includes(".ico") &&
        !positioning.left.name.includes(".tiff") &&
        !positioning.left.name.includes(".tif")
      ) {
        props.notify({
          type: "error",
          title: t("notify.error_title"),
          text: t("notify.image_type"),
        });
        return;
      }
    }

    if (positioning.right && positioning.right !== "null") {
      if ( !positioning.right.name.includes(".jpg") &&
        !positioning.right.name.includes(".jpeg") &&
        !positioning.right.name.includes(".png") &&
        !positioning.right.name.includes(".gif") &&
        !positioning.right.name.includes(".svg") &&
        !positioning.right.name.includes(".webp") &&
        !positioning.right.name.includes(".bmp") &&
        !positioning.right.name.includes(".ico") &&
        !positioning.right.name.includes(".tiff") &&
        !positioning.right.name.includes(".tif")
      ) {
        props.notify({
          type: "error",
          title: t("notify.error_title"),
          text: t("notify.image_type"),
        });
        return;
      }
    }

    if (positioning.normal && positioning.normal !== "null") {
      if ( !positioning.normal.name.includes(".jpg") &&
        !positioning.normal.name.includes(".jpeg") &&
        !positioning.normal.name.includes(".png") &&
        !positioning.normal.name.includes(".gif") &&
        !positioning.normal.name.includes(".svg") &&
        !positioning.normal.name.includes(".webp") &&
        !positioning.normal.name.includes(".bmp") &&
        !positioning.normal.name.includes(".ico") &&
        !positioning.normal.name.includes(".tiff") &&
        !positioning.normal.name.includes(".tif")
      ) {
        props.notify({
          type: "error",
          title: t("notify.error_title"),
          text: t("notify.image_type"),
        });
        return;
      }
    }

    if (name.trim() === "" || description.trim() === "" || !category) {
      props.notify({
        type: "error",
        title: t("electrode_positioning"),
        text: t("notify.input_missed"),
      });
      return;
    }

    if (
      (!normal || normal === "null") &&
      (!left || left === "null") &&
      (!right || right === "null")
    ) {
      props.notify({
        type: "error",
        title: t("electrode_positioning"),
        text: t("notify.images_missed"),
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("left", left);
    formData.append("normal", normal);
    formData.append("right", right);
    formData.append("category", category.value);

    if (normal && normal !== "null") {
      if (left && left !== "null") {
        if (!right || right === "null") {
          props.notify({
            type: "error",
            title: t("electrode_positioning"),
            text: t("notify.right_image_missed"),
          });
          return;
        }
      }

      if (right && right !== "null") {
        if (!left || left === "null") {
          props.notify({
            type: "error",
            title: t("electrode_positioning"),
            text: t("notify.left_image_missed"),
          });
          return;
        }
      }

      try {
        setLoading(true);
        setShowModal(false);
        api
          .newPositioning(formData)
          .then(() => {
            setShowModal(false);
          })
          .finally(() => {
            handleGetData();
            setPositioning(INITIAL_STATE);
          });

        props.notify({
          type: "success",
          title: t("notify.success"),
          text: t("notify.success"),
        });
        return;
      } catch (error) {
        props.notify({
          type: "error",
          title: t("electrode_positioning"),
          text: t("notify.error"),
        });
        return;
      }
    }

    if (left && left !== "null") {
      if (!right || right === "null") {
        props.notify({
          type: "error",
          title: t("electrode_positioning"),
          text: t("notify.right_image_missed"),
        });
        return;
      }
    }

    if (right && right !== "null") {
      if (!left || left === "null") {
        props.notify({
          type: "error",
          title: t("electrode_positioning"),
          text: t("notify.left_image_missed"),
        });
        return;
      }
    }

    try {
      setLoading(true);
      setShowModal(false);
      api
        .newPositioning(formData)
        .then(() => {
          setShowModal(false);
        })
        .finally(() => {
          handleGetData();
          setPositioning(INITIAL_STATE);
        });

      props.notify({
        type: "success",
        title: t("notify.success"),
        text: t("notify.success"),
      });
      return;
    } catch (error) {
      props.notify({
        type: "error",
        title: t("electrode_positioning"),
        text: t("notify.error"),
      });
      return;
    }
  };

  const handlePositionFilter = (f, positionFilter) => {
    if (positionFilter === "") return f;
    if (f[positionFilter] && f[positionFilter] !== "null") return f;
  };

  return (
    <>
      <LoaderContainer active={loading}>
        <CardsContainer>
          {data &&
            data
              .filter(
                (f) =>
                  f.category.includes(props.sidebarFilterState.category) ||
                  props.sidebarFilterState.category === ""
              )
              .filter((f) => handlePositionFilter(f, props.sidebarFilterState.position))
              .filter(
                (f) =>
                  f.name
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(
                      props.filter
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .toLowerCase()
                    ) || props.filter === ""
              )
              .map((card) => (
                <CardComponent
                  key={card._id}
                  data={card}
                  handleGetData={handleGetData}
                  setLoading={setLoading}
                  t={t}
                />
              ))}
        </CardsContainer>
      </LoaderContainer>
      <Modal
        title={t("search_your_electrode_positioning")}
        width="fit-content"
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        buttons={
          <>
            <Button
              type="ghost"
              label={t("cancel")}
              onClick={() => {
                setPositioning(INITIAL_STATE);
                setShowModal(false);
              }}
              width="142px"
            />
            <Button label={t("save")} width="142px" onClick={() => handleValidationElectrodes()} />
          </>
        }
      >
        <FindElectrodes t={t} positioning={positioning} setPositioning={setPositioning} />
      </Modal>
    </>
  );
};

export default connect(Electrodes);
