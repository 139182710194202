import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Selet from '../selet';
import ButtonAdd from '../buttonAdd';


const tipo = [
    {
        value: 'Texto',
        label: 'Texto',
    },
    {
        value: 'Numerico',
        label: 'Numérico',
    },
    {
        value: 'Seleccion Multiple',
        label: 'Selección Única',
    },
];


class FormSpecific extends Component {

    constructor() {
        super();
        this.state = {
            name: "",
            type: tipo[0].value,
            fields: [],
            quest1: "",
            quest2: "",
            quest3: "",
            channels:[]
        }
    }

    values() {
        let data = Object.assign({}, this.state)
        data['config_channels'] = this.state.channels.filter(e => e.index !== 100);
        if (this.props.values !== undefined) this.props.values(data)
    }

    componentDidUpdate() {
        this.values();
    }

    add() {
        if (this.multiple()) {
            this.setState((state, prev) => {
                state.fields.push({ name: this.state.name, type: this.state.type, seleccion: [this.state.quest1, this.state.quest2, this.state.quest3] })
                return state;
            })
        } else {
            this.setState((state, prev) => {
                state.fields.push({ name: this.state.name, type: this.state.type })
                return state;
            })
        }
    }

    multiple() {
        return this.state.type === "Seleccion Multiple" ? true : false;
    }

    render() {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={12} md={8} container direction="row" justifyContent="center" alignItems="center" className="relative">
                    <Grid item xs={12} md={5}>
                        <h6 className="title-calendar mb-2">Nombre</h6>
                        <TextField
                            className="w-100 mb-4"
                            id="outlined-margin-dense1"
                            margin="dense"
                            variant="outlined"
                            onChange={value => this.setState({ name: value.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className="pl-3">
                        <h6 className="title-calendar mb-2">Tipo</h6>
                        <Selet
                            data={tipo}
                            default={tipo[0].value}
                            value={(value) => this.setState({ type: value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <ButtonAdd action={() => this.add()} />
                    </Grid>

                    {this.multiple() ? <Grid item xs={12} md={12} container direction="row" justifyContent="center" alignItems="center" spacing={3} className="mt-3 relative">
                        <Grid item xs={12} md={4}>
                            <TextField
                                className="w-100 mb-4"
                                id="outlined-margin-dense2"
                                margin="dense"
                                variant="outlined"
                                onChange={value => this.setState({ quest1: value.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                className="w-100 mb-4"
                                id="outlined-margin-dense3"
                                margin="dense"
                                variant="outlined"
                                onChange={value => this.setState({ quest2: value.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                className="w-100 mb-4"
                                id="outlined-margin-dense4"
                                margin="dense"
                                variant="outlined"
                                onChange={value => this.setState({ quest3: value.target.value })}
                            />
                        </Grid>
                    </Grid> : null}
                </Grid>
                <br />
                {this.state.fields.map((e, i) => {
                    return (
                        <Grid style={{ margin: "10px 0" }} key={i} item xs={12} md={8} container direction="row" justifyContent="center" alignItems="center" spacing={3} >
                            <Grid item xs={12} md={5}>
                                <h6 className="m-0 color-primario">{e.name}</h6>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <h6 className="m-0 color-primario">{e.type}</h6>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }
}


export default FormSpecific;