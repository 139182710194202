import styled from "styled-components";

const MainNoInfo = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    & > p {
        font-size: 14px;
        color: #281B65;
    }
`;

export const SelectProtocol = styled(MainNoInfo)`
    min-height: 400px;
`;

export const NoItemToDisplay = styled(MainNoInfo)`
    min-height: 200px;
`;

export const Description = styled.span`
	margin: 0 0 15px 0;
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	letter-spacing: 0.850962px;
	color: #182024;
`;

export const DropdownContainer = styled.div`
    margin: 18px 0 34px 0;
`;
