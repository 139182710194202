import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import * as S from "./styles";
import LoaderContainer from "../../components/loader/container";

const colors = {
  primary: "#281b65",
  yellow: "#FDAD34",
};

const CardLayoutPlatform = ({
  title,
  colorTitle,
  backgroundColor,
  padding,
  header,
  children,
  loading,
  floatButton,
  sidebarContent,
  showSidebar,
  sidebarTitle,
  handleShowSidebar,
  handleCloseSidebar,
  noPaddingBottom,
  footer,
  overflowVirtuoso,
  fontWeight,
}) => {
  const { t } = useTranslation("registers");
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleCloseSidebar && handleCloseSidebar();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [handleCloseSidebar]);

  const closeSidebar = () => {
    handleCloseSidebar();
  };

  const [height, setHeight] = useState(0);
  const refHeight1 = useRef();

  useEffect(() => {
    setHeight(refHeight1.current.clientHeight);
  }, [loading]);

  return (
    <S.CardLayoutPlatformStyled>
      <div ref={refHeight1}>
        <S.Title bg={colors[backgroundColor]}>
          <p style={{ color: colorTitle, fontWeight: fontWeight }}>{title}</p>
        </S.Title>
        {header ? (
          <div id="cardlayoutplatform--header">{!loading && header}</div>
        ) : null}
      </div>
      <S.Body id="card-layout-platform-children" headerHeight={height} padding={padding} overflowVirtuoso={overflowVirtuoso}>
        <LoaderContainer active={loading} text={t("loader")}>
          <S.SidebarContainer showSidebar={showSidebar} onClick={closeSidebar}>
            <div
              ref={ref}
              className="cardlayout--sidebar"
              onClick={(e) => e.stopPropagation()}
            >
              <S.SidebarTitle>{sidebarTitle}</S.SidebarTitle>

              {sidebarContent}
            </div>
          </S.SidebarContainer>

          {loading ? null : <S.Children noPaddingBottom={noPaddingBottom}>{children}</S.Children>}
          
          {floatButton && <S.FloatButton>{floatButton}</S.FloatButton>}
          
          {footer && ( <S.SpaceFooter /> )}

        </LoaderContainer>
      </S.Body>
      {footer && (
        <S.Footer>
          {footer}
        </S.Footer>
      )}
    </S.CardLayoutPlatformStyled>
  );
};

export default CardLayoutPlatform;

CardLayoutPlatform.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  backgroundColor: PropTypes.oneOf(["primary", "yellow", "white"]),
  padding: PropTypes.string,
};

CardLayoutPlatform.defaultProps = {
  title: "Título",
  backgroundColor: "primary",
  loading: false,
  padding: "0 14px 0 14px",
};
