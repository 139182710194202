import jwt_decode from "jwt-decode";
import { Route, Redirect } from "react-router-dom";
import React from "react";

const key = "token";

export const grantPermission = (requestedRoles = []) => {
  if (requestedRoles.length > 0) {
    if (window.localStorage.getItem(key)) {
      const payload = jwt_decode(window.localStorage.getItem(key));
      const permittedRoles = payload.user.rol;

      if (requestedRoles.includes(permittedRoles)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const UnlockAccess = ({ children, request }) => {
  const permission = grantPermission(request); // request = ['ROLE_ADMIN'] / ['ROLE_USER'] / ['ROLE_MANAGER']
  return <>{permission && children}</>;
};

export const RoleBasedRouting = ({ component: Component, roles, redirect, ...rest }) => {
  return (
    <>
      {grantPermission(roles) ? (
        <Route
          {...rest}
          render={(props) => (
            <>
              <Component {...props} />
            </>
          )}
        />
      ) : (
        <Route
          render={() => (
            <>
              <Redirect to={redirect} />
            </>
          )}
        />
      )}
      {/* {
                !grantPermission(roles) && (
                    <Route
                        render={() => (
                            <>
                                <Redirect to={redirect} />
                            </>
                        )}
                    />
                )
            } */}
    </>
  );
};
