import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from "react-google-charts";

export const ChartDonut = (props) => {
    return (
        <div className="chart" style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Doughnut
                width={170}
                height={170}
                data={{
                    labels: props.labels,
                    datasets: [{
                        data: props.data,
                        backgroundColor: ["#49C39E", "#4666DD", "#FF7F23", "#FD0202"],
                        fill: ['rgba(30, 202, 78, 1)'],
                        pointBackgroundColor: ['rgba(41, 51, 138, 1)', 'rgba(41, 51, 138, 1)'],
                        lineTension: 0.1,
                        borderWidth: 1,
                        hoverBackgroundColor: ["#49C39E", "#4666DD", "#FF7F23", "#FD0202"],
                    }]
                }}
                options={{
                    animation: { animateRotate: false, animateScale: false },
                    responsive: false,
                    cutout: 65,
                    plugins: {
                        legend: { display: false, position: 'top' },
                        tooltip: {
                            callbacks: {
                                label: (context) => ` ${context.label}: ${context.parsed}%`
                            }
                        }
                    },
                }}
            />
            {props.enableValue ? <div style={{ position: "absolute", marginTop: 5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <p style={{ fontSize: 16, margin: 0, fontWeight: "bold", color: "#281B65", lineHeight: "150%" }}><span style={{ fontSize: 32 }}>{props.value}</span>%</p>
            </div> : null}
        </div>
    )
}

ChartDonut.defaultProps = {
    labels: ['Realizadas', 'Próximas', 'Por recuperar', 'Perdidas'],
    data: [],
    value: 20,
    enableValue: true
}

export const ChartLine = (props) => {

    const getConfData = (data) => {
        return [
            [{ type: "date", label: "Fecha" }, ...data.labels.map(e => e)],
            ...data.data.map(e => [new Date(e[0]), ...e[1].map(e => e)])
        ]
    }

    const createOptions = (data) => {
        return {
            chart: {
                title: data.nameEval,
                subtitle: data.descEval,
            },
            curveType: 'function',
            legend: { position: props.legend ? 'right' : 'none' },
            axisTitlesPosition: 'none',
            isStacked: false,
            displayExactValues: false,
            theme: 'material',
            colors: ['#2B1788', '#C7CFD8', '#FDAD34'],
            series: {
                1: {
                    targetAxisIndex: 1
                }
            },
        }
    }

    return (
        <div className="chart" style={{ width: '100%' }}>
            <Chart
                chartType="Line"
                width="100%"
                height="100%"
                data={getConfData(props.data)}
                options={createOptions(props.data)}
                loader={<p>Cargando datos...</p>}
            />
        </div>
    )
}

ChartLine.defaultProps = {
    titleX: "Periodo de tiempo",
    width: "auto",
    data: [],
    legend: false,
}

export const SliderChartLine = (props) => {

    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setReload(!reload)
        }, false);

    }, [])

    const processData = () => {
        setData(chunkArray(props.data, props.chunk))
    }

    useEffect(() => processData(), [props.data]);

    const rerender = () => {
        if (props.rerender) {
            setReload(!reload)
        }
    }

    return (
        <div id="carouselExampleIndicators" className="carousel slide w-100" data-ride="carousel">
            <div className="carousel-inner">
                {data && data.map((item, indexItem) => {
                    return (
                        <div className={`carousel-item ${props.active === indexItem ? 'active' : ''}`} key={indexItem}>
                            <div className='row'>
                                {item.map((e, i) => {
                                    return (
                                        <div className='col p-4'>
                                            <div className="d-flex align-items-end justify-content-center px-4" key={i}>
                                                <ChartLine data={e} legend={props.legendChart} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="d-flex align-items-end justify-content-end mb-2 mr-2">
                <span onClick={rerender} style={{ color: "#281B65", margin: 5, fontSize: 15 }} className="icon-arrow-point-to-left" type="button" data-target="#carouselExampleIndicators" data-slide="prev"></span>
                <span onClick={rerender} style={{ color: "#281B65", margin: 5, fontSize: 15 }} className="icon-arrow-point-to-right" type="button" data-target="#carouselExampleIndicators" data-slide="next"></span>
            </div>
        </div>
    )
}

SliderChartLine.defaultProps = {
    data: [],
    chunk: 1,
    active: 0,
    rerender: true,
    legendChart: false
}

const chunkArray = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index + chunk_size);
        tempArray.push(myChunk);
    }

    return tempArray;
}