import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Grid, TextField, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import Main from '../../components/main';
import axios from 'axios';
import { connect } from '../../store'
import LoaderContainer from '../../components/loader/container';
import { useTranslation } from 'react-i18next';
import ColorPicker from '../../components/color';
import PublishIcon from '@material-ui/icons/Publish';
import { useCookies } from 'react-cookie';
import Editor from '../../components/editor';

const Setting = (props) => {
    const { t } = useTranslation('maintainers');
    const [load, setLoad] = useState(true);
    const [color, setColor] = useState('#FF0000');
    const [color2, setColor2] = useState('#FF0000');
    const [files, setFiles] = useState(null);
    const [filesBanner, setFilesBanner] = useState(null);
    const [cookies] = useCookies(['tenantId']);
    const [slogan, setSlogan] = useState('');
    // const [desc, setDesc] = useState('');
    const [logo, setLogo] = useState('');
    const [banner, setBanner] = useState('');
    // const [pie, setPie] = useState('');
    const [contactForm, setContactForm] = useState('');
    const [rrss, setRRSS] = useState({});

    const [facebook, setFacebook] = useState(false);
    const [instagram, setInstagram] = useState(false);
    const [twitter, setTwitter] = useState(false);
    const [linkedin, setLinkedin] = useState(false);
    const [youtube, setYoutube] = useState(false);
    const [tiktok, setTiktok] = useState(false);


    let tenant = cookies.tenant || "agF";
    const getService = () => {
        setLoad(true);

        axios.get(`/custom/org/${tenant}`)
            .then(response => {
                dataParse(response.data.data)
                setLoad(false);
            })
            .catch(err => console.log(err))
    }

    const saveConfig = () => {
        let form = new FormData();
        form.append("color", color);
        form.append("color2", color2);
        form.append("contactForm", contactForm);
        form.append("slogan", slogan);
        form.append("social", JSON.stringify(rrss));
        if (files != null) form.append("logo", files, files.name);
        else form.append("logo", logo);
        if (filesBanner != null) form.append("banner", filesBanner, filesBanner.name);
        else form.append("banner", banner);

        axios.post(`/custom/org/${tenant}`, form)
            .then(response => {
                props.notify({ type: "success", title: t('setting.setting'), text: t('notify.success') });
            })
            .catch(err => {
                props.notify({ type: "error", title: t('setting.setting'), text: t('notify.error') })
                console.log(err)
            })
    }


    const dataParse = (data) => {
        setColor(data && data.color ? data.color : "#281b65");
        setColor2(data && data.color2 ? data.color2 : "#281b65");
        setContactForm(data && data.contactForm);
        setLogo(data && data.logo);
        setBanner(data && data.banner);
        setSlogan(data && data.slogan);
        setRRSS(data && data.social);
        setFacebook(data && data.social && data.social.facebook.active);
        setInstagram(data && data.social && data.social.instagram.active);
        setTwitter(data && data.social && data.social.twitter.active);
        setLinkedin(data && data.social && data.social.linkedin.active);
        setYoutube(data && data.social && data.social.youtube.active);
        setTiktok(data && data.social && data.social.tiktok.active);
    }

    const setSocial = (key, value, subvalue = undefined) => {
        let d = Object.assign({}, rrss);
        if (subvalue !== undefined) d[key][subvalue] = value;
        else d[key] = value;
        setRRSS(d);
    }

    useEffect(() => {
        getService();
    }, [])


    return (
        <Main>
            <Col xs="12">
                <div className="card-plataform bg-white mb-3">
                    <div className="box-titulo bg-primario d-flex align-items-center">
                        <p className="text-left ml-3 m-0 fz-18">{t('setting.title')}</p>
                    </div>
                    <LoaderContainer active={load}>
                        <Grid container spacing={3} className="px-4 mt-5 scroll-list">
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" className="pb-4 pl-3">
                                <Grid item xs={12}>
                                    <p className="title-calendar m-0 pb-4">{t('setting.setting')}</p>
                                </Grid>
                                <Grid item xs={12} md={3} className='pr-2'>
                                    <Grid container className="head-table3">
                                        <Grid item xs={10} container direction="row" justifyContent="flex-start" alignItems="center" className="pl-4">
                                            <p className="fz-14 color-sub-text m-0">{t('setting.logo')}</p>
                                        </Grid>
                                        <Grid item xs={2} container direction="row" justifyContent="center" alignItems="center">
                                            <input type="file" style={{ display: "none" }} id="ioop" onChange={v => {
                                                v.persist();
                                                setFiles(v.target.files[0]);
                                            }} />
                                            <label htmlFor="ioop" className="bg-purple text-white btn-upFiles" >
                                                <PublishIcon />
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3} className='pl-2'>
                                    <Grid container className="head-table3">
                                        <Grid item xs={10} container direction="row" justifyContent="flex-start" alignItems="center" className="pl-4">
                                            <p className="fz-14 color-sub-text m-0">{t('setting.banner')}</p>
                                        </Grid>
                                        <Grid item xs={2} container direction="row" justifyContent="center" alignItems="center">
                                            <input type="file" style={{ display: "none" }} id="banner" onChange={v => {
                                                v.persist();
                                                setFilesBanner(v.target.files[0]);
                                            }} />
                                            <label htmlFor="banner" className="bg-purple text-white btn-upFiles" >
                                                <PublishIcon />
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3} container direction="row" justifyContent="center" alignItems="center">
                                    <ColorPicker className="w-100" color={color} onChange={v => setColor(v)} />
                                </Grid>
                                <Grid item xs={12} md={3} container direction="row" justifyContent="center" alignItems="center">
                                    <ColorPicker className="w-100" color={color2} onChange={v => setColor2(v)} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="title-calendar m-0">{t('setting.text_banner')}</p>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Editor value={slogan} onChange={setSlogan} />
                            </Grid>
                            <Grid item xs={12}>
                                <p className="title-calendar m-0">{t('setting.contact_form')}</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    className="w-100"
                                    id="email2"
                                    label={t('setting.form_mail')}
                                    multiline
                                    variant="outlined"
                                    margin="dense"
                                    type="email"
                                    fullWidth
                                    value={contactForm}
                                    onChange={v => setContactForm(v.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <p className="title-calendar m-0">{t('setting.social')}</p>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={3} container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                        <FormGroup>
                                            <FormControlLabel className="m-0" control={<Checkbox checked={facebook} onChange={v => {
                                                v.persist();
                                                v = v.target.checked;
                                                setFacebook(v);
                                                setSocial('facebook', v, 'active')
                                            }} />} label="Facebook" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={9} container direction="row" justifyContent="center" alignItems="center">
                                        <TextField
                                            className="w-100"
                                            id="desc"
                                            label="Link"
                                            multiline
                                            margin="dense"
                                            type="text"
                                            fullWidth
                                            value={rrss && rrss.facebook && rrss.facebook.url}
                                            onChange={v => setSocial('facebook', v.target.value, 'url')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={3} container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                        <FormGroup>
                                            <FormControlLabel className="m-0" control={<Checkbox checked={instagram} onChange={v => {
                                                v.persist();
                                                v = v.target.checked;
                                                setInstagram(v);
                                                setSocial('instagram', v, 'active')
                                            }} />} label="Instagram" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={9} container direction="row" justifyContent="center" alignItems="center">
                                        <TextField
                                            className="w-100"
                                            id="desc"
                                            label="Link"
                                            multiline
                                            margin="dense"
                                            type="text"
                                            fullWidth
                                            value={rrss && rrss.instagram && rrss.instagram.url}
                                            onChange={v => setSocial('instagram', v.target.value, 'url')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={3} container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                        <FormGroup>
                                            <FormControlLabel className="m-0" control={<Checkbox checked={linkedin} onChange={v => {
                                                v.persist();
                                                v = v.target.checked;
                                                setLinkedin(v);
                                                setSocial('linkedin', v, 'active')
                                            }} />} label="Linkedin" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={9} container direction="row" justifyContent="center" alignItems="center">
                                        <TextField
                                            className="w-100"
                                            id="desc"
                                            label="Link"
                                            multiline
                                            margin="dense"
                                            type="text"
                                            fullWidth
                                            value={rrss && rrss.linkedin && rrss.linkedin.url}
                                            onChange={v => setSocial('linkedin', v.target.value, 'url')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={3} container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                        <FormGroup>
                                            <FormControlLabel className="m-0" control={<Checkbox checked={twitter} onChange={v => {
                                                v.persist();
                                                v = v.target.checked;
                                                setTwitter(v);
                                                setSocial('twitter', v, 'active')
                                            }} />} label="Twitter" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={9} container direction="row" justifyContent="center" alignItems="center">
                                        <TextField
                                            className="w-100"
                                            id="desc"
                                            label="Link"
                                            multiline
                                            margin="dense"
                                            type="text"
                                            fullWidth
                                            value={rrss && rrss.twitter && rrss.twitter.url}
                                            onChange={v => setSocial('twitter', v.target.value, 'url')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={3} container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                        <FormGroup>
                                            <FormControlLabel className="m-0" control={<Checkbox checked={youtube} onChange={v => {
                                                v.persist();
                                                v = v.target.checked;
                                                setYoutube(v);
                                                setSocial("youtube", v, "active");
                                            }} />} label="Youtube" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={9} container direction="row" justifyContent="center" alignItems="center">
                                        <TextField
                                            className="w-100"
                                            id="desc"
                                            label="Link"
                                            multiline
                                            margin="dense"
                                            type="text"
                                            fullWidth
                                            value={rrss && rrss.youtube && rrss.youtube.url}
                                            onChange={v => setSocial('youtube', v.target.value, 'url')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container>
                                    <Grid item xs={3} container direction="row" justifyContent="flex-start" alignItems="flex-end">
                                        <FormGroup>
                                            <FormControlLabel className="m-0" control={<Checkbox checked={tiktok} onChange={v => {
                                                v.persist();
                                                v = v.target.checked;
                                                setTiktok(v);
                                                setSocial('tiktok', v, 'active');
                                            }} />} label="Tiktok" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={9} container direction="row" justifyContent="center" alignItems="center">
                                        <TextField
                                            className="w-100"
                                            id="desc"
                                            label="Link"
                                            multiline
                                            margin="dense"
                                            type="text"
                                            fullWidth
                                            value={rrss && rrss.tiktok && rrss.tiktok.url}
                                            onChange={v => setSocial('tiktok', v.target.value, 'url')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="mr-3 my-5" container direction="row" justifyContent="flex-end" alignItems="flex-end">
                                <button className="btn-white-tf" name={t('maintainer.save')} variant="contained" color="primary" onClick={saveConfig}>{t('maintainer.save')}</button>
                            </Grid>
                        </Grid>
                    </LoaderContainer>
                </div>
            </Col>
        </Main>
    )

}

export default connect(Setting);

