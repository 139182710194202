import * as Action from "./evaluationsActions";

export const initialState = {
  loading: true,
  selectedId: "",
  idCurrent: null,

  // Evaluations
  evaluations: [],

  // Fields
  name: "",
  desc: "",
  instructions: "",
  cat: "",
  type: "",

  varsData: [],

  // Modals
  newEvaluationModal: false,
  previewActive: false,
  openPreview: false,
  openEdit: false,
  removeModal: false,

  // Filter
  filteredEvaluations: [],
  inputFilter: "",
  dropdownFilter: "name",

  // Logic
  selectedBox: null,

  // Modal Image Editing
  selectedImage: null
};

export const evaluationsReducer = (state, action) => {
  switch (action.type) {
    case Action.CLOSE_EDIT:
      return {
        ...state,
        name: "",
        desc: "",
        instructions: "",
        cat: "",
        type: "",
        varsData: [],
        
        idCurrent: null,
        openEdit: false,
        selectedId: "",
      };

    case Action.OPEN_EDIT:
      return {
        ...state,
        name: action.payload.name,
        desc: action.payload.desc,
        instructions: action.payload.instructions,
        cat: action.payload.cat._id,
        type: action.payload.type,
        varsData: action.payload.variables,

        openEdit: true,
        selectedId: action.payload._id,
      };

    case Action.OPEN_PREVIEW:
      return {
        ...state,
        name: action.payload.name,
        desc: action.payload.desc,
        instructions: action.payload.instructions,
        cat: action.payload.cat._id,
        type: action.payload.type,
        varsData: action.payload.variables,

        openPreview: true,
        selectedId: action.payload._id,
      };

    case Action.UPDATE_VARS_DATA:
      const { sourceIndex, destinationIndex } = action.payload;
      const updatedVarsData = [...state.varsData];
      const temp = updatedVarsData[sourceIndex];
      updatedVarsData[sourceIndex] = updatedVarsData[destinationIndex];
      updatedVarsData[destinationIndex] = temp;
      return {
        ...state,
        varsData: updatedVarsData,
      };
    
    case Action.SET_QUESTION_IMAGE:
      const { questionId: updatedQuestionId, imageSrc } = action.payload;
      const updatedData = state.varsData.map((question) => {
        if (question.id === updatedQuestionId || question._id === updatedQuestionId) {
          console.log("question:", question)
          return {
            ...question,
            image: imageSrc,
          };
        }
        return question;
      });
      return {
        ...state,
        varsData: updatedData,
      };

    case Action.CLEAR_QUESTION_IMAGE:
      const { questionId: clearedQuestionId } = action.payload;
      const clearedData = state.varsData.map((question) => {
        if (question.id === clearedQuestionId || question._id === clearedQuestionId) {
          return {
            ...question,
            image: null,
          };
        }
        return question;
      });
      return {
        ...state,
        varsData: clearedData,
      };
      

    case Action.SET_VARS_DATA:
      return {
        ...state,
        varsData: action.payload,
      };

    case Action.SET_IMAGE_FILE:
      return {
        ...state,
        imageFile: action.payload,
      };

    case Action.SET_SERVER_IMAGES:
      return {
        ...state,
        serverImages: action.payload,
      };

    case Action.SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    case Action.SET_INPUT_FIELD:
      return {
        ...state,
        [action.field]: action.payload,
      };

    case Action.SET_EVALUATIONS:
      return {
        ...state,
        evaluations: action.payload,
      };

    case Action.LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case Action.CLEAR_STATE:
      return {
        ...state,
        selectedId: "",
        name: "",
        desc: "",
        instructions: "",
        cat: "",
        type: "",
        varsData: [],
        vars: [],
        newEvaluationModal: false,
        idCurrent: null,
        openPreview: false,
        previewActive: false,
        openEdit: false,
        removeModal: false,
      };

    case Action.OPEN_MODAL:
      return {
        ...state,
        selectedId: action.payload || "",
        [action.modalType]: !state[action.modalType],
        varsData: action.payload || state.varsData,
      };

    case Action.REMOVE:
      return {
        ...state,
        removeModal: action.payload,
        selectedId: action.selectedId,
      };

    case Action.SET_FIELDS:
      return {
          ...state,
          [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};
