import React, { useState } from "react";
import PropTypes from "prop-types";

import { Page, pdfjs } from "react-pdf";

import * as S from "./Card.styles";
import { GarbageIcon } from "../../../../assets/icons";
import LinesEllipsis from "react-lines-ellipsis";

import "react-lazy-load-image-component/src/effects/blur.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const Card = ({
  isPdf,
  img,
  onClick,
  onClickDelete,
  filename,
  scrollPosition,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [numPages, setNumPages] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <S.CardStyled>
      {isPdf ? (
        <S.DocumentStyled
          onClick={onClick}
          file={img}
          onLoadError={console.error}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </S.DocumentStyled>
      ) : (
        <S.Image
          src={img}
          effect="blur"
          onClick={onClick}
          height={150}
          width={"100%"}
          scrollPosition={scrollPosition}
        />
        // <LazyLoadImage src={img} />
      )}
      <S.Info>
        <S.FileName onClick={onClick}>
          {
            <LinesEllipsis
              text={filename}
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          }
        </S.FileName>
        {onClickDelete && (
          <S.DeleteButton onClick={onClickDelete}>
            <GarbageIcon />
          </S.DeleteButton>
        )}
      </S.Info>
    </S.CardStyled>
  );
};

Card.propTypes = {
  filename: PropTypes.string,
  img: PropTypes.string,
  onClick: PropTypes.func,
  onClickDelete: PropTypes.func,
  isPdf: PropTypes.bool,
};
