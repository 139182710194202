import React from 'react';
import './style.css';
import { connect } from '../store'
import { useTranslation } from 'react-i18next';
 
const NoMobile = (props) => {

    const { t } = useTranslation('global');

    return (
        <div className="noMobile pt-5">
            <div className="box">
            <svg width="101" height="102" viewBox="0 0 101 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50.5784" cy="50.8282" r="50.232" fill="#ECEFF4"/>
                <g clipPath="url(#clip0_2385_2887)">
                    <path d="M38.1298 52.5863L63.1221 14.7185L54.0217 42.8006L66.9595 42.8531L38.0345 86.9378L51.1821 52.5727L38.1298 52.5863Z" fill="#FDAD34"/>
                    <path d="M38.1298 52.5863L63.1221 14.7185L54.0217 42.8006L66.9595 42.8531L38.0345 86.9378L51.1821 52.5727L38.1298 52.5863Z" fill="#FDAD34"/>
                    <path d="M38.1298 52.5863L63.1221 14.7185L54.0217 42.8006L66.9595 42.8531L38.0345 86.9378L51.1821 52.5727L38.1298 52.5863Z" fill="#FDAD34"/>
                </g>
                <defs>
                    <clipPath id="clip0_2385_2887">
                        <rect width="50" height="57.8361" fill="white" transform="translate(63.1221 14.7185) rotate(60)"/>
                    </clipPath>
                </defs>
            </svg>
                <br />
                <h4>{t('no_mobile.error')}</h4>
                <p>{t('no_mobile.info1')}</p>
                <span>{t('no_mobile.info2')}</span>
            </div>
        </div>
    )
}

export default connect(NoMobile);
