import styled from "styled-components";
import PhoneInput from "react-phone-input-2";

export const PhoneInputStyled = styled(PhoneInput)`
  & > .form-control {
    height: 46px;
    width: 100%;
    border-radius: 4px;
    border: 0.5px solid #9aa2a8;

    &:focus {
      box-shadow: none;
    }
  }

  & > .special-label {
    font-size: 14px;
    color: ${({ focus }) => (focus ? "#281b65" : "#9aa2a8")};
    font-weight: 300;
    left: 15px;
    top: -10px;
    padding: 0 5px;
    transition: color 0.2s;
    ${({ inputExtraProps }) =>
      inputExtraProps.required &&
      `
      &::after {
        content: "*";
        color: red;
      }
    `}
  }

`;

export const ModalNewUser = styled.div`
  & > div {
    margin: 15px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  & > .modalnewuser--buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;

    & > div:first-child {
      margin-right: 15px;
    }
  }
`;

export const HeaderStyled = styled.div`
  margin-top: 14px;
  padding: 0 14px 0 14px;
  display: flex;
  align-items: center;
  width: 100%;

  & > div:first-child {
    width: 100%;
  }

  & button {
    margin-left: 10px;
  }
`;

export const CenteredScreen = styled.div`
  display: grid;
  place-items: center;
  height: calc(100vh - 500px);

  & > p {
    color: #281b65;
    font-size: 14px;
  }
`;

export const DateFilterHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > p {
    margin: 0 6px 0 0;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    color: #182024;
  }

  & > div {
    &:nth-child(3) {
      margin: 0 10px;
    }

    & input {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;

      color: #182024;
    }
  }
`;

export const PaddingContainer = styled.div`
  padding-bottom: 150px;
`;
