export const CLEAR_STATE_PROTOCOL = "[] Clear state to initial";
export const CLEAR_STATE_SESSION = "[] Clear session state to initial";
export const EDITING_PROTOCOL = "[] Set editing state";
export const EDITING_SESSION = "[] Set editing session state";
export const SET_FIELDS =         "[] Set fields";
export const SET_INPUT_FIELDS =   "[] Set inputs fields";
export const SET_SUBSIDIARY =     "[] Set Subsidiary";
export const SET_OPEN_MODAL =     "[] Set Open modals";

export const CHANGE_PROTOCOL =    "[Protocols] Set Protocol";
export const CHANGE_SESSION =    "[Protocols] Set Session";
export const USE_EFFECT_PARAMS =    "[Protocols] Set route params";