import React, { useCallback, useEffect, useState, useRef } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "../../store";

import * as S from "./ClinicalRecords.styles";

import API from "../../services/api";

import {
  Button,
  ButtonCircle,
  Col,
  Container,
  Modal,
  Row,
  SearchBar,
  Tabs,
} from "trainfes-components-library";
import Pagination from "../../components/pagination";

import {
  FilterSidebar,
  ModalNewOrEditUserComponent,
  PatientCard,
  RecordCard,
  RecordTable,
} from "./components";

import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import { DatePickerComponent } from "../../components/DatePickerv2";

import { utils } from "react-modern-calendar-datepicker";
import {
  FORM_INITIAL_VALUE,
  handleDateFilter,
  handleDatePickerChange,
} from "./clinicalRecords.helpers";

import { FilterIcon } from "../../assets/icons";
import { rutValidation } from "../../lib/validation.helpers";
import { getUserId } from "../../lib/router";
import { RolBlockedScreen } from "../../components/RolBlockedScreen";

import { Virtuoso } from "react-virtuoso";

const api = new API();

const TherapistTherapist = (props) => {
  const history = useHistory();
  let { id } = useParams();

  const { t } = useTranslation([
    "registers",
    "create_patient",
    "modes",
    "sessionStudio",
  ]);

  const [patients, setPatients] = useState([]);

  const [, setRegisters] = useState([]);
  const [, setTrainings] = useState([]);
  const [idActual, setIdActual] = useState("");
  const [dateStart, setDateStart] = useState({ from: null, to: null });
  const [, setActivePatients] = useState(true);
  const [, setActiveData] = useState(true);
  const [page, setPage] = useState(1);
  const [limit] = useState(4);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [pageRecord, setPageRecord] = useState(1);
  const [limitRecord] = useState(4);
  const [totalPagesRecord, setTotalPagesRecord] = useState(0);
  const [pageUsers, setPageUsers] = useState(1);
  const [limitUsers] = useState(150);
  const [totalPagesUsers, setTotalPagesUsers] = useState(0);

  const handleGetPatients = (filterValue = "", pageValue = 1) => {

    setPacientLoading(true);
    api
      .getAllUserPatientsPaginated(pageValue, limitUsers, filterValue)
      .then((res) => {
        setPatients(res.data);
        setActivePatients(false);
        setTotalPagesUsers(res.totalPages);

        if (id) {
          getRegisterPatient(id, dateStart, page, limit);
          setIdActual(id);
          scrollElement(id);
          setTrainings([]);
        } else {

          if (res.data[0]._id) {
            getRegisterPatient(res.data[0]._id, dateStart, page, limit);
            setIdActual(res.data[0]._id);
            scrollElement(res.data[0]._id);
            setTrainings([]);
          }

        }

      })
      .finally(() => setPacientLoading(false))
      .catch((err) => setRegisters([]));
  };

  const handleGetRecords = (id, date, pageNum, limit) => {
    const controller = new AbortController();
    const { signal } = controller;
    setRecordsLoading(true);
    setIdActual(id);
    setActiveData(true);
    if (date === undefined || date.from == null) date = defaultDate();

    const fromDate = new Date(date.from.year, date.from.month - 1, date.from.day);
    const toDate = new Date(date.to.year, date.to.month - 1, date.to.day + 1);
    // crea un nuevo objeto con las fechas en formato ISO
    const dateISO = {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    axios
      .post(`/user/records/${id}/${pageNum}/${limit}`, dateISO, { signal })
      .then(({ data }) => {
        setRecords(data.data);
        setRecordActive(data.data[0]);
        setTotalPagesRecord(data.totalPages);
      })
      .finally(() => setRecordsLoading(false))
      .catch((err) => {
        setRecords([]);
        setRecordActive("");
        console.log(err);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });
  };

  const handleGetEvolution = (id, page, searchQuery = "", dateFilter = "", specialityFilter = "") => {
    const controller = new AbortController();
    const { signal } = controller;
    setHistoryLoading(true);
    setActiveData(true);
    const dateTo = dateFilter.to;
    const dateFrom = dateFilter.from;
    axios
      .get(`/user/evolution/${id}/${page}/${limit}`, {
        signal,
        params: {
          search: searchQuery,
          from: dateFrom,
          to: dateTo,
          speciality: specialityFilter,
        }
      })
      .then(({ data }) => {
        setHistorical(data.data);
        setTotalPages(data.totalPages);
      })
      .finally(() => setHistoryLoading(false))
      .catch((err) => {
        setHistorical([]);
        console.log(err);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });
  };

  const debounceRef = useRef();
  const handleSearchDebounced = (value) => {
    setSearchValue(value);
    setPage(1);

    clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      handleGetEvolution(idActual, page, value, dateFiltered, specialityFilter);
    }, 1000);
  };

  useEffect(() => {
    initDate();
    handleGetPatients();

    return () => {
      setPatients([]);
      setPacientLoading(false);
      setIdActual("");
      setTrainings([]);
      setRegisters([]);
      setDateStart({ from: null, to: null });
    };
  }, []);

  const initDate = () => {
    setDateStart(defaultDate());
  };

  const scrollElement = (id) => {
    document.querySelector("#id-" + id).scrollIntoView({
      behavior: "smooth",
    });
  };

  const subtractMonths = (numOfMonths, date = new Date()) => {
    date.setMonth(date.getMonth() - numOfMonths);
    return date;
  };

  const defaultDate = () => {
    let from = subtractMonths(6);
    from.setSeconds(0);
    from.setMinutes(0);
    from.setHours(0);
    return {
      from: {
        year: from.getFullYear(),
        month: from.getMonth() + 1,
        day: from.getDate(),
      },
      to: utils().getToday(),
    };
  };

  // -> Estado almacenando registros de evolución e históricos.
  const [records, setRecords] = useState([]);
  const [historical, setHistorical] = useState([]);
  const [recordActive, setRecordActive] = useState("");

  const [pacientLoading, setPacientLoading] = useState(true);
  const [, setRecordsLoading] = useState(true);
  const [historyLoading, setHistoryLoading] = useState(false);

  const getRegisterPatient = useCallback((id, date, pageNum, limit, searchQuery = "") => {
    const controller = new AbortController();
    const { signal } = controller;

    setHistoryLoading(true);

    setIdActual(id);
    setActiveData(true);
    if (date === undefined || date.from == null) date = defaultDate();

    const fromDate = new Date(date.from.year, date.from.month - 1, date.from.day);
    const toDate = new Date(date.to.year, date.to.month - 1, date.to.day + 1);
    // crea un nuevo objeto con las fechas en formato ISO
    const dateISO = {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };

    axios
      .post(`/user/records/${id}/${pageNum}/${limit}`, dateISO, { signal })
      .then(({ data }) => {
        setRecords(data.data);
        setRecordActive(data.data[0]);
        setTotalPagesRecord(data.totalPages);
      })
      .finally(() => setRecordsLoading(false))
      .catch((err) => {
        setRecords([]);
        setRecordActive("");
        console.log(err);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });
    
    axios
      .get(`/user/evolution/${id}/${page}/${limit}`, { signal })
      .then(({ data }) => {
        setHistorical(data.data);
        setTotalPages(data.totalPages);
      })
      .finally(() => setHistoryLoading(false))
      .catch((err) => {
        setHistorical([]);
        console.log(err);
        if (err.name !== "AbortError") {
          console.error(err.message);
        }
      });
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  const tabsObject = [
    { id: 0, title: t("evolutions") },
    { id: 1, title: t("another_records") },
  ];

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [showSidebar, setShowSidebar] = useState(false);
  const handleShowSidebar = () => setShowSidebar(true);
  const handleCloseSidebar = () => setShowSidebar(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: FORM_INITIAL_VALUE,
  });

  const [country, setCountry] = useState("");
  const [commune, setCommune] = useState("");
  const [region, setRegion] = useState("");
  const [address, setAddress] = useState("");
  const [stateAddress, setStateAddress] = useState();

  const getDataAddress = (data) => {
    setCountry(data.country);
    setCommune(data.state);
    setRegion(data.region);
    setAddress(`${data.address} ${data.number}`);
    setStateAddress(data.addressComplete);
  };

  const onSubmit = async (dataForm) => {
    if (!rutValidation(dataForm.nationality, dataForm.rut)) {
      props.notify({
        type: "error",
        title: t("create_patient:notify.invalid_id"),
        text: t("create_patient:notify.invalid_id"),
      });
      return;
    }

    if (patients.some((patient) => patient.rut === dataForm.rut)) {
      props.notify({
        type: "error",
        title: t("create_patient:notify.invalid_id"),
        text: t("create_patient:notify.exits"),
      });
      return;
    }

    if (country.trim() === "") {
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.country"),
      });
      return;
    }

    if (region.trim() === "") {
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.state"),
      });
      return;
    }

    if (address.trim() === "") {
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: t("create_patient:notify.address"),
      });
      return;
    }

    const form = new FormData();
    form.append("nationality", dataForm.nationality);
    form.append("rut", dataForm.nationality === 'CL' ? dataForm.rut.trim() : dataForm.rut);
    form.append("name", dataForm.name);
    form.append("lastname", dataForm.lastname);
    form.append("email", dataForm.email);
    form.append("phone", dataForm.phone);
    form.append("injury_years", dataForm.injury_years);
    form.append("genre", dataForm.genre);
    form.append("birthdate", dataForm.birthdate.replaceAll("/", "-"));
    form.append("country", country);
    form.append("region", region);
    form.append("commune", commune);
    form.append("rol", dataForm.rol);
    form.append("address", address);
    form.append("other_address", dataForm.other_address);
    form.append("innsurance_company", dataForm.innsurance_company);
    form.append("clinic", dataForm.clinic);
    form.append("treating_doctor", dataForm.treating_doctor);
    form.append("years_injury", dataForm.years_injury);
    form.append("reference", dataForm.reference);

    try {
      await api.createPatien(form, isSubmitting);

      props.notify({
        type: "success",
        title: t("create_patient:notify.success"),
        text: t("create_patient:notify.success"),
      });

      // * Reset Data
      reset();
      setCountry("");
      setCommune("");
      setRegion("");
      setAddress("");
      setStateAddress(undefined);

      handleGetPatients();
      handleCloseModal();
    } catch ({ data }) {
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: `${t("create_patient:notify.err")}. ${data.err.err}`,
      });
    }
  };

  const [filter, setFilter] = useState("");

  const [dateFiltered, setDateFiltered] = useState({
    from: null,
    to: null,
  });

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [dateOtherRecordsFiltered, setDateOtherRecordsFiltered] = useState({
    from: null,
    to: null,
  });

  const clearFilterRecordTable = () => {
    setDateOtherRecordsFiltered({
      from: null,
      to: null,
    });
    setFromDate(undefined);
    setToDate(undefined);
    setPageRecord(1);
    handleGetRecords(idActual, { from: null, to: null }, 1, limitRecord);
  };

  const [minimumDate, setMinimumDate] = useState();
  const [maximumDate, setMaximumDate] = useState();

  const [specialityFilter, setSpecialityFilter] = useState("");

  useEffect(() => {
    if (fromDate && toDate) {
      handleGetRecords(idActual, { from: fromDate, to: toDate }, pageRecord, limitRecord);
    }
  }, [fromDate, toDate]);

  const tabsRender = (key) => {
    switch (key) {
      case 0:
        return (
          <>
            {historical.map((r) => (
                <RecordCard key={r._id} data={r} />
            ))}

            {historical.length === 0 && (
              <S.CenteredScreen>
                <p>{t("no_records_evolution")}</p>
              </S.CenteredScreen>
            )}
          </>
        );
      case 1:
        return (
          <>
            <S.DateFilterHeader>
              <p>{t("filter_by_date_range")}</p>
              <DatePickerComponent
                height="36px"
                placeholder={t("from")}
                range={false}
                value={fromDate}
                onChange={(value) => {
                  setFromDate(value);
                  handleDatePickerChange(value, toDate, setMinimumDate, setDateStart);
                }}
                maximumDate={maximumDate}
              />
              <DatePickerComponent
                height="36px"
                placeholder={t("to")}
                range={false}
                value={toDate}
                onChange={(value) => {
                  setToDate(value);
                  handleDatePickerChange(fromDate, value, setMaximumDate, setDateStart);
                }}
                minimumDate={minimumDate ? minimumDate : undefined}
              />
              <Button
                type="ghost"
                label={t("clear")}
                width="90px"
                onClick={clearFilterRecordTable}
              />
            </S.DateFilterHeader>

            {records
              .filter((item) =>
                handleDateFilter(item, dateOtherRecordsFiltered)
              )
              .map((record, i) => (
                <RecordTable
                  key={`record-${i}`}
                  record={record}
                  recordActive={recordActive}
                  setRecordActive={setRecordActive}
                  t={t}
                />
              ))}

            {records.length === 0 && (
              <S.CenteredScreen>
                <p>{t("no_records")}</p>
              </S.CenteredScreen>
            )}
            <Pagination
              currentPage={pageRecord}
              totalPages={totalPagesRecord}
              onPageChange={(newPageRecord) => {
                setPageRecord(newPageRecord);
                handleGetRecords(idActual, dateStart, newPageRecord, limitRecord);
              }}
            />
          </>
        );
      default:
        break;
    }
  };

  const handleFilterChange = (speciality, dateFilter) => {
    setPage(1);
    handleGetEvolution(idActual, page, searchValue, dateFilter, speciality);
  };

  const sidebarFilter = {
    title: (
      <>
        <FilterIcon /> {t("filters")}
      </>
    ),
    content: (
      <FilterSidebar
        dateFiltered={dateFiltered}
        setDateFiltered={setDateFiltered}
        setSpecialityFilter={setSpecialityFilter}
        handleCloseSidebar={handleCloseSidebar}
        onFilterChange={handleFilterChange}
      />
    ),
  };

  const Header = () => null;
  const Footer = () => <div style={{ height: "50px", width: "100%" }}></div>;

  const debounceUserRef = useRef();
  const handleFilterUsers = (value) => {
    setFilter(value);
    setPageUsers(1);

    clearTimeout(debounceUserRef.current);

    debounceUserRef.current = setTimeout(() => {
      handleGetPatients(value);
    }, 1000);
  };

  return (
    <Container>
      <Row cols={12} gap={30}>
        <Col xs={5}>
          <CardLayoutPlatform
            loading={pacientLoading}
            title={t("titlePatients")}
            footer={
              <div style={{overflowX: "hidden", display: "flex", justifyContent: "flex-end", width: "100%", backgroundColor: "white"}}>
                { !pacientLoading ? (
                  <>
                    <Pagination
                      currentPage={pageUsers}
                      totalPages={totalPagesUsers}
                      onPageChange={(newPageUsers) => {
                        setPageUsers(newPageUsers);
                        handleGetPatients(filter, newPageUsers);
                      }}
                    />
                    <ButtonCircle onClick={handleShowModal} />
                  </>
                ) : null}
              </div>
            }
            noPaddingBottom
            padding={"0"}
            header={
              <div style={{ marginTop: "14px", padding: "0 14px 10px 14px" }}>
                <SearchBar
                  placeholder={t("search")}
                  onChange={(event) => {
                    handleFilterUsers(event.target.value);
                  }}
                  value={filter}
                />
              </div>
            }
            overflowVirtuoso="true"
          >
            <Virtuoso
              style={{
                height: "calc(100vh - 249px)",
                padding: "0 0 200px 0",
              }}
              data={patients}
              components={{ Header, Footer }}
              itemContent={(index, patient) => {
                return (
                  <PatientCard
                    t={t}
                    key={patient._id}
                    idActual={idActual}
                    data={patient}
                    historyLoading={historyLoading}
                    onClick={() => {
                      getRegisterPatient(patient._id, dateStart, page, limit);
                      setActiveTab(0);
                    }}
                    handleGetPatients={handleGetPatients}
                  />
                );
              }}
            />
          </CardLayoutPlatform>
        </Col>
        <Col xs={7}>
          <CardLayoutPlatform
            loading={historyLoading || pacientLoading}
            title={t("titleRegisterHistory")}
            showSidebar={showSidebar}
            handleClose={handleCloseModal}
            handleCloseSidebar={handleCloseSidebar}
            sidebarTitle={sidebarFilter.title}
            sidebarContent={sidebarFilter.content}
            header={
              getUserId().rol !== "admin" ? (
                <>
                  <Tabs
                    data={tabsObject}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <S.HeaderStyled>
                    {activeTab === 0 && (
                      <>
                        <SearchBar
                          placeholder={t("search")}
                          onChange={(event) =>
                            handleSearchDebounced(event.target.value)
                          }
                          value={searchValue}
                        />
                        <Button
                          type="ghost"
                          icon={<FilterIcon />}
                          label={t("filters")}
                          width="102px"
                          onClick={handleShowSidebar}
                        />
                      </>
                    )}
                  </S.HeaderStyled>
                </>
              ) : null
            }
            footer={
              activeTab === 0 && getUserId().rol !== "admin" ? (
                  <div style={{display: "flex", justifyContent: "flex-end", width: "100%", backgroundColor: "white"}}>
                    { !historyLoading && !pacientLoading ? (
                      <>
                        <Pagination
                          currentPage={page}
                          totalPages={totalPages}
                          onPageChange={(newPage) => {
                              setPage(newPage);
                              handleGetEvolution(idActual, newPage, searchValue, dateFiltered, specialityFilter);
                          }}
                        />
                        <ButtonCircle
                          onClick={() => {
                              history.push({
                                pathname: `/patient-evolution/${idActual}`
                              });

                            }}
                        />
                      </>
                    ) : null}
                  </div>
              ) : null
            }
          >
            {getUserId().rol !== "admin" ? (
              tabsRender(activeTab)
            ) : (
              <RolBlockedScreen t={t} />
            )}
          </CardLayoutPlatform>
        </Col>
      </Row>

      <Modal
        title={t("create_new_patient")}
        isOpen={showModal}
        buttons={
          <>
            <Button
              type="ghost"
              label={t("sessionStudio:cancel")}
              onClick={handleCloseModal}
            />
            <Button
              label={t("sessionStudio:save")}
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isSubmitting}
            />
          </>
        }
      >
        <ModalNewOrEditUserComponent
          isEditing={false}
          watch={watch}
          handleSubmit={handleSubmit}
          errors={errors}
          onSubmit={onSubmit}
          control={control}
          stateAddress={stateAddress}
          setStateAddress={setStateAddress}
          getDataAddress={getDataAddress}
          t={t}
        />
      </Modal>
    </Container>
  );
};

export default connect(TherapistTherapist);
