import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";
import enLocale from "@fullcalendar/core/locales/en-au";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import "./old.css";
// import dragula from "dragula";

const Calendar = (props) => {
    const ref = useRef();
    const [events, setEvents] = useState([]);
    const [resources, setResources] = useState([]);

    const handleDateClick = (event) => {
        if (props.handleDateClick !== undefined) props.handleDateClick(event);
    };

    const eventReceive = (event) => {
        if (props.eventReceive !== undefined)
            props.eventReceive(event.event, event.event.extendedProps, event);
    };

    const eventClick = (event) => {
        if (props.eventClick !== undefined)
            props.eventClick(event.event, event.event.extendedProps, event);
    };

    const dropEvent = (event) => {
        if (event.event) {
            if (props.dropEvent !== undefined)
                props.dropEvent("event", event.event.extendedProps, event);
        } else {
            let extendedProps = {};
            for (
                var i = 0, atts = event.draggedEl.attributes, n = atts.length;
                i < n;
                i++
            ) {
                if (atts[i].nodeName === "event") {
                    extendedProps[atts[i].nodeName] = JSON.parse(atts[i].nodeValue);
                } else {
                    extendedProps[atts[i].nodeName] = atts[i].nodeValue;
                }
            }
            if (props.dropEvent !== undefined)
                props.dropEvent("drag", extendedProps, event);
        }
    };

    const renderEvent = (event) => {
        if (props.renderEvent !== undefined) {
            return props.renderEvent(event.event, event.event.extendedProps, event);
        } else {
            return (
                <>
                    <b>{event.timeText}</b>
                    <i>{event.event.title}</i>
                </>
            );
        }
    };

    useEffect(() => {
        if (props.events !== undefined && Array.isArray(props.events)) {
            setEvents(props.events);
        }
    }, [props.events]);

    useEffect(() => {
        if (props.resources !== undefined && Array.isArray(props.resources)) {
            setResources(props.resources);
        }
    }, [props.resources]);

    useEffect(() => {
        if (props.refDrag !== undefined) {
            new Draggable(props.refDrag.current, {
                itemSelector: props.dragSelector,
                mirrorSelector: ".gu-mirror",
                eventData: function (eventEl) {
                    let extendedProps = {};
                    for (
                        var i = 0, atts = eventEl.attributes, n = atts.length;
                        i < n;
                        i++
                    ) {
                        if (atts[i].nodeName === "event") {
                            extendedProps[atts[i].nodeName] = JSON.parse(atts[i].nodeValue);
                        } else {
                            extendedProps[atts[i].nodeName] = atts[i].nodeValue;
                        }
                    }
                    if (props.onDragable !== undefined)
                        return props.onDragable(extendedProps);
                },
            });
        }

        ref.current.getApi().setOption("height", props.height);
    }, []);

    const onManualResizing = () => {
        // setTimeout(() => {
        //     ref.current.getApi().updateSize();
        //     if (props.onManualResizing != undefined) props.onManualResizing(false);
        // }, 300)
    };

    useEffect(() => {
        if (props.manualResize) onManualResizing();
    }, [props.manualResize]);

    const eventDrop = (data) => {
        if (props.eventDrop !== undefined) props.eventDrop(data);
    };

    const getLocale = (locale) => {
        switch (locale.toLowerCase().trim()) {
            case "es":
                return esLocale;
            case "en":
                return enLocale;
            default:
                return esLocale;
        }
    };

    return (
        <div className="ccalendar">
            <FullCalendar
                locale={getLocale(props.locale)}
                select={(start, end, allDay) => { }}
                weekends={props.weekends}
                headerToolbar={props.headers}
                customButtons={props.buttons}
                dateClick={handleDateClick}
                eventReceive={eventReceive}
                eventClick={eventClick}
                editable={props.editable}
                eventStartEditable={true}
                droppable={props.droppable}
                drop={(event) => dropEvent(event)}
                eventDrop={eventDrop}
                events={events}
                eventSources={resources}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                eventContent={renderEvent}
                eventDurationEditable={true}
                timeZone="local"
                hiddenDays={props.hiddenDays}
                eventOrder="start,hour"
                eventTimeFormat={{ hour: "2-digit", minute: "2-digit", hour12: false }}
                allDaySlot={props.allDaySlot}
                initialView={props.initialView}
                slotMinTime={props.slotMinTime}
                slotMaxTime={props.slotMaxTime}
                handleWindowResize={props.handleWindowResize}
                expandRows={props.expandRows}
                stickyHeaderDates={true}
                stickyFooterScrollbar={true}
                slotDuration={props.slotDuration}
                slotLabelInterval={props.slotDuration}
                slotLabelFormat={{
                    hour: "numeric",
                    minute: "2-digit",
                    omitZeroMinute: false,
                    meridiem: "short",
                }}
                nowIndicator={true}
                eventOverlap={props.eventOverlap}
                slotEventOverlap={props.slotEventOverlap}
                fixedMirrorParent={document.querySelector(props.fixedMirrorParent)}
                selectOverlap={props.selectOverlap}
                selectable={props.selectable}
                aspectRatio={props.aspectRatio}

                contentHeight={20}
                validRange={props.validRange}
                // selectAllow={props.selectAllow}
                selectConstraint={props.selectConstraint}
                ref={ref}
                views={{
                    dayGridMonth: {
                        dayMaxEvents: props.dayMaxEvents,
                        dayMaxEventRows: props.dayMaxEventRows
                    }
                }}
            />
        </div>
    );
};

Calendar.defaultProps = {
    fixedMirrorParent: 'body',
    selectConstraint: {},
    selectAllow: {},
    validRange: { start: new Date() },
    dayMaxEvents: false,
    eventMaxStack: 10,
    aspectRatio: 1.35,
    locale: "es",
    expandRows: true,
    handleWindowResize: true,
    eventOverlap: false,
    slotEventOverlap: false,
    selectOverlap: false,
    selectable: true,
    hiddenDays: [],
    editable: true,
    droppable: true,
    weekends: true,
    eventLimit: false,
    drag: "dm294rvcmccjfveccewcfmc2ewocx3e",
    events: [],
    headers: {
        left: "today prev title next",
        center: "dayGridMonth timeGridWeek timeGridDay",
        right: "add",
    },
    buttons: {
        add: {
            text: "Agendar nueva cita",
            click: function () { },
        },
    },
    height: "calc(100vh - 20px)",
    initialView: "timeGridWeek",
    slotMinTime: "08:00:00",
    slotMaxTime: "22:00:00",
    slotDuration: "00:15:00",
    allDaySlot: false,
    refDrag: undefined,
    dragSelector: ".event",
};

export default Calendar; //connect(Calendar);