import React from "react";
import styled from "styled-components";

const Card = ({ status, type, patientName, service, date }) => {
  return (
    <StyledCard status={status} type={type}>
      {patientName && <p>{patientName}</p>}
      {service && <p>{service}</p>}
    </StyledCard>
  );
};

export default Card;

const determineBackgroundColor = (status) => {
  switch (status) {
    case "agendado no confirmado por usuario":
      return "red";
    case "agendado confirmado asistencia por usuario":
      return "orange";
    case "en espera":
      return "blue";
    case "en atención":
      return "green";
    case "atendido":
      return "grey";
    default:
      return "white";
  }
};

const determineStripeColor = (type) => {
  return type === "presencial" ? "grey" : type === "online" ? "blue" : "white";
};

const StyledCard = styled.div`
  padding: 8px 1.1875rem;
  margin-bottom: 5px;
  background-color: ${(props) => determineBackgroundColor(props.status)};
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1rem;
    color: white;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 12px;
    background-color: ${(props) => determineStripeColor(props.type)};
  }
`;
