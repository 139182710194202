export const configInit = () => {
  let i = 0;
  let arr = [];
  while (i < 24) {
    arr.push(false);
    i++;
  }

  return arr;
};

export const toInt = (obj = { hours: 0, minutes: 0, seconds: 0 }) => {
  let { hours, minutes, seconds } = obj;
  return hours * 3600 + minutes * 60 + seconds;
};

export const hoursToSeconds = (h) => {
  if (h === 0) return 0;
  else return parseInt(h) * 3600;
};

export const minutesToSeconds = (m) => {
  if (m === 0) return 0;
  else return parseInt(m) * 60;
};

export const secondsToHour = (segundosP) => {
  const segundos = Math.round(segundosP % 0x3c).toString();
  const s = segundos <= 0 ? "00" : segundos < 10 ? "0" + segundos : segundos.toString();
  const horas = Math.floor(segundosP / 0xe10);
  const h = horas <= 0 ? "00" : horas < 10 ? "0" + horas : horas.toString();
  const minutos = (Math.floor(segundosP / 0x3c) % 0x3c).toString();
  const m = minutos <= 0 ? "00" : minutos < 10 ? "0" + minutos : minutos.toString();
  return `${h}:${m}:${s}`;
};

export const sumTotalTime = (repeticiones, time) => {
  const date = new Date();
  const [h, m, s] = time.split(":");
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setDate(1);
  date.setMonth(0);
  date.setFullYear(1900);

  for (let i = 0; i < repeticiones; i++) {
    date.setHours(date.getHours() + parseInt(h));
    date.setMinutes(date.getMinutes() + parseInt(m));
    date.setSeconds(date.getSeconds() + parseInt(s));
  }

  return { hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds() };
};

export const sumTotalTimebySeg = (repeticiones, time) => {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setDate(1);
  date.setMonth(0);
  date.setFullYear(1900);
  for (let i = 0; i < repeticiones; i++) date.setSeconds(date.getSeconds() + parseInt(time));
  return { hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds() };
};

export const calcWorkTime = (
  series,
  pausa_serie_min,
  pausa_series_seg,
  repeats,
  repeat_time,
  repeat_pause
) => {
  const total_time =
    (repeat_time + repeat_pause) * repeats + (60 * pausa_serie_min + pausa_series_seg);
  return sumTotalTimebySeg(series, total_time);
};

export const parseTimeToString = (time) => {
  const pt = (num) => (num < 10 ? "0" + num : num);
  return `${pt(time.hours)}:${pt(time.minutes)}:${pt(time.seconds)}`;
};

// * Calcular segundos totales de formato obj {hours: 0 , minutes:0, seconds: 0}
export const getSeconds = (state) => {
  return state.seconds + state.minutes * 60 + state.hours * 3600 + 0;
};

// * Calcular segundos totales de formato str '00:00:00'
export const getSecondsFromString = (elem) => {
  const arr = elem.split(":");
  return getSeconds({ hours: Number(arr[0]), minutes: Number(arr[1]), seconds: Number(arr[2]) });
};

// * Transforma total de segundos a formato '00:00:00'
export const secondsToDate = (seconds) => {
  // return !isNaN(seconds) ? new Date(seconds * 1000).toISOString().slice(10, 19) : "00:00:00";
  return !isNaN(seconds) ? (Math.floor(seconds / 3600)) + ":" + ("0" + Math.floor(seconds / 60) % 60).slice(-2) + ":" + ("0" + seconds % 60).slice(-2) : "00:00:00";
};



// * Genera validación en modo secuencial. Para canales cuales tengan pulsos que se sobrepongan.
export const overlapingValidation = ( array ) => {
  if (array.length <= 1) return

  const newFormatArray = array.map((item) => {
    const newObject = { start: item.pos, end: (item.pos + item.seconds) }
    return newObject
  })

  return newFormatArray.some((item, i) => {
    let tempArray = newFormatArray.filter((elem) => elem !== item )
    return tempArray.some((position) => (item.start >= position.start) && (item.start < position.end) )
  })
}