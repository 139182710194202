import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const TimePickers = (props) => {
  const { t } = useTranslation("global");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia("(min-width: 768px)").addListener(handler);
  }, []);

  useEffect(() => {
    if (props.data != null) {
      setMinutes(props.data.minutes);
      setSeconds(props.data.seconds);
      setHours(props.data.hours);
    }
  }, [props.data]);

  const validNanH = (v) => {
    if (isNaN(hours)) setHours(0);
  };

  const validNanM = (v) => {
    if (isNaN(minutes)) setMinutes(0);
  };

  const validNanS = (v) => {
    if (isNaN(seconds)) setSeconds(0);
  };

  const getValues = (v) => {
    if (props.values !== undefined) props.values(v);
  };

  const plus = (v) => {
    switch (v) {
      case 0:
        if (parseInt(hours) < 60) {
          setHours(parseInt(hours + 1));
          getValues({
            hours: parseInt(hours + 1),
            minutes: parseInt(minutes),
            seconds: parseInt(seconds),
          });
        }
        break;
      case 1:
        if (parseInt(minutes) < 60) {
          setMinutes(parseInt(minutes + 1));
          getValues({
            hours: parseInt(hours),
            minutes: parseInt(minutes + 1),
            seconds: parseInt(seconds),
          });
        }
        break;
      case 2:
        if (parseInt(seconds) < 60) {
          setSeconds(parseInt(seconds + 1));
          getValues({
            hours: parseInt(hours),
            minutes: parseInt(minutes),
            seconds: parseInt(seconds + 1),
          });
        }
        break;

      default:
        return;
    }
  };

  const minus = (v) => {
    switch (v) {
      case 0:
        if (hours > 0) {
          setHours(parseInt(hours - 1));
          getValues({
            hours: parseInt(hours - 1),
            minutes: parseInt(minutes),
            seconds: parseInt(seconds),
          });
        }
        break;
      case 1:
        if (parseInt(minutes) > 0) {
          setMinutes(parseInt(minutes - 1));
          getValues({
            hours: parseInt(hours),
            minutes: parseInt(minutes - 1),
            seconds: parseInt(seconds),
          });
        }
        break;
      case 2:
        if (parseInt(seconds) > 0) {
          setSeconds(parseInt(seconds - 1));
          getValues({
            hours: parseInt(hours),
            minutes: parseInt(minutes),
            seconds: parseInt(seconds - 1),
          });
        }
        break;

      default:
        return;
    }
  };

  const changeH = (v) => {
    if (parseInt(v) < 60 && parseInt(v) > 0) {
      setHours(parseInt(v));
      getValues({ hours: v, minutes, seconds });
    } else {
      setHours(0);
      getValues({ hours: 0, minutes, seconds });
    }
  };
  const changeM = (v) => {
    if (parseInt(v) < 60 && parseInt(v) > 0) {
      setMinutes(parseInt(v));
      getValues({ hours, minutes: parseInt(v), seconds });
    } else {
      setMinutes(0);
      getValues({ hours, minutes: 0, seconds });
    }
  };
  const changeS = (v) => {
    if (!minutes && !hours) {
      if (isNaN(parseInt(v))) {
        setSeconds(0);
        getValues({ hours, minutes, seconds: 0 });
      }
      if (parseInt(v) <= 60 && parseInt(v) > 0) {
        setSeconds(parseInt(v));
        getValues({ hours, minutes, seconds: parseInt(v) });
      } else {
        setSeconds(0);
        getValues({ hours, minutes, seconds: 0 });
      }
      return;
    }

    if (parseInt(v) < 60 && parseInt(v) > 0) {
      setSeconds(parseInt(v));
      getValues({ hours, minutes, seconds: parseInt(v) });
    } else {
      setSeconds(0);
      getValues({ hours, minutes, seconds: 0 });
    }
  };

  const value = (value) => {
    switch (value) {
      case 0:
        return (
          <input
            onBlur={validNanH}
            className="no-arrows-input counter-input"
            type="number"
            value={Number(hours).toString()}
            onChange={(c) => changeH(c.target.value)}
          />
        );
      case 1:
        return (
          <input
            onBlur={validNanM}
            className="no-arrows-input counter-input"
            type="number"
            value={Number(minutes).toString()}
            onChange={(c) => changeM(c.target.value)}
          />
        );
      case 2:
        return (
          <input
            onBlur={validNanS}
            className="no-arrows-input counter-input"
            type="number"
            value={Number(seconds).toString()}
            onChange={(c) => changeS(c.target.value)}
          />
        );

      default:
        return;
    }
  };

  const gridSize = () => {
    const { hours, minutes, seconds } = props;
    // eslint-disable-next-line no-array-constructor
    const arr = new Array(hours, minutes, seconds);
    const result = [];
    arr.map((e) => (e === true ? result.push(e) : null));

    switch (result.length) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      default:
        return 0;
    }
  };

  // TODO: Llevar a librerìa de componentes.
  const timer = (title, label) => {
    return (
      <div
        className="TimePickers"
        style={{ width: matches ? `calc(100% /${gridSize()})` : "100%" }}
      >
        <div className="lcd relative">
          <p>{title}</p>
          {value(label)}
        </div>
        <div className="controls">
          <button className="plus" onClick={() => plus(label)}>
            <span className="material-icons">expand_less</span>
          </button>
          <button className="minus" onClick={() => minus(label)}>
            <span className="material-icons">expand_more</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex align-items-end justify-content-center" style={{ width: "100%" }}>
      {props.hours ? timer(t("timer.hours"), 0) : null}
      {props.minutes ? timer(t("timer.minutes"), 1) : null}
      {props.seconds ? timer(t("timer.seconds"), 2) : null}
    </div>
  );
};

export default TimePickers;
