import React from "react";
import Loader from "./index";
import { useTranslation } from "react-i18next";

const LoaderContainer = (props) => {
  const { t } = useTranslation("global");
  return props.active ? (
    <div className="container-loader" style={{ minHeight: props.height }}>
      <div className="card-loader" style={props.style}>
        <div className="dblock">
          <Loader />
          <p>{props.text !== undefined ? props.text : t("loader")}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className={props.className} style={{ height: props.heightContent, ...props.style }}>
      {props.children}
    </div>
  );
};

LoaderContainer.defaultProps = {
  active: true,
  height: "70vh",
  heightContent: "",
  className: {},
  style: {},
};

export default LoaderContainer;
