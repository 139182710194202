import styled, { css } from 'styled-components'

export const ProgressBar = styled.div`
    border-radius: 3px;
    height: 25px;
    background-color: #FAFAFA;
    background-image: none;
    background-repeat: repeat-x;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    display: flex;
    width: 100%;
`

export const ProgressBarItem = styled.div`
    background: ${props => props && props.bg};
    width: ${props => props && props.width};
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
`

export const IconEvaluation = styled.span`
    color: ${props => props && props.disabled ? "#BBBBBB" : "#fdad34"};
    font-size: 18px;
    padding-right: 0.25rem!important;
`

export const PlanningContainer = styled.div`
    border: 0.5px solid #9CB2CD;
    border-radius: 3px;
    padding: 8px;
    background: white;
    border-left: 4px solid ${props => props && props.statusColor};
    & p{
        margin:0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`

export const ItemContainer = styled.div`
    width: 100%;
    display: inline-flex;
    padding: 8px;
    & p{
        margin:0;
    }

    ${props => props && props.center ? css`justify-content: center;` : ``}
`

export const HeaderContainer = styled.div`
    width: 100%;
    display: inline-flex;
    padding: 8px;
    & p{margin:0;}
`

export const Header = styled.div`
    background: #EEF2FF;
    font-weight: 600;
    color: #281b65;
    font-size: 14px;
    padding: 10px 0;
`

export const Accordeon = styled.div`
    height: 100%;
    padding: 5px 0;
    max-height: ${props => props && props.active ? `${props.heightOpen}px` : "50px"};
    overflow: hidden;
    transition: max-height .6s linear;
    margin: 15px 0;
`

export const ArrowContainer = styled.div`
    & svg{
        rotate: ${props => props && props.active ? "180deg" : "360deg"};
        transition: max-height .6s linear;
    }
`

export const ContainerMain = styled.div`
    background: white;
`

export const TitlePlans = styled.div`
    background: rgba(208,201,255,.19);
    font-weight: 600;
    color: #281b65;
    font-size: 14px;
    padding: 10px;
    margin: 8px 0;
`

export const ItemDetail = styled.div`
    margin-bottom:15px;
`

export const ArrowSVG = styled.div`
    display:flex;
    justify-content: center;
    align-items:center;
    & svg{
        margin-right: 10px;
        width: 10px;
        height: 10px;
    }
`



export const CEventStyle = styled.div`
    background: ${props => props && props.background};
    color: ${props => props && props.color};
    padding: 10px 5px;
`

export const Titlep = styled.p`
    margin:0;
    padding:0;
    font-size: ${props => props && props.small ? "12px" : "14px"};
    font-weight: ${props => props && props.bold ? "bold" : "normal"};
    color: #FFF;
`

export const CEventMonth = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    background: ${props => props && props.backgroundColor};
    border: 1px solid ${props => props && props.borderColor};
    width: 100%;
    border-radius: 3px;
    flex-direction: column;
`

export const CEventMonthText = styled.p`
    margin: 0; 
    font-size: 14px; 
    color: ${props => props && props.textColor};
    font-weight: ${props => props && props.bold ? "bold" : "normal"};
`

export const CGroupLocked = styled.div`
    position: relative; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    height: 100%; 
    text-align: center;
`

export const CGroupLockedText = styled.p`
    margin: 0; 
    padding:0;
    font-size: 14px; 
    color: ${props => props && props.textColor};
    font-weight: ${props => props && props.bold ? "bold" : "normal"};
`

export const CGroupNoAvailable = styled.div`
    position: relative;
    padding: 15px;
`;

export const CgroupNoAvailableDetail = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
    position: absolute; 
    bottom: 10px;
    right: 10px;

    & img {
        object-fit: cover; 
        width: 100%; 
        height: 100%;
    }
`
export const CRelative = styled.div`
    position: relative;
`

export const CLast = styled.div`
    position: absolute; 
    bottom: 0; 
    left: 0; 
    color: ${props => props && props.textColor};
    font-size: 20px;
    font-weight: ${props => props && props.bold ? "bold" : "normal"};
`

export const CLastImg = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
    position: absolute; 
    bottom: 10;
    right: 10px;

    & img {
        object-fit: cover; 
        width: 100%; 
        height: 100%;
    }
`


export const CircleStatus = styled.div`
    width:10px;
    height:10px;
    background-color: ${props => props && props.color};
    border-radius: 100px;
    margin: 0 10px;
    border: 1px solid #FFF;
`

export const BoxTitleEvent = styled.div`
    // margin-top: 8px;
    display:flex;
    align-items:center;
    margin: 0; 
    padding:0;
`

export const InfoEventModal = styled.div`
    height: 40px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    & h4{
        font-size: 16px;
        font-weight: 700;
        margin: 0;
    }

    & p { margin:0; }
`

export const HourInfoModal = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%!important;

    & p{
        font-size: 14px;
        color: #3c4043;
        line-height:20px;
        margin:0;
    }

    & p.suc, a.suc {
        font-size: 12px;
        color: #70757a;
        line-height:20px;
        margin:0;
    }

    & p.bold {
        font-weight: 700;
    }

    & .date{
        font-weight: 600;
    }

    & span.space{
        margin-right: 15px;
    }

    & .bZaNel{
        width:100%;
    }

    & .iUTKsX{
        width:100%;
    }
`

export const IconModal = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    height: 100%;
`

export const BarSeparateInfo = styled.div`
    height: 20px;
`


export const ContainerOverflow = styled.div`
    height: calc(100vh - 430px);
    overflow-x: hidden;
    overflow-y: scroll; 
`

export const ContainerDropdown = styled.div`
    margin-bottom: 15px;
    width:100%;
`

export const ContainerGoBack = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    width: 150px;
    margin-bottom: 10px;
    cursor: pointer;
    & svg{
        cursor: pointer;
    }

    & span {
        margin-left: 10px;
        font-size: 14px;
    }

    &:hover svg path{
        fill: #21252995;
    } 

    &:hover span{
        color: #21252995;
    }
`

export const ContainerEventItem = styled.div`
    display: flex;
    align-items: center;
    height: auto;
    background: ${props => props && props.backgroundColor};
    color: ${props => props && props.textColor};
`

export const ContainerTitleConfirm = styled.div`
    padding: 15px;
    background: #C7D9FF;
    display: inline-flex;
    border-radius: 6px;
    width:100%;
    & h2 {
        color:#281B65;
        font-size: 21px;
        font-weight: 700;
    }

    & svg {
        margin-left: 15px;
    }

    & svg path{
        fill: #281B65;
    }
`

export const ContainerMessageConfirm = styled.div`
    padding: 15px;
    background: #FFFFFF;
    display: inline-flex;
    border-radius: 6px;
    width:100%;
    & h4 {
        color:#263238;
        font-size: 16px;
    }

    & h4 span {
        font-weight: 700;
    }
`

export const ContainerDataConfirm = styled.div`
    padding: 15px;
    background: #FFFFFF;
    display: inline-flex;
    border-radius: 6px;
    border: 1px solid #CED4DA;
    width:100%;
`

export const ContainerDataItemConfirm = styled.div`
    padding: 15px;
    background: #FFFFFF;
    display: inline-flex;
    border-radius: 6px;
    margin-bottom:15px;
    width:100%;
    & p {
        color:#281B65;
        font-size: 12px;
    }

    & p span {
        font-weight: 700;
    }

    & .icon {
        width: 40px;
    }

    & .icon svg path{
        fill: #FDAD34;
    }
`

export const SidebarContent = styled.div`
  height: calc(100% - 84px);
  display: grid;
  grid-template-rows: 1fr 45px;

  & > .form > p {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;

    color: #182024;

    margin: 0 0 4px 0;

    &:last-child {
      margin: 30px 0 4px 0;
    }
  }

  & > .form > label {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    color: #182024;
  }

  & > .form > .mrgn-top-30 {
    margin-top: 30px;
  }
`;
