import React, { useEffect, useReducer } from "react";
import { connect } from "../../store";
import { useTranslation } from "react-i18next";

import {
	Container,
	Row,
	Col, ButtonCircle, Dropdown,
	SearchBar,
	TableServices,
	Modal,
	Button
} from "trainfes-components-library";
import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import ModalModos from "../../components/modalExtended";

import { useGetEvaluations } from "../../hooks";

import { formValidation, validateVarsData } from "./EvaluationsPage.utils";
import { IconsContainer, TableHeader } from "../../styles/views.styles";
import { filterValidation } from "../../lib/textValidation";
import { ModalNewEvaluation } from "./components/ModalNewEvaluation";
import { evaluationsReducer, initialState } from "./reducer/evaluationsReducer";
import { CLEAR_STATE, OPEN_PREVIEW, SET_FIELDS } from "./reducer/evaluationsActions";
import { Delete } from "../../assets/icons/pages";
import { EyeIcon } from "../../assets/icons";
import { ModalPreview } from "./components/ModalPreview";
import { ModalPreviewContainer, PreviewContainer } from "./EvaluationsPage.styles";

const Evaluations = (props) => {
	const { t } = useTranslation("evaluations");

	const { EvaluationsQuery, addEvaluation, updateEvaluation, deleteEvaluation, customLoading } = useGetEvaluations();

	const [state, dispatch] = useReducer(evaluationsReducer, initialState);

	const modes = [
		{ name: t('modes.normal'), cod: 0 },
		{ name: t('modes.main'), cod: 1 },
		{ name: t('modes.order'), cod: 2 },
	];
	
	const handleCreateVariable = () => {
		if( customLoading || !formValidation( state, t, props.notify) ) return;

		const validationResult = validateVarsData(state.varsData);
		if (!validationResult.valid) {
			return props.notify({ type: "error", title: t("evaluations"), text: t(validationResult.error) });
		}
		
		let dataToPost = {
			selectedId: state.selectedId,
			name: state.name,
			cat: state.cat,
			type: state.type,
			variables: state.varsData,
			desc: state.desc,
			instructions: state.instructions,
		};

		try {
			if (state.openEdit) {
				dataToPost.selectedId = state.selectedId;
				updateEvaluation(dataToPost)
				props.notify({ type: "success", title: t("evaluations"), text: t("notify.evaluation_updated") });
			} else {
				addEvaluation(dataToPost)
				props.notify({ type: "success", title: t("evaluations"), text: t("notify.evaluation_created") });
			}
			
			dispatch({ type: CLEAR_STATE })
		} catch (error) {
			dispatch({ type: CLEAR_STATE })
			props.notify({ type: "error", title: t("evaluations"), text: t("notify.error_creating") });
		}
	};

  	const handleDeleteEvaluation = () => {
		if( customLoading ) return;

		try {
			deleteEvaluation(state.idCurrent);
			dispatch({ type: CLEAR_STATE })
			props.notify({ type: "success", title: t("evaluations"), text: t("notify.evaluation_removed") });

		} catch (error) {
			props.notify({ type: "error", title: t("evaluations"), text: t("notify.error_deleting") })
		}
	};

	const pushVars = (type) => {
		const d = Object.assign([], state.vars);
		if (type === 2) {
			d.splice( d.findIndex((r) => r.id === "fase"), 1 );
			d.push({ name: "Diagnóstico", type: { value: "string" }, id: "diagnostic", required: true });
			d.push({ name: "Numero de sesiones", type: { value: "number" }, id: "ns", required: true });
			d.push({ name: "Frecuencia de sesiones", type: { value: "number" }, id: "fs", required: true });
			d.push({ name: "Objetivos de rehabilitación", type: { value: "textarea" }, id: "ob", required: true });
		} else if (type === 1) {
			d.splice( d.findIndex((r) => r.id === "diagnostic"), 1 );
			d.splice( d.findIndex((r) => r.id === "ns"), 1 );
			d.splice( d.findIndex((r) => r.id === "fs"), 1 );
			d.splice( d.findIndex((r) => r.id === "ob"), 1 );
			d.push({ name: "Complemento", type: { value: "string" }, id: "fase", required: true });
		} else if (type === 0) {
			d.splice( d.findIndex((r) => r.id === "diagnostic"), 1 );
			d.splice( d.findIndex((r) => r.id === "ns"), 1 );
			d.splice( d.findIndex((r) => r.id === "fs"), 1 );
			d.splice( d.findIndex((r) => r.id === "ob"), 1 );
			d.splice( d.findIndex((r) => r.id === "fase"), 1 );
		}

		dispatch({ type: SET_FIELDS, payload: { field: "vars", value: d } })
	};

	useEffect(() => pushVars(state.type), [state.type]);

	const handleOpenDelete = ( id ) => {
		dispatch({ type: SET_FIELDS, payload: { field: "idCurrent", value: id } })
		dispatch({ type: SET_FIELDS, payload: { field: "removeModal", value: true } })
	}

	const columns = [
		{ key: "name", text: t("name"), alignContent: "center", alignTitle: "center", maxWidth: "150px" },
		{ key: "cat.name", text: t("category"), alignContent: "center", alignTitle: "center", maxWidth: "150px" },
		{
		  key: "no_fields", text: t("number_of_fields"), type: "custom", alignContent: "center", alignTitle: "center", maxWidth: "150px",
		  elem: (payload) => (
			<>{payload.variables.length}</>
		  ),
		},
		{
		  key: "controls", text: t("actions"), type: "custom", alignContent: "center", alignTitle: "center", maxWidth: "150px",
		  elem: (payload) => (
			<IconsContainer>
			  <EyeIcon onClick={ () => dispatch({ type: OPEN_PREVIEW, payload }) } />
			  <Delete onClick={ () => handleOpenDelete( payload._id ) } />
			</IconsContainer>
		  ),
		},
	];

	const filterOptions = [
		{ label: t("name"), value: "name", key: "name" },
		{ label: t("category"), value: "cat", key: "cat" },
	];

	useEffect(() => {
		handleFilter();
	}, [ state.inputFilter, EvaluationsQuery.data ]);
	
	const handleFilter = () => {
		let data = Object.assign( [], EvaluationsQuery.data );
	
		if (state.inputFilter === "") {
			return dispatch({
				type: SET_FIELDS,
				payload: {
					field: "filteredEvaluations",
					value: data.reverse(),
				},
			});
		}

		if (state.dropdownFilter === "cat") {
			return dispatch({
				type: SET_FIELDS,
				payload: {
					field: "filteredEvaluations",
					value: data.reverse().filter((e) => {
						return filterValidation( e.cat.name ).includes( filterValidation( state.inputFilter ) )
					}),
				},
			});
		}
		
		return dispatch({
			type: SET_FIELDS,
			payload: {
				field: "filteredEvaluations",
				value: data.reverse().filter((e) => {
					return filterValidation( e[ state.dropdownFilter ] ).includes( filterValidation( state.inputFilter ) )
				} ),
			},
		})
	}

	const handlePreview = () => {
		if( customLoading || !formValidation( state, t, props.notify) ) return;
		dispatch({ type: SET_FIELDS, payload: { field: "previewActive", value: !state.previewActive } })
	}
	
	const handleEdit = () => {
		dispatch({ type: SET_FIELDS, payload: { field: "openPreview", value: false } })
		dispatch({ type: SET_FIELDS, payload: { field: "openEdit", value: true } })
	}
	
	return (
		<>
		<Container>
			<Row cols={12}>
				<Col xs={12}>
					<CardLayoutPlatform
						noPaddingBottom
						loading={ customLoading }
						title={ t("evaluations") }
						floatButton={
							<ButtonCircle onClick={ () => dispatch({ type: SET_FIELDS, payload: { field: "newEvaluationModal", value: true } }) } />
						}
					>
						<TableHeader>
							<p>{t("search_by")}</p>
							<Dropdown
								height={38}
								options={filterOptions}
								value={ filterOptions.filter( (e) => e.value === state.dropdownFilter)[0] }
								onChange={(e) =>
									dispatch({ type: SET_FIELDS, payload: { field: "dropdownFilter", value: e.value } })
								}
							/>
							<SearchBar
								borderRadius="6px"
								height="38px"
								placeholder={t("search")}
								onChange={(e) =>
									dispatch({ type: SET_FIELDS, payload: { field: "inputFilter", value: e.target.value } })
								}
							/>
                		</TableHeader>

						<TableServices
							data={ state.filteredEvaluations }
							columns={ columns }
							searchKeys="title type"
							autoHeight={false}
							perPage={10}
							pagination={true}
							filterSelect={false}
							filterInput={false}
							perPageSelect={false}
							textEmptyData={t("no_data")}
						/>
					</CardLayoutPlatform>
				</Col>
			</Row>
		</Container>

		<ModalModos
			open={ state.newEvaluationModal || state.openEdit }
			setOpen={() =>
					dispatch({
					type: SET_FIELDS,
					payload: { field: "newEvaluationModal", value: true },
				})
			}
			onClose={ () => {
				dispatch({ type: CLEAR_STATE })}}
			callback={() => handleCreateVariable()}
			title={t("new_evaluation")}
			btnText={t("save")}
			preview
			btnPreviewText={ state.previewActive ? t("edit") : t("preview") }
			handleClickPreview={ handlePreview }
		>
			{
				state.previewActive ?
				(
					<PreviewContainer>
						<ModalPreviewContainer>
							<ModalPreview t={t} state={state} dispatch={dispatch} />
						</ModalPreviewContainer>
					</PreviewContainer>
				)
			:
				(
					<ModalNewEvaluation
						t={t}
						notify={props.notify}
						state={state}
						dispatch={dispatch}
						modes={modes}
					/>

				)
			}
		</ModalModos>

		<Modal
            isOpen={ state.openPreview }
            buttons={
                <>
                    <Button
                        type="ghost"
                        label={t("edit")}
                        onClick={ handleEdit }
					/>
                    <Button
                        label={t("accept")}
                        onClick={ () => dispatch({ type: CLEAR_STATE }) }
                        disabled={ state.isPosting }
                    />
                </>
            }
        >
            <ModalPreview t={t} state={state} dispatch={dispatch} />
        </Modal>

		<Modal
			title={ t("really_want_to_delete") + "?" }
			isOpen={ state.removeModal }
			buttons={
			<>
				<Button
					type="ghost"
					label={t("categories.cancel")}
					onClick={ () => dispatch({ type: CLEAR_STATE }) }
				/>
				<Button
					label={t("categories.remove")}
					onClick={ handleDeleteEvaluation }
					disabled={ state.isPosting }
				/>
			</>
			}
		>
      	</Modal>
		</>
	);
};

export default connect(Evaluations);
