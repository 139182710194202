import React from "react";
import "./style.css";
import { connect } from "../../store";
// import { Alert, AlertTitle } from "@material-ui/lab";
import { Alert } from "trainfes-components-library";
import toast, { Toaster } from "react-hot-toast";

const Notification = (props) => {
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        containerStyle={{ margin: 0, zIndex: 999999 }}
        toastOptions={{
          className: "",
          duration: 4000,
          style: {
            background: "transparent",
            padding: 0,
            boxShadow: "none",
          },
        }}
      />
      {props.store.notifications.map((e, i) => {
        props.notifyRemove(e);
        // setTimeout(() => props.notifyRemove(e), e.time);
        switch (e.type) {
          case "error":
            return toast((t) => (
              <Alert key={i} type="error" title={e.title} description={e.text} />
            ));
          case "warning":
            return toast((t) => (
              <Alert key={i} type="warning" title={e.title} description={e.text} />
            ));
          case "info":
            return toast((t) => <Alert key={i} type="info" title={e.title} description={e.text} />);
          case "success":
            return toast((t) => (
              <Alert key={i} type="success" title={e.title} description={e.text} />
            ));
          default:
            return (
              <Alert
                key={i}
                variant="filled"
                severity="info"
                title={e.title}
                description={e.text}
              />
            );
        }
      })}
    </>
  );
};

export default connect(Notification);
