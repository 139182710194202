import React, { useState, useEffect } from "react";

import { DeleteIcon, EditIcon } from "../../../../assets/icons";

import { DotsTooltip } from "trainfes-components-library";

import * as S from "./RowTraining.styles";
import * as I from "../../../../assets/icons/modes-icons";

export const RowTraining = React.forwardRef(
  (
    {
      id,
      index,
      data,
      edit,
      remove,
      t,
      hoursToSeconds,
      minutesToSeconds,
      convertir,
      className,
    },
    ref
  ) => {
    const RowIcons = {
      "icon-sequential": <I.SequentialIcon />,
      "icon-sensor": <I.SensorIcon />,
      "icon-bike": <I.BikeIcon />,
      "icon-rowing": <I.RowingIcon />,
      "icon-bipedestation": <I.BipedestationIcon />,
      "icon-pain": null,
      "icon-flexibility": null,
      "icon-stimulation": null,
      "icon-heart": null,
      "icon-alert": <I.AdvertenciaIcon />,
      "icon-pause": <I.DescansandoIcon />,
      "icon-video": <I.VideoIcon />,
      "icon-evaluation": <I.GeneralIcon />,
      "icon-march": <I.MarchIcon />,
      "icon-treadmill": <I.TreadmillIcon />,
      "icon-feedback": <I.FeedbackIcon />,
      "icon-cicloergometro": <I.CicloergometroIcon />,
      "icon-form": <I.EvaluationIcon />,
      "icon-training-therapist": <I.ArchivoIcon />,
    };

    const [time, setTime] = useState("00:00:00");

    useEffect(() => {
      if (!data.params) return;
      setTime(timeLabel());
    }, [data]);

    const timeLabel = () => {
      if (
        data.mode === "records" ||
        data.mode === "evaluations" ||
        data.mode === "warnings"
      )
        return "-";

      if (data.params.time) return data.params.time;
      if (data.params.tiempo_trabajo) {
        let pes =
          hoursToSeconds(data.params.tiempo_trabajo.hours) +
          minutesToSeconds(data.params.tiempo_trabajo.minutes) +
          data.params.tiempo_trabajo.seconds;
        return convertir(pes);
      }

      return "00:00:00";
    };

    const title =
      data.name || data.params.technical_name || data.params.public_name || "";
    const titleCut = 23;

    const titleValidation = () => {
      return title.slice(0, titleCut);
    };

    const repetitionsValidator = (mode) => {
      switch (mode) {
        case "graphic":
          return data.params.num_contraccion;
        case "sensor":
          return data.params.num_contraccion;

        default:
          return data.params.repeticiones || "-";
      }
    };

    return (
      <S.Container id={id} ref={ref} className={className}>
        <S.Index>{index}</S.Index>

        <S.Icon>
          {RowIcons[data.icon] || <span className={data.icon}></span>}
        </S.Icon>

        <S.Info>
          <p className="__title">{t(data.mode)}</p>
          <p className="__name">
            {titleValidation()}
            <span style={{ pointerEvents: "auto" }}>
              {title.length > titleCut && <DotsTooltip content={title} />}
            </span>
          </p>
        </S.Info>

        <S.SubInfo>
          <p className="__title">{t("pdf_files")}</p>
          <p className="__value">
            {data.params.files && data.params.files.length > 0
              ? data.params.files.length
              : "No"}
          </p>
        </S.SubInfo>

        <S.SubInfo>
          <p className="__title">{t("repeat")}</p>
          <p className="__value">{repetitionsValidator(data.mode)}</p>
        </S.SubInfo>

        <S.SubInfo>
          <p className="__title">{t("titleSeries")}</p>
          <p className="__value">
            {data.params.series
              ? data.params.series
              : data.series
              ? data.series
              : "-"}
          </p>
        </S.SubInfo>

        <S.SubInfo>
          <p className="__title">{t("others")}</p>
          <p className="__value">-</p>
        </S.SubInfo>

        <S.SubInfo>
          <p className="__title">{t("time")}</p>
          <p className="__time">{time}</p>
        </S.SubInfo>

        <S.Icons>
          <EditIcon onClick={edit} />
          <DeleteIcon onClick={remove} />
        </S.Icons>
      </S.Container>
    );
  }
);
