import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Tool from "../../components/tool";
import Upload from "../../components/upload";
import { getUserId } from "../../lib/router";
import IconButton from "@material-ui/core/IconButton";
import Tab from "../../components/tab";
import { setToken } from "../../lib/router";
import { connect } from "../../store";
import { useTranslation } from "react-i18next";
import TherapistProfile from "./therapistProfile";
import { getUrl } from "../../lib/utils";
import API from "../../services/api";
import { eventListener } from "../../services/socket";
import PhoneInput from "react-phone-input-2";

const api = new API();
let fileUploading = null;

const UserProfile = (props) => {
  const { t } = useTranslation("profile");
  // const [data, setData] = useState({});
  const [file, setFile] = useState(null);
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState("");
  const [cargo, ] = useState("");
  const [subcargo, ] = useState("");

  const parseHashFileName = () => {
    return `${fileUploading.name.replace(new RegExp(" ", "g"), "_")}:::${fileUploading.size}`;
  };

  useEffect(() => {
    getData();
    getAllNotifications();

    eventListener("file_upload", function (data) {
      if (parseHashFileName() === data.id) {
        console.log("file_upload ->", data.progress, "%");
      }
    });
  }, []);

  // useEffect(() => {
  //     getData();
  // }, [props.store.user])

  const getData = () => {
    setName(props.store.user.name);
    setLastname(props.store.user.lastname);
    setEmail(props.store.user.email);
    setPhone(props.store.user.phone);
    setAvatar(props.store.user.avatar);
  };

  const getAllNotifications = () => {
    // Axios.get("/user/notifications/" + getUserId().userId)
    api
      .getNotificationsProfile(getUserId().userId)
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((err) => props.notify({ type: "error", title: "Notificaciones", text: err }));
  };

  const updatePassword = () => {
    if (newPass.length === 0 || /^\s+$/.test(newPass))
      props.notify({ type: "error", title: t("tab1.notyTitle"), text: t("tab1.noWhite") });
    else {
      if (newPass !== confirmPass)
        props.notify({ type: "error", title: t("tab1.notyTitle"), text: t("tab1.noMatch") });
      else {
        api
          .setPasswordProfile(getUserId().userId, { password: currentPass, newpass: newPass })
          .then((res) => {
            if (res.success)
              props.notify({ type: "success", title: t("tab1.notyTitle"), text: t("tab1.update") });
            else props.notify({ type: "error", title: t("tab1.notyTitle"), text: t("tab2.err") });
          })
          .catch((err) =>
            props.notify({ type: "error", title: t("tab1.notyTitle"), text: err.toString() })
          );
      }
    }
  };

  const saveProfile = () => {
    if (name === "" || lastname === "" || email === "" || phone === "") {
      return props.notify({ type: "error", title: "Error", text: t("tab1.noWhite") });
    }

    if (
      name === props.store.user.name &&
      lastname === props.store.user.lastname &&
      email === props.store.user.email &&
      phone === props.store.user.phone &&
      file === null
    ) {
      return props.notify({
        type: "error",
        title: "Error",
        text: t("tab1.noChange"),
      });
    }  

    const form = new FormData();
    const user = getUserId();
    form.append("name", name);
    form.append("lastname", lastname);
    form.append("email", email);
    form.append("phone", phone);
    form.append("job", cargo);
    form.append("subjob", subcargo);

    if (file != null) {
      form.append("files", file, file.name);
      fileUploading = file;
    }

    api
      .setProfile(user.userId, form)
      .then((response) => {
        setToken(response.token);
        props.notify({ type: "success", title: t("tab1.success"), text: t("tab1.success") });
      })
      .catch((err) => {
        props.notify({ type: "error", title: "Error", text: err.toString() });
        console.log(err);
      });
  };

  let rol = getUserId().rol.toLowerCase();
  if (rol === "therapist" || rol === "admin-clinic" || rol === "teraphist") {
    return <TherapistProfile />;
  } else {
    return (
      <Grid className="card-plataform bg-white m-3">
        <Grid container direction="row" justifyContent="center" alignItems="center" className="">
          <Grid item xs={2}>
            <div
              className="nav flex-column nav-pills sombra-left bg-white"
              style={{ minHeight: "600px" }}
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className="nav-link pl-4 py-3 active"
                id="v-pills-home-tab"
                data-toggle="pill"
                href="src#v-pills-home"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                {t("tab1.title")}
              </a>
              <a
                className="nav-link pl-4 py-3"
                id="v-pills-profile-tab"
                data-toggle="pill"
                href="src#v-pills-profile"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                {t("tab2.title")}
              </a>
              <a
                className="nav-link pl-4 py-3"
                id="v-pills-messages-tab"
                data-toggle="pill"
                href="src#v-pills-messages"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                {t("tab3.title")}
              </a>
              {/*<a className="nav-link pl-4 py-3" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">{t('tab4.title')}</a>
                            <a className="nav-link pl-4 py-3" id="v-pills-tutor-tab" data-toggle="pill" href="#v-pills-tutor" role="tab" aria-controls="v-pills-tutor" aria-selected="false">{t('tab5.title')}</a>*/}
            </div>
          </Grid>
          <Grid item xs={10}>
            <div
              className="tab-content sombra-right border-left bg-white"
              style={{ minHeight: "600px" }}
              id="v-pills-tabContent"
            >
              <div
                className="tab-pane fade show active scroll-list"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className="py-4"
                >
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Upload img={getUrl(avatar)} value={(val) => setFile(val)} notify={props.notify} t={t} />
                  </Grid>
                  <Grid item xs={8} className="pl-3">
                    <h5 className="m-0">
                      {name} {lastname}
                    </h5>
                    <label
                      style={{ cursor: "pointer" }}
                      className="hover"
                      htmlFor="icon-button-file"
                    >
                      {t("tab1.changeAvatar")}
                    </label>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.name")}</p>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="pl-3"
                  >
                    <input
                      type="text"
                      className="w-100 mr-5 my-2 form-control"
                      defaultValue={name || ""}
                      onChange={(value) => setName(value.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.lastname")}</p>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="pl-3"
                  >
                    <input
                      type="text"
                      className="w-100 mr-5 my-2 form-control"
                      defaultValue={lastname || ""}
                      onChange={(value) => setLastname(value.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.email")}</p>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="pl-3"
                  >
                    <input
                      type="text"
                      className="w-100 mr-5 my-2 form-control"
                      defaultValue={email || ""}
                      onChange={(value) => setEmail(value.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab1.phone")}</p>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="pl-3"
                  >
                    <PhoneInput
                      inputClass="w-100"
                      containerClass="w-100 mr-5 my-2"
                      value={ phone || "" }
                      inputExtraProps={{ required: true }}
                      country={"cl"}
                      countryCodeEditable={false}
                      enableSearch
                      searchPlaceholder={t("search")}
                      specialLabel={""}
                      onChange={(value) => setPhone(value)}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    className="my-2 mr-5"
                    >
                      <button onClick={() => saveProfile()} className="btn-white-tf py-2">
                        {t("tab1.save")}
                      </button>
                    </Grid>
                </Grid>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className="py-4"
                >
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      fontSize="large"
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img
                        src={avatar}
                        alt=""
                        style={{ cursor: "pointer", borderRadius: 100, width: 40, height: 40 }}
                        className="img-fluid"
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={8} className="pl-3">
                    <h5 className="m-0">
                      {name} {lastname}
                    </h5>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab2.prevPass")}</p>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="pl-3"
                  >
                    <TextField
                      className="w-100 mr-5"
                      id="outlined-margin-dense2"
                      margin="dense"
                      variant="outlined"
                      onChange={(val) => setCurrentPass(val.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <p className="m-0 pr-3 fz-12 color-sub-text">{t("tab2.newPass")}</p>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="pl-3"
                  >
                    <TextField
                      className="w-100 mr-5"
                      id="outlined-margin-dense2"
                      margin="dense"
                      variant="outlined"
                      onChange={(val) => setNewPass(val.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" alignItems="">
                  <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems=""
                  >
                    <p className="m-0 pr-3 pt-3 fz-12 color-sub-text">{t("tab2.confirm")}</p>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="pl-3"
                  >
                    <TextField
                      className="w-100 mr-5"
                      id="outlined-margin-dense2"
                      margin="dense"
                      variant="outlined"
                      onChange={(val) => setConfirmPass(val.target.value)}
                    />
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      className="my-2 mr-5"
                    >
                      <button onClick={() => updatePassword()} className="btn-white-tf py-2">
                        {t("tab1.save")}
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div
                className="tab-pane scroll-list fade pt-4"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                {notifications.length > 0 &&
                  notifications.map((e) => {
                    return (
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className="border-bottom-tf p-2"
                      >
                        <Grid
                          item
                          xs={2}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          className="pr-3"
                        >
                          <div className="icon-info-xs">
                            <span className="icon-notification icon-display"></span>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <div dangerouslySetInnerHTML={{ __html: e.content }}></div>
                          <p className="m-0 fz-14 color-sub-text">10/07/2020</p>
                        </Grid>
                      </Grid>
                    );
                  })}
                {notifications.length === 0 ? (
                  <div className="p-4">{t("tab3.notFound")}</div>
                ) : null}
              </div>
              <div
                className="tab-pane scroll-list fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <h6 className="p-4">Tus logros</h6>
              </div>
              <div
                className="tab-pane scroll-list fade"
                id="v-pills-tutor"
                role="tabpanel"
                aria-labelledby="v-pills-tutor-tab"
              >
                <Tab
                  tabs={[
                    {
                      title: "Tus Tutores",
                      content: (
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="p-2 pt-5"
                        >
                          <Table>
                            <TableHead>
                              <TableRow align="left">
                                <TableCell>Nombre</TableCell>
                                <TableCell>Apellido</TableCell>
                                <TableCell>Correo</TableCell>
                                <TableCell>Teléfono</TableCell>
                                <TableCell>Editar</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow align="center">
                                <TableCell>Helen</TableCell>
                                <TableCell>Harnisch</TableCell>
                                <TableCell>harnisch@gmail.com</TableCell>
                                <TableCell>9 79957398</TableCell>
                                <TableCell className="d-flex align-items-center justify-content-center">
                                  <div className="icon-info d-flex align-items-center justify-content-center">
                                    <Tool className="icon-tools color-primario" />
                                  </div>
                                </TableCell>
                              </TableRow>
                              <TableRow align="left">
                                <TableCell>Luis</TableCell>
                                <TableCell>Viches</TableCell>
                                <TableCell>vilches@gmail.com</TableCell>
                                <TableCell>9 99987398</TableCell>
                                <TableCell className="d-flex align-items-center justify-content-center">
                                  <div className="icon-info d-flex align-items-center justify-content-center">
                                    <Tool className="icon-tools color-primario" />
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      ),
                    },
                    {
                      title: "Crear nuevo tutor",
                      content: (
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className="p-2 pt-5"
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={2}
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              <p className="m-0 pr-3 fz-12 color-sub-text">Nombre</p>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              className="pl-3"
                            >
                              <input type="text" className="w-100 mr-5 my-2 form-control" />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={2}
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              <p className="m-0 pr-3 fz-12 color-sub-text">Apellido</p>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              className="pl-3"
                            >
                              <input type="text" className="w-100 mr-5 my-2 form-control" />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={2}
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              <p className="m-0 pr-3 fz-12 color-sub-text">Email</p>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              className="pl-3"
                            >
                              <input type="text" className="w-100 mr-5 my-2 form-control" />
                            </Grid>
                          </Grid>
                          <Grid container direction="row" justifyContent="center" alignItems="">
                            <Grid
                              item
                              xs={2}
                              container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems=""
                            >
                              <p className="m-0 pr-3 pt-3 fz-12 color-sub-text">Teléfono</p>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              className="pl-3"
                            >
                              <input type="text" className="w-100 mr-5 my-2 form-control" />
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                className="my-2 mr-5"
                              >
                                <button className="btn-white-tf py-2">Guardar</button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default connect(UserProfile);
