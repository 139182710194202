import { useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import API from "../services/api";

const api = new API();

export const useGetServiceTypes = () => {

    // * Array para usar en Dropdowns.
    const [serviceTypes, setServiceTypes] = useState([])

    const serviceTypesQuery = useQuery({
        queryKey: ["service-types"],
        queryFn: () => api.getServiceTypes()
            .then((res) => {

                setServiceTypes(
                    res.data.map((e) => {
                        return { label: e.name, value: e._id };
                    })
                )

                return res.data
            }),
        refetchOnWindowFocus: false,
    });

    const { mutate: addNewServiceType, isLoading: createLoading } = useMutation(
        ( newServiceType ) => {
            return api.createServiceTypes( newServiceType )
        },
        {
            onSuccess: async () => serviceTypesQuery.refetch()
        }
    );
    
    const { mutate: editServiceType, isLoading: editLoading } = useMutation(
        ( { id, data } ) => {
            return api.editServiceTypes( id, data )
        },
        {
            onSuccess: async () => serviceTypesQuery.refetch()
        }
    );

    const { mutate: deleteServiceType, isLoading: deleteLoading } = useMutation(
        ( id ) => {
            return api.deleteServiceTypes( id )
        },
        {
            onSuccess: async () => serviceTypesQuery.refetch()
        }
    );

    const customLoading = createLoading || editLoading || deleteLoading;

    return {
        serviceTypesQuery,
        addNewServiceType,
        editServiceType,
        deleteServiceType,

        serviceTypes,
        customLoading
    }
}
