// -> Métodos para DatePickers. Configura fechas mínimas y máximas en calendario.
import { dateStampToJs, dateToJs } from "../../lib/date.helpers";

export const handleDatePickerChange = (
  currentValue,
  oppositeValue,
  setMinMaxDate,
  setDateStart = () => {}
) => {
  setMinMaxDate({ from: currentValue, to: oppositeValue });

  if (currentValue && oppositeValue) {
    setDateStart({ from: currentValue, to: oppositeValue });
  }
};

export const handleDatePickerChangeEvol = (val, setDate, minMaxDate) => {
  // val          ?   Value de Datepicker.
  // setDate      ?   State (setFromDate || setToDate).
  // minMaxDate   ?   State (setMinimumDate || setMaximumDate).
  
  setDate(val);
  minMaxDate(val);
}

export const handleDateFilter = (item, stateDateFilter) => {
  // ? stateDateFilter corresponde al estado que almacena los rangos del filtro de fechas.

  const { from, to } = stateDateFilter;
  const itemDate = item.date ? dateStampToJs(item.date) : dateToJs("00/00/0000");

  if (from && to) return itemDate >= dateToJs(from) && itemDate <= dateToJs(to);
  if (from) return itemDate >= dateToJs(from);
  if (to) return itemDate <= dateToJs(to);
  return item;
};

export const FORM_INITIAL_VALUE = {
  nationality: "",
  tenantId: "",
  rut: "",
  name: "",
  lastname: "",
  email: "",
  phone: "",
  other_address: "",
  genre: "",
  birthdate: "",
  clinic: "",
  insurance_company: "",
  treating_doctor: "",
  years_injury: "",
  injury_years: "",
  rol: "patient",
  reference: "",
};
