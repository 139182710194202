import React from 'react';
import Slider from '@material-ui/core/Slider';


export default function CustomizedSlider(props) {

    const handleSliderChange = (event, newValue) => {
        if(props.onChange !== undefined) props.onChange(newValue);
    };

    return (
        <Slider valueLabelDisplay="auto" step={props.step || 1} aria-label="custom thumb label"  value={props.defaultValue || 0} min={props.min || 0} max={props.max || 100} onChange={handleSliderChange} />
    );
}



