import React, { useState, useEffect } from "react";
import ProgressBar from "../../../../components/progressBar";
import LoaderContainer from "../../../../components/loader/container";
import ButtonAdd from "../../../../components/buttonAdd";
import ImgUpload from "../../../../assets/img/illustrations/upload.svg";
import MediaManager from "../../media";
import { eventListener } from "../../../../services/socket";
import { connect } from "../../../../store";
import { useTranslation } from "react-i18next";
import "../../../../assets/css/video.css";
import "../../style.css";

import { Modal, Button, Input, Dropdown } from "trainfes-components-library";

import VideoPlayer from "../../../../components/player";
import { Card } from "../../components/Card";
import {
  CardsContainer,
  Label,
  VideoModalContainer,
  TextArea,
  ModalDescription,
} from "../../Library.styles";

const mediaManager = new MediaManager();
const Video = (props) => {
  const { t } = useTranslation("library");
  const [load, setLoad] = useState(true);
  const [openVideo, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [addFile, setAddFile] = useState(false);
  const [current, setCurrent] = useState(null);
  const [removeId, setRemoveId] = useState(null);
  const [percentUpload, setPercentUpload] = useState(0);
  const [dataUpload, setDataUpload] = useState("0 / 0");
  const [step, setStep] = useState(0);
  const [video, setVideo] = useState(null);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [cover, setCover] = useState(null);
  const [imageBackground, setImageBackground] = useState(null);
  const [cat, setCat] = useState(null);
  const [device, setDevice] = useState(null);

  useEffect(() => {
    getData(true);

    eventListener(mediaManager.evFileUpload, async (data) => {
      if ((await mediaManager.getParseFileCurret()) === data.id) {
        setPercentUpload(data.progress);
        setDataUpload(data.data);
      }
    });
  }, []);

  const getData = (loader = false) => {
    setLoad(loader);
    mediaManager
      .getVideos()
      .then((res) => {
        setLoad(false);
        props.setVideos(res);
      })
      .catch((err) => console.log(err));
  };

  const getThumnail = (id) => `https://vumbnail.com/${id}.jpg`;

  const notify = (message) => {
    props.notify({ type: "error", title: t("videos"), text: message });
    return false;
  };

  const subir = () => {
    if (name == null || name.length === 0 || name === "") return notify(t("notify.name"));
    if (cat == null) return notify(t("notify.category"));
    if (device == null) return notify(t("notify.device"));
    if (video == null) return notify(t("notify.video"));

    console.log("Validaction");

    getTime(video).then(async (duration) => {
      setStep(2);
      console.log("get_time");
      mediaManager.setCurrentFile(video);
      mediaManager
        .uploadVideo({
          media: video,
          name: name,
          desc: desc,
          cover: await getCoverFromBlob(cover),
          cat: cat,
          device: device,
          duration: duration,
        })
        .then((res) => {
          console.log("upload");
          getData();
          setStep(3);
          clear();
        })
        .catch((err) => console.log(err));
    });
  };

  const clear = () => {
    setVideo(null);
    setName("");
    setDesc("");
    setCover(null);
    setCat(null);
    setDevice(null);
    setPercentUpload(0);
    setAddFile(false);
    setStep(0);
  };

  const getCoverFromBlob = (cover) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(cover);
      reader.onloadend = () => resolve(reader.result);
    });
  };

  const uploadVideo = () => {
    return (
      <div className="browse-files text-center my-2">
        <label htmlFor="fileupload" className="custom-file-upload">
          <img src={ImgUpload} className="img-fluid" alt="Subir"></img>
        </label>
        <p className="fw-600 color-sub-text py-3">
          {t("Search_here_for_your_video_from_your_computer")}
          <br></br>
          {t("to_start_uploading_it_to_the_library")}
        </p>
        <label htmlFor="fileupload" className="btn-purple-tf mx-auto">
          {t("search_your_video")}
        </label>
      </div>
    );
  };

  const listVideo = () => {
    return (
      <div className="browse-files text-center my-2">
        <img src={imageBackground} className="img-fluid" alt="Subir"></img>
        <p className="fw-600 color-sub-text py-3">{video && video.name}</p>
        <label htmlFor="fileupload" className="btn-purple-tf mx-auto">
          {t("search_other_video")}
        </label>
      </div>
    );
  };

  const uploadingVideo = () => {
    return (
      <div className="browse-files text-center my-2">
        <img src={ImgUpload} className="img-fluid" alt="Subir"></img>
        <p className="fw-600 color-sub-text py-3">{t("uploading_your_video")}</p>
        <ProgressBar value={percentUpload} mb={dataUpload} />
      </div>
    );
  };

  const successUpload = () => {
    return (
      <div className="browse-files text-center my-2">
        <img src={ImgUpload} className="img-fluid" alt="Up"></img>
        <p className="fw-600 color-sub-text py-3">
          {t("your_video_has_been_uploaded_successfully")}
        </p>
        <label
          htmlFor="fileupload"
          className="btn-purple-tf mx-auto"
          onClick={() => {
            setVideo(null);
            setCurrent(null);
            setStep(0);
            clear();
          }}
        >
          {t("uploading_other_video")}
        </label>
      </div>
    );
  };

  const remove = () => {
    if (removeId != null) {
      mediaManager
        .deleteMedia(removeId._id)
        .then((res) => {
          getData();
          setOpenRemove(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const getVideoCover = (file, seekTo = 0.0) => {
    return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => reject("error when loading video file", ex));
      videoPlayer.addEventListener("loadedmetadata", () => {
        if (videoPlayer.duration < seekTo) return reject("video is too short.");
        setTimeout(() => (videoPlayer.currentTime = seekTo), 200);
        videoPlayer.addEventListener("seeked", () => {
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          ctx.canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.75);
        });
      });
    });
  };

  const getTime = (file) => {
    return new Promise((resolve, reject) => {
      let video = document.createElement("video");
      video.src = window.URL.createObjectURL(file);
      video.oncanplay = function () {
        let duration = video.duration;
        let hour = parseInt(duration / 3600);
        let h = hour < 10 ? "0" + hour : hour;
        let minute = parseInt((duration % 3600) / 60);
        let m = minute < 10 ? "0" + minute : minute;
        let sec = parseInt(duration % 60);
        let s = sec < 10 ? "0" + sec : sec;
        resolve(h + ":" + m + ":" + s);
      };
    });
  };

  const getCoverFile = async (file) => {
    try {
      const cover = await getVideoCover(file, 1.5);
      setCover(cover);
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(cover);
      setImageBackground(imageUrl);
    } catch (ex) {
      console.log("ERROR: ", ex);
    }
  };

  const categoriesOptions = props.store.categories_media.map((category) => {
    return { value: category._id, label: category.name };
  });

  const devicesOptions = props.store.devices_media.map((device) => {
    return { value: device._id, label: device.name };
  });

  return (
    <LoaderContainer active={load}>
      <>
        <CardsContainer>
          {props.store.videos
            .filter(
              (f) =>
                f.cat._id.includes(props.sidebarFilterState.category) ||
                props.sidebarFilterState.category === ""
            )
            .filter(
              (f) =>
                f.device._id.includes(props.sidebarFilterState.device) ||
                props.sidebarFilterState.device === ""
            )
            .filter(
              (f) =>
                f.name
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    props.filter
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .toLowerCase()
                  ) || props.filter === ""
            )
            .map((e, i) => {
              let src = e.cover ? e.cover : getThumnail(e.src);
              return (
                <Card
                  key={i}
                  img={src}
                  onClick={() => {
                    setOpen(true);
                    setCurrent(e);
                  }}
                  filename={e.name}
                  onClickDelete={() => {
                    setOpenRemove(true);
                    setRemoveId(e);
                  }}
                />
              );
            })}
        </CardsContainer>

        <ButtonAdd action={() => setAddFile(true)} />

        {current && (
          <Modal
            title={current.name}
            width="fit-content"
            isOpen={openVideo}
            handleClose={() => setOpen(false)}
            buttons={<Button label={t("close")} width="142px" onClick={() => setOpen(false)} />}
          >
            <VideoPlayer provider={current.provider} src={current.src} cover={current.cover} />

            <ModalDescription>{current.desc}</ModalDescription>
          </Modal>
        )}

        {removeId && (
          <Modal
            title={`${t("sure_you_want_to_delete")} ${removeId.name}?`}
            isOpen={openRemove}
            handleClose={() => setOpenRemove(false)}
            buttons={
              <>
                <Button
                  type="ghost"
                  label={t("cancel")}
                  onClick={() => setOpenRemove(false)}
                  width="142px"
                />
                <Button
                  label={t("delete")}
                  width="142px"
                  onClick={() => {
                    remove();
                    setOpenRemove(false);
                  }}
                />
              </>
            }
          ></Modal>
        )}

        <Modal
          title={t("upload_videos")}
          isOpen={addFile}
          handleClose={() => clear()}
          buttons={
            <>
              <Button
                type="ghost"
                label={t("cancel")}
                onClick={() => clear()}
                width="142px"
              />
              <Button label={t("upload")} width="142px" onClick={() => subir()} />
            </>
          }
        >
          <VideoModalContainer>
            <Input
              placeholder={t("video_name")}
              value={name}
              onChange={(v) => setName(v.target.value)}
            />
            <div className="_dropdowns">
              <Dropdown
                key={`category-${cat}`}
                placeholder={t("category")}
                options={categoriesOptions}
                value={categoriesOptions.filter((e) => e.value === cat)[0]}
                onChange={(c) => setCat(c.value)}
                type="line"
                portal
              />
              <Dropdown
                key={`device-${device}`}
                placeholder={t("devices")}
                options={devicesOptions}
                value={devicesOptions.filter((e) => e.value === device)[0]}
                onChange={(c) => setDevice(c.value)}
                type="line"
                portal
              />
            </div>

            <div className="_description">
              <Label>{t("desc")}</Label>
              <TextArea value={desc} onChange={(v) => setDesc(v.target.value)}></TextArea>
            </div>

            {step === 0
              ? uploadVideo()
              : step === 1
              ? listVideo()
              : step === 2
              ? uploadingVideo()
              : step === 3
              ? successUpload()
              : null}
          </VideoModalContainer>
        </Modal>
      </>
      <input
        accept="video/*"
        type="file"
        id="fileupload"
        style={{ display: "none" }}
        onChange={(file) => {
          if (!file.target.files[0].type.includes("video")) {
            return notify(t("notify.video_type"));
          }
          file.persist();
          setVideo(file.target.files[0]);
          getCoverFile(file.target.files[0]);
          setStep(1);
          file.target.value = null;
        }}
      />
    </LoaderContainer>
  );
};

export default connect(Video);
