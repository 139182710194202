/* eslint-disable array-callback-return */
import React, { useState } from "react";

import { getUserId } from "../../lib/router";
import { menuList } from "./data/menuList";
import { LogoStyled, NavStyled, ScrollBox, UlStyled } from "./styles";

//IMG
import IcoIsotipo from "../../assets/img/iconMenu/icon-isotipo.svg";
import "../../assets/img/icomoon/style.css";
import { MenuItem } from "./components/MenuItem";

const Sidebar = (props) => {
  const [onMouseLeave, setOnMouseLeave] = useState(true);

  const handleToggleMouseLeave = () => setOnMouseLeave((prev) => !prev);

  const { menu_availables } = menuList();

  return (
    <NavStyled onMouseLeave={handleToggleMouseLeave}>
      <LogoStyled>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src={IcoIsotipo} alt="Isotipo" />
        </div>
        <div className="logo--text_version">v 2.0.2</div>
      </LogoStyled>
      <ScrollBox>
        <UlStyled>
          {menu_availables.map((e) => {
            if (e.roles.includes(getUserId().rol)) {
              return <MenuItem key={e.path || e.icon} item={e} onMouseLeave={onMouseLeave} />;
            }
          })}
        </UlStyled>
      </ScrollBox>
    </NavStyled>
  );
};

export default Sidebar;
