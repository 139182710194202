/* eslint-disable array-callback-return */
import API from '../../services/api';
import _ from 'lodash';
const api = new API();

class FlowController {
    getDataPatients() {
        return new Promise((resolve, reject) => {
            api.getFollowPatient()
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }

    getData(page, limit, data = {}) {
        return new Promise((resolve, reject) => {
            const cacheKey = JSON.stringify({ page, limit, ...data });
            const cachedResponse = localStorage.getItem(cacheKey);
    
            const handleFreshData = (res) => {
                localStorage.setItem(cacheKey, JSON.stringify(res));
    
                if (cachedResponse && !_.isEqual(res, JSON.parse(cachedResponse))) {
                    console.log('New or updated data is available.');
                    resolve(res);  // Si hay datos frescos diferentes, resuelve con esos datos.
                } else if (!cachedResponse) {
                    console.log('No cached response available.');
                    resolve(res);  // Si no hay datos en caché, resuelve con los datos frescos.
                } 
            };
    
            if (cachedResponse) {
                resolve(JSON.parse(cachedResponse));
                api.getFollowPatientDatePaginated(page, limit, data)
                    .then(handleFreshData)
                    .catch(err => reject(err));
            } else {
                api.getFollowPatientDatePaginated(page, limit, data)
                    .then(handleFreshData)
                    .catch(err => reject(err));
            }
        });
    }
    
    sortBy(data = [], key = '', type = true, set) {
        return new Promise((resolve, reject) => {
            var arr = Object.assign([], data);
            const array = arr.sort((a, b) => {
                if (key === "name") return this.sortByName(a, b, type);
                else if (key === "percent") return this.sortByPercent(a, b, type);
                else if (key === "session") return this.sortBySessions(a, b, type);
                else if (key === "evaluations") return this.sortByEvaluations(a, b, type);
                else if (key === "nextSession") return this.sortByNextSessions(a, b, type);
            });

            resolve(array);
        });
    }

    sortByName(a, b, type) {
        if (type) {
            if (a["name"] < b["name"]) return 1;
            if (a["name"] > b["name"]) return -1;
            return 0;
        } else {
            if (a["name"] > b["name"]) return 1;
            if (a["name"] < b["name"]) return -1;
            return 0;
        }
    }

    sortByPlans(a, b, type) {
        if (type) {
            if (a["all_planning"] < b["all_planning"]) return 1;
            if (a["all_planning"] > b["all_planning"]) return -1;
            return 0;
        } else {
            if (a["all_planning"] > b["all_planning"]) return 1;
            if (a["all_planning"] < b["all_planning"]) return -1;
            return 0;
        }
    }


    sortByPercent(a, b, type) {
        if (type) {
            if (a['percent_success'] < b['percent_success']) return 1;
            if (a['percent_success'] > b['percent_success']) return -1;
            return 0;
        } else {
            if (a['percent_success'] > b['percent_success']) return 1;
            if (a['percent_success'] < b['percent_success']) return -1;
            return 0;
        }
    }

    sortBySessions(a, b, type) {
        if (type) {
            if (a['arrowUp'] < b['arrowUp']) return 1;
            if (a['arrowUp'] > b['arrowUp']) return -1;
            return 0;
        } else {
            if (a['arrowUp'] > b['arrowUp']) return 1;
            if (a['arrowUp'] < b['arrowUp']) return -1;
            return 0;
        }
    }

    sortByEvaluations(a, b, type) {
        if (type) {
            if (a['starts'] < b['starts']) return 1;
            if (a['starts'] > b['starts']) return -1;
            return 0;
        } else {
            if (a['starts'] > b['starts']) return 1;
            if (a['starts'] < b['starts']) return -1;
            return 0;
        }
    }

    sortByNextSessions(a, b, type) {
        if (type) {
            if (a["all_planning"]['min'] < b["all_planning"]['min']) return 1;
            if (a["all_planning"]['min'] > b["all_planning"]['min']) return -1;
            return 0;
        } else {
            if (a["all_planning"]['min'] > b["all_planning"]['min']) return 1;
            if (a["all_planning"]['min'] < b["all_planning"]['min']) return -1;
            return 0;
        }
    }

    collapse(selector, expanded) {
        selector.classList.toggle('collapsed');
        selector.classList.toggle('show');
        expanded.setAttribute("aria-expanded", expanded.getAttribute('aria-expanded') === 'false' ? true : false);
    }

    onClick(id) {
        let element = document.getElementById(id);
        element.classList.toggle('active');
        this.collapse(element.querySelector('#collapse_' + id), element.querySelector('[data-toggle]'));
    }

    filterPatients(pageNum, limit, data = {}) {
        return new Promise((resolve, reject) => {
            api.getFollowPatientDatePaginated(pageNum, limit, data)
                .then(res => resolve(res))
                .catch(err => reject(err))
        })
    }

    getFollowChartPatient(id, data = {}) {
        return new Promise((resolve, reject) => {
            api.getFollowChartPatient(id, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }

    scheduleEventsByPatient(id, data) {
        return new Promise((resolve, reject) => {
            api.scheduleEventsByPatientPost(id, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        })
    }

    getEventsPlanningByUser(id, data) {
        return new Promise((resolve, reject) => {
            api.getEventsPlanningByUser(id, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err));
        })
    }

    finallyPlanning(id) {
        return new Promise((resolve, reject) => {
            api.terminatePlanning(id)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        })
    };

    finallyProgram(id) {
        return new Promise((resolve, reject) => {
            api.terminateProgram(id)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        })
    };

    getChartsEvals(id) {
        return new Promise((resolve, reject) => {
            api.getFollowCharteval(id)
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
        })
    }

    checkValueEvent(checked, id, events, allEvents) {
        return new Promise((resolve, reject) => {
            const event = Object.assign([], events);
            if (checked) {
                const newEvenst = allEvents.filter(e => e.planningId === id)
                resolve([...event, ...newEvenst]);
            } else {
                const newEvenst = event.filter(e => e.planningId !== id)
                resolve(newEvenst);
            }
        })
    };

    checkValueSchedule(checked, id, schedules, eventsSchedules) {
        return new Promise((resolve, reject) => {
            let schedule = Object.assign([], eventsSchedules);
            if (checked) {
                const newData = schedules.filter(e => e.data.programId === id);
                resolve([...schedule, ...newData]);
            } else {
                const newData = schedule.filter(e => e.data.programId !== id);
                resolve(newData);
            }
        })
    };

    getEventsAndPlannings(id) {
        return new Promise((resolve, reject) => {
            api.getEventsAndPlanningsByUser(id)
                .then((res) => {
                    const { events, planning } = res.data;
                    resolve({ events: events, plannings: planning })
                })
                .catch((err) => reject(err));
        })
    }

    getPlansAndPrograms(id, data) {
        return new Promise((resolve, reject) => {
            api.getPlansAndPrograms(id, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }

    setDateStorage(data) {
        if (window) {
            window.sessionStorage.setItem("tfdate-follow", JSON.stringify(data));
        }
    }

    getDateStorage() {
        if (window) {
            const storage = window.sessionStorage.getItem("tfdate-follow");
            if (storage) return JSON.parse(storage);
            else return { from: null, to: null };
        } else return { from: null, to: null };
    }

    changeStatusEvent(id, status) {
        return new Promise((resolve, reject) => {
            api.changeStatusEvent(id, { status })
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }

    changeSessionPlanning(id, status, session) {
        return new Promise((resolve, reject) => {
            api.changeSessionPlanning(id, { status, session })
                .then(res => resolve(res.data))
                .catch(err => reject(err))
        })
    }
}


export default new FlowController();