import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Expand from "../../../../components/expand";
import {
  ArrowPatientCardIcon,
  ToolsPatientCardIcon,
} from "../../../ClinicalRecordsPage/components/PatientCard/icons";
import * as S from "./protocolo.styles";

import { Row, Col, Container, MenuDropdown } from "trainfes-components-library";

export const Card = ({
  e,
  currentId,
  handleToggle,
  handleMenuEdit,
  handleMenuClone,
  handleMenuDelete,
  active,
}) => {
  const { t } = useTranslation("protocols");

  const [showBody, setShowBody] = useState(false);
  const handleToggleBody = () => setShowBody((prev) => !prev);

  const handleClic = (e) => {
    if (currentId !== e._id) handleToggle(e);
    handleToggleBody();
  };

  useEffect(() => {
    if (currentId !== e._id) {
      setShowBody(false);
    } else {
      setShowBody(true);
    }
  }, [currentId, active]);

  const getTags = (str) => {
    let tags = str.trim().split(",");
    return tags.join(" - ");
  };

  return (
      <S.CardContainer active={currentId === e._id} id={currentId}>
        <S.CardHeader onClick={() => handleClic(e)}>
          <div className="header--info">
            <p className="_title">{e.name}</p>
            <p className="_sub-title">{e.fase}</p>
          </div>

          <div className={`_icon ${showBody ? "_active" : ""}`}>
            <ArrowPatientCardIcon />
          </div>
        </S.CardHeader>

        {showBody && (
          <S.CardBody>
            <div>
              <S.DescriptionTitle>{t("desc")}</S.DescriptionTitle>
              <S.Hr />
            </div>
            <Container>
              <Row cols={12}>
                <Col xs={11} className="padding-0">
                  <S.Description>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: e.description,
                      }}
                    ></span>
                  </S.Description>
                </Col>

                <Col xs={1}>
                  <MenuDropdown
                    icon={
                      <div className="_icon">
                        <ToolsPatientCardIcon />
                      </div>
                    }
                    items={[
                      {
                        anchor: t("formProtocol.edit"),
                        onClick: () => handleMenuEdit(),
                      },
                      {
                        anchor: t("formProtocol.clone"),
                        onClick: () => handleMenuClone(),
                      },
                      {
                        anchor: t("formProtocol.delete"),
                        onClick: () => handleMenuDelete(),
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Container>
            <div>
              <Expand titulo={t("og")}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: e.general_objetive,
                  }}
                ></span>
              </Expand>

              <Expand titulo={t("oe")}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: e.specific_objetive,
                  }}
                ></span>
              </Expand>

              <Expand titulo={t("np")}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: e.public_note,
                  }}
                ></span>
              </Expand>

              <Expand titulo={t("diagnosis")}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: getTags(e.private_note),
                  }}
                ></span>
              </Expand>
            </div>
          </S.CardBody>
        )}
      </S.CardContainer>
  );
};
