import React from "react";
import Select from "react-select";
import "./style.css";

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "white",
    "&:hover": { borderColor: "#281B65" },
    borderColor: isFocused ? "#281B65" : "rgba(0, 0, 0, 0.23)",
    boxShadow: "none",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? "#ccc"
        : isFocused
        ? "#4632A420"
        : isSelected
        ? "#281B65"
        : "#FAFAFA",
      color: isFocused ? "#3A3A3A" : isSelected ? "#FFF" : "#3A3A3A",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      padding: "2px 10px",
      backgroundColor: "#E5E7FA",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#6979F8",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: "transparent",
      color: "#281B65",
      cursor: "pointer",
    },
  }),
  menuPortal: (styles, { data }) => ({
    ...styles,
    zIndex: 9999999999999,
  }),
};

const DropdownSelect = (props) => {
  const onChange = (id) => {
    if (props.onChange !== undefined) props.onChange(id);
  };

  return (
    <div className="dropdown-select-tf">
      <Select
        isMulti={props.isMulti}
        className={props.className}
        styles={colourStyles}
        options={props.options}
        placeholder={props.placeholder}
        value={props.value}
        onChange={onChange}
        maxMenuHeight={props.maxMenuHeight}
        menuPlacement={props.menuPlacement}
        menuPortalTarget={props.menuPortalTarget}
        isDisabled={props.disabled}
      />
    </div>
  );
};

DropdownSelect.defaultProps = {
  options: [],
  placeholder: "",
  value: { value: "", label: "" },
  maxMenuHeight: 300,
  menuPlacement: "auto",
  menuPortalTarget: document.getElementById("root"),
  className: "",
  isMulti: false,
  disabled: false,
};

export default DropdownSelect;

export const DropdownMultiSelect = (props) => {
  const onChange = (id) => {
    if (props.onChange !== undefined) props.onChange(id);
  };

  return (
    <div className="dropdown-select-tf">
      <Select
        isMulti
        className={props.className}
        styles={colourStyles}
        options={props.options}
        placeholder={props.placeholder}
        value={props.value}
        onChange={onChange}
        maxMenuHeight={props.maxMenuHeight}
        menuPlacement={props.menuPlacement}
        menuPortalTarget={props.menuPortalTarget}
        isDisabled={props.disabled}
      />
    </div>
  );
};

DropdownMultiSelect.defaultProps = {
  options: [],
  placeholder: "",
  value: { value: "", label: "" },
  maxMenuHeight: 300,
  menuPlacement: "auto",
  menuPortalTarget: document.getElementById("root"),
  className: "",
  disabled: false,
};
