import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Container,
    Row,
    Col,
    Dropdown,
    SearchBar,
    TableServices,
    ButtonCircle,
    Button,
    Modal
} from "trainfes-components-library"

import { connect } from '../../store'

import { CLEAR_STATE, EDITING_STATE, initialState, reducer, SET_FIELDS, SET_OPEN_MODAL } from './reducer';

import { useGetSpecialities } from '../../hooks';

import CardLayoutPlatform from '../../components/CardLayoutPlatform';
import { ModalNewSpeciality } from './components/ModalNewSpeciality';


import * as S from "./specialties.styles"
import { TableHeader } from '../ServicesMaintainerPage/specificServices.styles';

import { formValidation } from './specialties.utils';
import { filterValidation } from '../../lib/textValidation';
import { Delete, Edit } from '../../assets/icons/pages';

const Specialties = (props) => {
    const { t } = useTranslation('maintainers');

    const [state, dispatch] = useReducer(reducer, initialState);

    const { specialitiesQuery, addNewSpeciality, editSpeciality, deleteSpeciality } = useGetSpecialities()

    if (specialitiesQuery.error) return "An error has occurred: " + specialitiesQuery.error.message;

    const handleModals = ( modal, value ) => dispatch({ type: SET_OPEN_MODAL, payload: { modal, value }})


    const onCreateSubsidiary = () => {
        if ( state.isPosting ) return;
        dispatch({ type: SET_FIELDS, payload: { field: "isPosting", value: true } })

        if ( !formValidation( state, t, props.notify ) ) {
            return dispatch({ type: SET_FIELDS, payload: { field: "isPosting", value: false } })
        }

        const dataToPost = {
            name:       state.name,
            desc:       state.description,
            color:      state.color,
            isMedic:    state.isMedic
        }

        if ( state.isEditing ) return onEdit( state.idCurrent, dataToPost )

        try {
            addNewSpeciality( dataToPost )
            
            dispatch({ type: CLEAR_STATE  })
            
            return props.notify({ type: 'success', title: t('specialties.specialty'), text: t('notify.success') })
        } catch (error) {
            props.notify({ type: 'error', title: t('specialties.specialty'), text: t('notify.error') })
        }
    }

    const onEdit = ( id, data ) => {
        if ( state.isPosting ) return;
        dispatch({ type: SET_FIELDS, payload: { field: "isPosting", value: true } })

        try {
            editSpeciality( { id, data } )
            
            dispatch({ type: CLEAR_STATE  })
      
            return props.notify({ type: 'success', title: t('specialties.specialty'), text: t('notify.success') })
          } catch (error) {
            return props.notify({ type: 'error', title: t('specialties.specialty'), text: t('notify.error') })
          }
    }

    const onDeleteSpeciality = () => {
        if ( state.isPosting ) return;
        dispatch({ type: SET_FIELDS, payload: { field: "isPosting", value: true } })

        try {
            deleteSpeciality( state.idCurrent )
      
            dispatch({ type: CLEAR_STATE })
      
            return props.notify({ type: 'success', title: t('specialties.specialty'), text: t('notify.success') })
          } catch (error) {
            return props.notify({ type: 'error', title: t('specialties.specialty'), text: t('notify.error') })
          }
    }

    const handleOpenDelete = ( id ) => {
        dispatch({ type: SET_FIELDS, payload: { field: "idCurrent", value: id } })
        dispatch({ type: SET_FIELDS, payload: { field: "removeModal", value: true } })
    }

    const columns = [
        {
            key: "color", 
            text: "Color", 
            type: "custom", 
            alignContent: "center", 
            alignTitle: "center",
            elem: ({ color }) => ( <S.ColorDiv bg={ color } /> ),
        },
        {   
            key: "name",        
            text: t('specialties.specialty'), 
            alignContent: "center", 
            alignTitle: "center" },
        {   
            key: "desc",        
            text: t('maintainer.desc'),        
            alignContent: "center", 
            alignTitle: "center" },
        {   
            key: "isMedic",     
            text: t('specialties.referrer_interconsultation'),  
            type: "custom", 
            alignContent: "center", 
            alignTitle: "center", 
            elem: ({ isMedic }) => (  <span>{ isMedic ? t("setting.yes") : "No" }</span> ),
        },
        {
            key: "controls", text: t("maintainer.actions"), 
            type: "custom", 
            alignContent: "center", 
            alignTitle: "center",
            elem: (payload) => (
                <S.IconsContainer>
                    <Edit     onClick={ () => dispatch({ type: EDITING_STATE, payload }) } />
                    <Delete   onClick={ () => handleOpenDelete( payload._id ) } />
                </S.IconsContainer>
            ),
        },
    ];

    const filterOptions = [
        { 
            label:  t('specialties.specialty'),    
            value:  "name",      
            key:    "name" 
        },
        { 
            label:  t('maintainer.desc'),          
            value:  "desc", 
            key:    "encargado"  
        },
        // { 
        //     label:  t('specialties.referrer_interconsultation'),   
        //     value:  "isMedic",   
        //     key:    "isMedic"   
        // },
    ];

    useEffect(() => {
        handleFilter();
    }, [ state.inputFilter, specialitiesQuery.data ]);

    const handleFilter = () => {
        let data = Object.assign( [], specialitiesQuery.data );
    
        if (state.inputFilter === "") {
          return dispatch({
            type: SET_FIELDS,
            payload: {
              field: "filteredSpecialities",
              value: data.reverse(),
            },
          });
        }
        
        return dispatch({
                type: SET_FIELDS,
                payload: {
                  field: "filteredSpecialities",
                  value: data.reverse().filter((e) => {
                    return filterValidation( e[ state.dropdownFilter ] ).includes( filterValidation( state.inputFilter ) )
                  } ),
                },
              })
    }


    return (
        <>
        <Container>
            <Row cols={12}>
                <Col xs={12}>
                    <CardLayoutPlatform
                        noPaddingBottom
                        title={ t("specialties.title") }
                        loading={ specialitiesQuery.isLoading }
                        floatButton={
                            <ButtonCircle onClick={ () => handleModals( "newSpecialityModal", true ) } />
                        }
                    >
                        <TableHeader>
                            <p>{t("maintainer.search_by")}</p>
                            <Dropdown
                                height={38}
                                options={filterOptions}
                                value={ filterOptions.filter( (e) => e.value === state.dropdownFilter)[0] }
                                onChange={(e) =>
                                    dispatch({ type: SET_FIELDS, payload: { field: "dropdownFilter", value: e.value } })
                                }
                            />
                            <SearchBar
                                borderRadius="6px"
                                height="38px"
                                placeholder={t("maintainer.search")}
                                onChange={(e) =>
                                    dispatch({ type: SET_FIELDS, payload: { field: "inputFilter", value: e.target.value } })
                                }
                            />
                        </TableHeader>

                        <TableServices
                            data={ state.filteredSpecialities }
                            columns={ columns }
                            searchKeys="title mode"
                            autoHeight={false}
                            perPage={10}
                            pagination={true}
                            filterSelect={false}
                            filterInput={false}
                            perPageSelect={false}
                            textEmptyData="Sin datos para mostrar"
                        />
                    </CardLayoutPlatform>
                </Col>
            </Row>
        </Container>

        <Modal
            title={
                state.isEditing
                ? t("specialties.edit_specialty")
                : t("specialties.new_specialty")
            }
            isOpen={state.newSpecialityModal}
            buttons={
                <>
                    <Button
                        type="ghost"
                        label={t("maintainer.cancel")}
                        onClick={ () => dispatch({ type: CLEAR_STATE }) }
                    />
                    <Button
                        label={t("maintainer.save")}
                        onClick={ () => onCreateSubsidiary() }
                        disabled={ state.isPosting }
                    />
                </>
            }
        >
            <ModalNewSpeciality t={t} state={state} dispatch={dispatch} />
        </Modal>

        <Modal
            title={ t("maintainer.really_want_to_delete") + "?" }
            isOpen={ state.removeModal }
            buttons={
                <>
                    <Button
                        type="ghost"
                        label={t("maintainer.cancel")}
                        onClick={ () => dispatch({ type: CLEAR_STATE }) }
                    />
                    <Button
                        label={t("maintainer.remove")}
                        onClick={ () => onDeleteSpeciality() }
                        disabled={ state.isPosting }
                    />
                </>
            }
        >
        </Modal>
        </>
    )

}

export default connect(Specialties);