import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Modal, Button } from "trainfes-components-library";
import { HideImageIcon, ShowImageIcon } from "../../../../assets/icons";
import ModalMuscleSelector from "../ModalMuscleSelector";
import * as S from "./MuscleSelector.styles";

export const MuscleSelector = ({
  data,
  musclesSelected,
  setMusclesSelected,
  index,
  configChannels,
  setConfigChannels,
  channels,
  setChannels,
  activeTab,
  noModify,
}) => {
  const { t } = useTranslation(["sessionStudio", "create_patient", "modes"]);
  const { ch: channel, img } = data;

  const [showModal, setShowModal] = useState(false);
  const odd = channel % 2 !== 0;

  const [newData, setNewData] = useState([...musclesSelected]);

  useEffect(() => {
    let channelItems = [...channels];
    let copyNewData = [...musclesSelected];

    if (configChannels) {
      configChannels.forEach((item) => {
        const muscleSelectedIndex = copyNewData.findIndex(
          (muscleItem) => item.ch === muscleItem.ch
        );

        if (!copyNewData[muscleSelectedIndex].img) {
          copyNewData[muscleSelectedIndex] = {
            ...copyNewData[muscleSelectedIndex],
            img: { name: item.name },
          };
        }

        channelItems[muscleSelectedIndex] = {
          ...channelItems[muscleSelectedIndex],
          keyId: item.name,
          name: item.name,
          index: 0,
        };
      });

      setMusclesSelected(copyNewData);
      setNewData(copyNewData);
      setChannels(channelItems);
    }
  }, [configChannels]);

  // * -> Métodos
  const handleChangeImage = (imgSelected) => {
    let items = [...musclesSelected];
    let item = { ...items[index], img: imgSelected, showImage: Boolean(imgSelected) };

    items[index] = item;

    setNewData(items);
  };

  const handleChangeVisibility = (bool) => {
    let items = [...musclesSelected];
    let item = { ...items[index], showImage: bool };

    items[index] = item;

    setNewData(items);
    setMusclesSelected(items);
  };

  const handleSubmit = () => {
    setShowModal(false);

    let channelItems = [...channels];

    let channelItem = {
      ...channelItems[index],
      keyId: newData[index].img ? newData[index].img.name : "",
      name: newData[index].img ? newData[index].img.name : "",
      index: newData[index].img ? 0 : 100,
    };

    channelItems[index] = channelItem;

    setChannels(channelItems);

    if (configChannels && configChannels.length > 0) {
      if (configChannels.some((e) => e.ch === index + 1) && !newData[index].img) {
        setConfigChannels(configChannels.filter((e) => e.ch !== index + 1));
      } else {
        if (newData[index].img) {
          setConfigChannels([...configChannels.filter((e) => e.ch !== index + 1), channelItem]);
        } else {
          setConfigChannels([...configChannels.filter((e) => e.ch !== index + 1)]);
        }
      }
    } else {
      if (newData[index].img) setConfigChannels([channelItem]);
    }

    setMusclesSelected(newData);
  };

  return (
    <div>
      <S.Block active={data.img} nomodify={noModify}>
        <S.Channel blue={odd} onClick={() => setShowModal(true)} active={data.img}>
          {channel}
        </S.Channel>
        {data.img && (
          <>
            <S.Name>{data.img.name}</S.Name>
            {data.img.url && !noModify ? (
              <div className="MuscleSelector--icons">
                <ShowImageIcon
                  onClick={() => handleChangeVisibility(true)}
                  className={`${data.showImage ? "icon_active" : null}`}
                />
                <HideImageIcon
                  onClick={() => handleChangeVisibility(false)}
                  className={`${!data.showImage ? "icon_active" : null}`}
                />
              </div>
            ) : null}
          </>
        )}
      </S.Block>

      <Modal
        title={t("search_your_muscles")}
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        buttons={
          <>
            <Button type="ghost" label={t("cancel")} onClick={() => setShowModal(false)} />
            <Button label={t("save")} onClick={handleSubmit} />
          </>
        }
      >
        <ModalMuscleSelector
          channel={channel}
          img={img}
          musclesSelected={musclesSelected}
          setMusclesSelected={setMusclesSelected}
          handleChangeImage={handleChangeImage}
          configChannels={configChannels}
        />
      </Modal>
    </div>
  );
};
