import { Document } from "react-pdf";
import styled from "styled-components";

export const CardsContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 20px;
  place-items: center;
`;

export const HeaderElectrodes = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const VideoModalContainer = styled.div`
  & > * {
    margin: 7px 0;
  }

  & > ._dropdowns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
`;

export const Label = styled.label`
  color: #281b65;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 4px 0;
`;

export const TextArea = styled.textarea`
  border-radius: 4px;
  border: 1px solid #9aa2a8;
  outline: none;
  padding: 10px;
  resize: none;
  width: 100%;

  &:focus {
    border: 1px solid #5943c1;
  }
`;

export const ImgStyled = styled.img`
  width: 100%;
  max-height: 600px;
`;

export const ModalDescription = styled.p`
  margin: 15px 0;
`;

export const DocumentStyled = styled(Document)`
  /* height: 150px; */
  max-height: 600px;
  object-fit: contain;
  overflow-x: hidden;
`;

export const HeaderStyled = styled.div`
  margin-top: 14px;
  padding: 0 14px 0 14px;
  display: flex;
  align-items: center;
  width: 100%;

  & > div:first-child {
    width: 100%;
  }

  & button {
    margin-left: 10px;
  }
`;