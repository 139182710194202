import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "../../../store";

import Counter from "../../counter";
import TimePicker from "../../timePicker";
import { MuscleSelector } from "../components/MuscleSelector";

import {
  Container,
  Row,
  Col,
  InputBorder,
  Textarea,
  Slider,
  Button,
  Modal,
} from "trainfes-components-library";

import * as S from "../styles/Modos.styles";
import * as C from "../styles/configbutton.styles";

import { ConfigIcon } from "../../../assets/icons";

import { toInt } from "../../../lib/modes.helpers";

// eslint-disable-next-line no-unused-vars
var ancho_pulso, self;
class Graphic extends React.Component {
  constructor() {
    super();
    this.state = {
      channels: [
        { ch: 1, name: "", index: 100, keyId: "" },
        { ch: 2, name: "", index: 100, keyId: "" },
        { ch: 3, name: "", index: 100, keyId: "" },
        { ch: 4, name: "", index: 100, keyId: "" },
        { ch: 5, name: "", index: 100, keyId: "" },
        { ch: 6, name: "", index: 100, keyId: "" },
      ],
      ch1: [],
      ch2: [],
      ch3: [],
      ch4: [],
      ch5: [],
      ch6: [],
      matrix: [],
      total: 0,
      selectedId: "",
      channelSelected: "",
      rightRamp: 0,
      leftRamp: 0,
      duration: 0,
      position: 0,
      wd: 0,
      // repeticiones: 1,
      series: 1,
      pausa_series: { hours: 0, minutes: 0, seconds: 0 },
      contraccion: 0,
      pausa: 0,
      num_contraccion: 1,
      pausa_contraccion: { hours: 0, minutes: 0, seconds: 0 },
      time: "00:00:00",

      frecuenciaTemp: 35,
      ancho_pulsoTemp: 200,
      frecuencia: 35,
      ancho_pulso: 200,
      public_name: "",
      technical_name: "",
      description: "",
      musclesSelected: [
        {
          ch: 1,
          img: null,
          showImage: false,
        },
        {
          ch: 2,
          img: null,
          showImage: false,
        },
        {
          ch: 3,
          img: null,
          showImage: false,
        },
        {
          ch: 4,
          img: null,
          showImage: false,
        },
        {
          ch: 5,
          img: null,
          showImage: false,
        },
        {
          ch: 6,
          img: null,
          showImage: false,
        },
      ],
      showConfigModal: false,
    };
    this.grid = React.createRef();
  }

  componentDidMount() {
    self = this;

    this.calc();
    window.addEventListener("resize", this.calc, false);

    const getCh = (data, ch) => {
      return data.map((e) => {
        e["ch"] = ch;
        return e;
      });
    };

    if (this.props.data.params && Object.keys(this.props.data.params).length !== 0) {
      const {
        config_channels,
        ch1,
        ch2,
        ch3,
        ch4,
        ch5,
        ch6,
        pausa_contraccion,
        num_contraccion,
        pausa_series,
        series,
        musclesSelected,
        technical_name,
        public_name,
        description,
        frecuencia,
        ancho_pulso,
        time,
      } = this.props.data.params;
      let pulsos = [
        ...getCh(ch1, 1),
        ...getCh(ch2, 2),
        ...getCh(ch3, 3),
        ...getCh(ch4, 4),
        ...getCh(ch5, 5),
        ...getCh(ch6, 6),
      ];
      this.setState({
        config_channels,
        ch1,
        ch2,
        ch3,
        ch4,
        ch5,
        ch6,
        pausa_contraccion,
        num_contraccion,
        pausa_series,
        series,
        frecuencia,
        ancho_pulso,
        frecuenciaTemp: frecuencia,
        ancho_pulsoTemp: ancho_pulso,
        technical_name: technical_name || "",
        public_name: public_name || "",
        description: description || "",
        time: time || "00:00:00",
      });

      if (pulsos.length > 0) {
        let d = pulsos[0];
        this.setState({
          channelSelected: d.ch,
          selectedId: d.id,
          position: d.pos,
          duration: d.seconds,
          leftRamp: d.rampl,
          rightRamp: d.rampr,
        });
      }

      if (musclesSelected) {
        this.setState({ musclesSelected });
      }
    }
  }

  checkSliderAction() {
    return (
      this.state.ch1.length !== 0 ||
      this.state.ch2.length !== 0 ||
      this.state.ch3.length !== 0 ||
      this.state.ch4.length !== 0 ||
      this.state.ch5.length !== 0 ||
      this.state.ch6.length !== 0
    );
  }

  calc() {
    var arr = [],
      box = 0,
      space;
    space = self.grid.current.clientWidth / 10;
    for (let i = 0; i < 9; i++) {
      box = box + space;
      arr.push(box);
    }
    self.setState({ matrix: arr, total: space });
  }

  updateLineRed() {
    let line = document.getElementById("redline");
    let pulsos = [
      ...this.state.ch1,
      ...this.state.ch2,
      ...this.state.ch3,
      ...this.state.ch4,
      ...this.state.ch5,
      ...this.state.ch6,
    ];
    let results = [0];

    pulsos.forEach((e) => {
      let element = document.getElementById(e.id);
      if (element) {
        let pos = parseInt(
          window.getComputedStyle(element).getPropertyValue("left").split("px")[0]
        );
        let w = parseInt(window.getComputedStyle(element).getPropertyValue("width").split("px")[0]);
        results.push(parseInt(pos + w));
      }
    });

    if (results.length > 0) {
      line.style.left = Math.max(...results) + "px";
    }
  }

  
  generateLines() {
    let lines = [
      <div
        id="redline"
        key={0}
        style={{
          position: "absolute",
          left: `0px`,
          width: 1,
          height: 240,
          background: "#FF0000",
          zIndex: 10000000,
        }}
      ></div>,
    ];

    this.state.matrix.map((i, o) =>
      lines.push(
        <div
          key={o + 1}
          style={{
            position: "absolute",
            left: `${i}px`,
            width: 1,
            height: 240,
            background: "#F0F0F0",
            zIndex: 1000,
          }}
        >
          <p
            className="noselect"
            style={{ position: "absolute", bottom: -45, left: -5, color: "#CCC" }}
          >
            {o + 1}
          </p>
        </div>
      )
    );

    return lines.map((e) => e);
  }

  pulso(e, ch, i) {
    let seconds = e.seconds * this.state.total;
    var vrp = e.rampl / e.seconds;
    var vrn = e.rampr / e.seconds;
    let pos = e.pos * this.state.total;
    return (
      <div
        id={e.id}
        key={i}
        onClick={() => this.setSliders(ch, e.id, e.pos, e.seconds, e.rampl, e.rampr, seconds)}
        style={{
          cursor: "pointer",
          position: "absolute",
          left: `${pos}px`,
          width: seconds || 30,
          height: 40,
          backgroundColor: "rgba(144,130,210,0.47)",
        }}
      >
        <div
          className="path"
          style={{
            cursor: "pointer",
            position: "absolute",
            backgroundColor: e.id === this.state.selectedId ? "#281B65" : "#9082D2",
            width: seconds || 30,
            height: 40,
            clipPath: `polygon(${vrp * 100}% 0,${vrn * 100}% 0, 100% 100%, 0 100%)`,
          }}
        ></div>
        <p
          className="noselect"
          style={{ color: "#FFF", fontSize: 10, padding: 5, position: "absolute" }}
        >
          Pulso: {i + 1}
        </p>
      </div>
    );
  }

  setSliders(ch, id, pos, seconds, rl, rr, wd) {
    this.setState({
      channelSelected: ch,
      selectedId: id,
      position: pos,
      duration: seconds,
      leftRamp: rl,
      rightRamp: rr,
      wd: wd,
    });
  }

  values() {
    const chs = this.state.channels.map((e) => (e.name !== "" ? true : false));
    let result = Object.assign({}, this.state);
    result["channels"] = chs;
    result["config_channels"] = this.state.channels.filter((e) => e.name !== "");
    if (this.props.values !== undefined) this.props.values(result);
  }

  componentDidUpdate(props, state) {
    this.values();

    this.updateLineRed();

    let pec = toInt(this.state.pausa_contraccion); //this.state.pausa_contraccion
    let nc = this.state.num_contraccion;
    let ns = this.state.series;
    let pes = toInt(this.state.pausa_series);
    let pulsos = [
      ...this.state.ch1,
      ...this.state.ch2,
      ...this.state.ch3,
      ...this.state.ch4,
      ...this.state.ch5,
      ...this.state.ch6,
    ];
    let tc = pulsos.length > 0 ? Math.max(...pulsos.map((e) => parseInt(e.pos + e.seconds))) : 0;
    let t = ns * (nc * (tc + pec) - pec) + pes * (ns - 1);
    let total = this.convertir(t);

    if (state.time !== total) this.setState({ time: total });
  }

  convertir(segundosP) {
    if (segundosP >= 0) {
      const segundos = Math.round(segundosP % 0x3c).toString();
      const s = segundos < 10 ? "0" + segundos : segundos.toString();
      const horas = Math.floor(segundosP / 0xe10);
      const h = horas < 10 ? "0" + horas : horas.toString();
      const minutos = (Math.floor(segundosP / 0x3c) % 0x3c).toString();
      const m = minutos < 10 ? "0" + minutos : minutos.toString();
      return `${h}:${m}:${s}`;
    } else {
      return `00:00:00`;
    }
  }

  validChannel() {
    if (
      this.state.channelSelected === 1 ||
      this.state.channelSelected === 2 ||
      this.state.channelSelected === 3 ||
      this.state.channelSelected === 4 ||
      this.state.channelSelected === 5 ||
      this.state.channelSelected === 6
    )
      return true;
    else return false;
  }

  setValuesSlider(key, value) {
    if (this.validChannel()) {
      this.setState(function (state, props) {
        // eslint-disable-next-line array-callback-return
        state["ch" + this.state.channelSelected].find((e) => {
          if (e.id === this.state.selectedId) e[key] = value;
        });
        return state;
      });
    }
  }

  setBoth(value) {
    // eslint-disable-next-line array-callback-return
    this.state["ch" + this.state.channelSelected].find((e) => {
      if (e.id === this.state.selectedId) e["both"] = value;
    });
  }

  getIndex() {
    var a = this.state["ch" + this.state.channelSelected];
    for (var i = 0; i < a.length; i++) {
      if (a[i].id === this.state.selectedId) return i;
    }
    return -1;
  }

  rampLeft(value) {
    if (this.checkSliderAction()) {
      var tmp = this.state.leftRamp;
      var v = value < 0.5 ? 0 : value;

      if (v >= this.state.rightRamp && v > 0) {
        this.setBoth(true);
        v -= v - this.state.rightRamp;
      } else if (v < tmp) this.setBoth(false);

      var index = this.getIndex();

      if (this.state["ch" + this.state.channelSelected][index]["both"]) {
        var vr = this.state.rightRamp - (tmp - value);
        if (vr > this.state.duration - 0.5) vr = this.state.duration;
        this.setState({ leftRamp: v, rightRamp: this.roundOneDecimal(vr) });
        this.setValuesSlider("rampl", v);
        this.setValuesSlider("rampr", this.roundOneDecimal(vr));
      } else {
        this.setState({ leftRamp: v });
        this.setValuesSlider("rampl", v);
      }
    }
  }

  rampRight(value) {
    if (this.checkSliderAction()) {
      var tmp = this.state.rightRamp;
      var v = value > this.state.duration - 0.5 ? this.state.duration : value;

      if (v <= this.state.leftRamp && v > 0) {
        this.setBoth(true);
        v -= v - this.state.leftRamp;
      } else if (v > tmp) this.setBoth(false);

      var index = this.getIndex();

      if (this.state["ch" + this.state.channelSelected][index]["both"]) {
        var vl = this.state.leftRamp - (tmp - value);
        if (vl < 0.5) vl = 0;
        this.setState({ rightRamp: v, leftRamp: this.roundOneDecimal(vl) });
        this.setValuesSlider("rampr", v);
        this.setValuesSlider("rampl", this.roundOneDecimal(vl));
      } else {
        this.setState({ rightRamp: v });
        this.setValuesSlider("rampr", v);
      }
    }
  }

  duration(value) {
    console.log({value})
    this.setState({ duration: this.roundOneDecimal(value), rightRamp: value });
    this.setValuesSlider("seconds", value);
    this.setValuesSlider("rampr", value);
  }

  position(value) {
    this.setState({ position: value });
    this.setValuesSlider("pos", this.state.position);
  }

  create(ch) {
    let id = Math.random().toString(36).slice(-8);
    this.setState(function (state, props) {
      state["ch" + ch].push({ pos: 0, seconds: 1, rampl: 0, rampr: 1, id: id, both: false });
      state["selectedId"] = id;
      state["channelSelected"] = ch;
      state["leftRamp"] = 0;
      state["rightRamp"] = 1;
      state["duration"] = 1;
      state["position"] = 0;
      return state;
    });
  }

  deletePulse() {
    if (this.state.channelSelected !== "" && this.state.selectedId !== "") {
      this.setState((state, props) => {
        let d = Object.assign([], state["ch" + this.state.channelSelected]);
        d.splice(
          d.findIndex((r) => r.id === this.state.selectedId),
          1
        );
        state["ch" + this.state.channelSelected] = d;

        return state;
      });
      setTimeout(this.noDataChannel, 100);
    }
  }

  noDataChannel() {
    const { ch1, ch2, ch3, ch4, ch5, ch6 } = self.state;
    if ([].concat(ch1, ch2, ch3, ch4, ch5, ch6).length === 0) {
      self.setState({
        selectedId: "",
        channelSelected: "",
        rightRamp: 0,
        leftRamp: 0,
        duration: 0,
        position: 0,
      });
      self.setValuesSlider("rampr", 0);
      self.setValuesSlider("rampl", 0);
      self.setValuesSlider("pos", 0);
      self.setValuesSlider("seconds", 0);
    }
  }

  calcTime() {
    return 10 - this.state.duration;
  }

  calcDuration() {
    return 10 - this.state.position;
  }

  roundOneDecimal(num) {
    return Math.round(num * 10) / 10;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calc, false);
  }

  valueLabel(value) {
    return `${this.roundOneDecimal(this.state.duration - value)}`;
  }

  setMusclesSelected(data) {
    self.setState({ musclesSelected: data });
  }

  setConfigChannels(data) {
    self.setState({ config_channels: data });
  }

  setChannels(data) {
    self.setState({ channels: data });
  }

  setPublicName(e) {
    self.setState({ public_name: e });
  }

  setTechnicalName(e) {
    self.setState({ technical_name: e });
  }

  setDescription(e) {
    self.setState({ description: e });
  }

  setShowConfigModal(bool) {
    self.setState({ showConfigModal: bool });
  }

  setFrecuencyTemp(value) {
    self.setState({ frecuenciaTemp: value });
  }

  setPulseWidthTemp(value) {
    self.setState({ ancho_pulsoTemp: value });
  }

  handleConfigSubmit() {
    self.setState({
      ancho_pulso: self.state.ancho_pulsoTemp,
      frecuencia: self.state.frecuenciaTemp,
    });
    self.setShowConfigModal(false);
  }

  render() {
    const { t } = this.props;

    return (
      <S.ScreenContainer>
        <Container>
          <Row cols={12} gap={"30"}>
            <Col xs={12} md={4}>
              <S.SequentialForm>
                <InputBorder
                  placeholder={t("tech_name")}
                  value={this.state.technical_name}
                  onChange={({ target }) => this.setTechnicalName(target.value)}
                />
                <InputBorder
                  placeholder={t("public_name")}
                  value={this.state.public_name}
                  onChange={({ target }) => this.setPublicName(target.value)}
                  showRequired={false}
                />
                <Textarea
                  placeholder={t("description")}
                  value={this.state.description}
                  onChange={({ target }) => this.setDescription(target.value)}
                />
              </S.SequentialForm>

              <S.Title>{t("select_muscles")}</S.Title>

              {this.state.musclesSelected &&
                this.state.musclesSelected.map((channel, i) => (
                  <MuscleSelector
                    key={i}
                    index={i}
                    data={channel}
                    musclesSelected={this.state.musclesSelected}
                    setMusclesSelected={this.setMusclesSelected}
                    configChannels={this.state.config_channels || null}
                    setConfigChannels={this.setConfigChannels}
                    channels={this.state.channels}
                    setChannels={this.setChannels}
                  />
                ))}
            </Col>
            <Col xs={12} md={8}>
              <C.ConfigTimeContainer>
                <S.TimeContainer style={{ margin: "7px 0" }}>
                  <S.TimeTotal>{t("total_time")}:</S.TimeTotal>
                  <S.Box padding={"10px"}>
                    <S.TimeText>{this.state.time}</S.TimeText>
                  </S.Box>
                </S.TimeContainer>
                <C.ConfigButton onClick={() => this.setShowConfigModal(true)}>
                  <ConfigIcon />
                </C.ConfigButton>
              </C.ConfigTimeContainer>

              <S.GridContainer>
                <S.Box padding={"10px 15px 0px 15px"}>
                  <S.GridTitle>{t("titleSeries")}</S.GridTitle>
                  <Counter
                    data={this.state.series}
                    title="1 - 50"
                    min={1}
                    max={50}
                    values={(e) => this.setState({ series: e })}
                  />
                </S.Box>
                <S.Box padding={"10px 15px 0px 15px"}>
                  <S.GridTitle>{t("pauseSeries")}</S.GridTitle>
                  <TimePicker
                    data={this.state.pausa_series}
                    seconds={true}
                    minutes={true}
                    values={(value) => this.setState({ pausa_series: value })}
                  />
                </S.Box>
                <S.Box padding={"10px 15px 0px 15px"}>
                  <S.GridTitle>{t("num_repeats")}</S.GridTitle>
                  <Counter
                    data={this.state.num_contraccion}
                    title="0 - 50"
                    min={0}
                    max={50}
                    values={(e) => this.setState({ num_contraccion: e })}
                  />
                </S.Box>
                <S.Box padding={"10px 15px 0px 15px"}>
                  <S.GridTitle>{t("pause_between_repeat")} (0 - 60s)</S.GridTitle>
                  <TimePicker
                    data={this.state.pausa_contraccion}
                    seconds={true}
                    values={(value) => this.setState({ pausa_contraccion: value })}
                  />
                </S.Box>
              </S.GridContainer>

              <S.TimelineTitle style={{ marginTop: 25 }}>
                {t("select_at_least_one_pulse")}
              </S.TimelineTitle>
              <S.TimelineContainer>
                <S.TrashIcon selected={this.state.selectedId} onClick={() => this.deletePulse()}>
                  <span style={{ fontSize: 21 }} className="icon-trash pointer"></span>
                </S.TrashIcon>

                <S.Timeline id="grid" ref={this.grid}>
                  
                  {this.generateLines()}

                  <S.TimelineBg>
                    <S.TimelineSelect
                      active={this.state.musclesSelected[0].img}
                      backgroundColor={"#281b65"}
                      onClick={() => this.create(1)}
                    >
                      1+
                    </S.TimelineSelect>
                    {this.state.ch1.map((e, i) => this.pulso(e, 1, i))}
                  </S.TimelineBg>
                  <S.TimelineBg>
                    <S.TimelineSelect
                      active={this.state.musclesSelected[1].img}
                      backgroundColor={"#fdad34"}
                      onClick={() => this.create(2)}
                    >
                      2+
                    </S.TimelineSelect>
                    {this.state.ch2.map((e, i) => this.pulso(e, 2, i))}
                  </S.TimelineBg>
                  <S.TimelineBg>
                    <S.TimelineSelect
                      active={this.state.musclesSelected[2].img}
                      backgroundColor={"#281b65"}
                      onClick={() => this.create(3)}
                    >
                      3+
                    </S.TimelineSelect>
                    {this.state.ch3.map((e, i) => this.pulso(e, 3, i))}
                  </S.TimelineBg>
                  <S.TimelineBg>
                    <S.TimelineSelect
                      active={this.state.musclesSelected[3].img}
                      backgroundColor={"#fdad34"}
                      onClick={() => this.create(4)}
                    >
                      4+
                    </S.TimelineSelect>
                    {this.state.ch4.map((e, i) => this.pulso(e, 4, i))}
                  </S.TimelineBg>
                  <S.TimelineBg>
                    <S.TimelineSelect
                      active={this.state.musclesSelected[4].img}
                      backgroundColor={"#281b65"}
                      onClick={() => this.create(5)}
                    >
                      5+
                    </S.TimelineSelect>
                    {this.state.ch5.map((e, i) => this.pulso(e, 5, i))}
                  </S.TimelineBg>
                  <S.TimelineBg>
                    <S.TimelineSelect
                      active={this.state.musclesSelected[5].img}
                      backgroundColor={"#fdad34"}
                      onClick={() => this.create(6)}
                    >
                      6+
                    </S.TimelineSelect>
                    {this.state.ch6.map((e, i) => this.pulso(e, 6, i))}
                  </S.TimelineBg>
                </S.Timeline>
              </S.TimelineContainer>

              <S.GridContainer>
                <S.Box padding={"10px 15px 0px 15px"}>
                  <S.GridTitle>{t("startTime")}</S.GridTitle>
                  <S.SliderIndicator>{this.state.position}</S.SliderIndicator>
                  <S.SliderContainer>
                    <Slider
                      step={0.1}
                      value={this.state.position}
                      min={0}
                      max={this.calcTime()}
                      onChange={(value) => this.position(this.roundOneDecimal(value))}
                    />
                  </S.SliderContainer>
                </S.Box>

                <S.Box padding={"10px 15px 0px 15px"}>
                  <S.GridTitle>{t("durationTime")}</S.GridTitle>
                  <S.SliderIndicator>{this.state.duration}</S.SliderIndicator>
                  <S.SliderContainer>
                    <Slider
                      step={0.1}
                      value={this.state.duration}
                      min={0.1}
                      max={this.calcDuration()}
                      onChange={(value) => this.duration(this.roundOneDecimal(value))}
                    />
                  </S.SliderContainer>
                </S.Box>

                <S.Box padding={"10px 15px 0px 15px"}>
                  <S.GridTitle>{t("leftRamp")}</S.GridTitle>
                  <S.SliderIndicator>{this.state.leftRamp}</S.SliderIndicator>
                  <S.SliderContainer>
                    <Slider
                      step={this.state.leftRamp > 0.5 ? 0.1 : 0.5}
                      value={this.state.leftRamp}
                      min={0}
                      max={this.state.duration}
                      onChange={(value) => this.rampLeft(value)}
                    />
                  </S.SliderContainer>
                </S.Box>

                <S.Box padding={"10px 15px 0px 15px"}>
                  <S.GridTitle>{t("rightRamp")}</S.GridTitle>
                  <S.SliderIndicator>
                    {this.roundOneDecimal(this.state.duration - this.state.rightRamp)}
                  </S.SliderIndicator>
                  <S.SliderContainer>
                    <Slider
                      step={0.1}
                      value={this.state.rightRamp}
                      min={0}
                      max={this.state.duration}
                      onChange={(value) => this.rampRight(value)}
                    />
                  </S.SliderContainer>
                </S.Box>
              </S.GridContainer>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.showConfigModal}
          buttons={
            <>
              <Button
                type="ghost"
                width="142px"
                label={t("cancel")}
                onClick={() => {
                  this.setFrecuencyTemp(this.state.frecuencia);
                  this.setPulseWidthTemp(this.state.ancho_pulso);
                  this.setShowConfigModal(false);
                }}
              />
              <Button width="142px" label={t("save")} onClick={this.handleConfigSubmit} />
            </>
          }
        >
          <C.ModalConfigContainer>
            <S.Box padding={"10px 15px 0px 15px"}>
              <S.GridTitle>{t("frecuency")} (HZ)</S.GridTitle>
              <S.SliderIndicator>{this.state.frecuenciaTemp || 0}</S.SliderIndicator>
              <S.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.frecuenciaTemp}
                  min={5}
                  max={60}
                  onChange={(value) => this.setFrecuencyTemp(value)}
                />
              </S.SliderContainer>
            </S.Box>

            <S.Box padding={"10px 15px 0px 15px"}>
              <S.GridTitle>{t("pulse_width")} (US)</S.GridTitle>
              <S.SliderIndicator>{this.state.ancho_pulsoTemp || 0}</S.SliderIndicator>
              <S.SliderContainer>
                <Slider
                  step={1}
                  value={this.state.ancho_pulsoTemp}
                  min={20}
                  max={400}
                  onChange={(value) => this.setPulseWidthTemp(value)}
                />
              </S.SliderContainer>
            </S.Box>
          </C.ModalConfigContainer>
        </Modal>
      </S.ScreenContainer>
    );
  }
}

export default withTranslation("sessionStudio")(connect(Graphic));
