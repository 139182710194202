import React from "react";
import { Redirect, useLocation } from 'react-router-dom';
import CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode"

const isToken = () => {
    return getToken() != null || undefined ? true : false;
}

export const getToken = () => {
    return window.localStorage.getItem('token');
}

export const setToken = (value, opt = '') => {
    window.localStorage.setItem('token', JSON.stringify(value))
}

export const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                let [key, value] = param.split('=');
                key = decodeURIComponent(key.replace(/\+/g, ' '));
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {}
            )
        : {}
};

export const qsStringify = (data) => {
    let query = '';
    if (Object.keys(data).length !== 0) query += `${Object.keys(data).sort().map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&')}`;
    return query;
}

const getTokenParams = (loc) => {
    if (loc && loc.search) {
        const key = ';OOCR*4:?%uEpgI9-y;SdQ6V{E+Ca^Wpa}[d*(&{3[dBE="muBdm8=8z!|mZkdv';
        const params = getQueryStringParams(loc.search);
        const token = params.token;
        delete params.token;
        const qs = qsStringify(params);
        const sha256 = CryptoJS.HmacSHA256(qs, key).toString();
        console.log(sha256)
        return token === sha256;
    } else {
        return false;
    }
}

export const Middleware = (Component) => {
    const loc = useLocation();
    const tknp = getTokenParams(loc);
    const tkn = isToken();
    return (tkn || tknp) ? <Component /> : <Redirect to="/login" />
}

export const MiddlewareTokenParams = (Component) => {
    const loc = useLocation();
    const tknp = getTokenParams(loc);
    return tknp ? <Component /> : <Redirect to="/login" />
}

export const getUserId = () => {
    const token = getToken();
    if (token) {
        const payload = jwt_decode(token);
        return {
            userId: payload.user._id,
            rut: payload.user.rut,
            name: payload.user.name + " " + payload.user.lastname,
            rol: payload.user.rol,
            avatar: payload.user.avatar,
            email: payload.user.email,
            user: payload.user,
            token: token
        }
    } else {
        return {
            userId: "",
            rut: "",
            name: "",
            rol: "",
            avatar: "",
            email: "",
            user: "",
            token: ""
        }
    }
}

export const closeSession = () => {
    window.localStorage.removeItem('token');
    window.location.href = "/";
}