import endpoint from '../services/endpoint'
import IcoPerfil from '../assets/img/iconMenu/icon-perfil.svg';

export const getUrl = (path) => {
    if (path != null) return path.includes('http') ? path : endpoint + path;
    else return IcoPerfil;
}

export const normalizeHeight = (selector, min = 0) => {
    document.querySelector(selector).style.minHeight = (window.innerHeight - min) + 'px';
    window.addEventListener('resize', function () {
        document.querySelector(selector).style.minHeight = (window.innerHeight - min) + 'px';
    });
}


export const normalizeHeightByElement = (selector, min = 0) => {
    selector.style.height = (window.innerHeight - min) + 'px';
    window.addEventListener('resize', function () {
        selector.style.height = (window.innerHeight - min) + 'px';
    });
}


export const generateUUID = () => {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line no-mixed-operators
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

export const clearFormInput = (id) => {
    let d = document.querySelector(id);
    Array.from(d.querySelectorAll('input')).map(e => e.value = '');
    Array.from(d.querySelectorAll('textarea')).map(e => e.value = '');
}