import React from 'react';
import styled from 'styled-components';

const pagination = ({
  currentPage, 
  totalPages,
  onPageChange 
}) => {
  
  if (totalPages === 0) return null;
  const pages = [...Array(totalPages + 1).keys()].slice(1);

  return (
    <ContainerStyled>
      <ButtonStyled
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        isArrow
      >
        {'«'}
      </ButtonStyled>
      
      {pages.map(page => (
        <ButtonStyled
          key={page}
          onClick={() => onPageChange(page)}
          disabled={page === currentPage}
        >
          {page}
        </ButtonStyled>  
      ))}

      <ButtonStyled
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        isArrow
      >
        {'»'}
      </ButtonStyled>

    </ContainerStyled>
  );

}

export default pagination;

const ContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonStyled = styled.button`
  background-color: #fff;
  border: none;
  border-radius: 4px;
  color: #281b65;
  padding: 6px 12px;
  margin: 0 3px;
  font-size: 14px;
  &:hover {
    background-color: #ecf2ff;
  }
  &:disabled {
    background-color: #ecf2ff;
    cursor: not-allowed;
  }
  ${({ isArrow }) => isArrow && `
    &:disabled {
    background-color: #fff;
    color: #c5c5c5;
    cursor: not-allowed;
    }
  `}
`;