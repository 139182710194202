import './style.css';

const generateXAxis = (m = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0, -10]) => {
    let container = document.querySelector('#chart .xLineContainer');
    container.innerHTML = "";
    let o = 0;
    let t = 0;
    let f = mediaQuery(m.length - 1);

    while (o < m.length) {
        createDivP(t, m[o]);
        t = t + f;
        o++;
    }
}

const createDivP = (x, value) => {
    let container = document.querySelector('#chart .xLineContainer');
    let p = document.createElement('p');
    p.style.position = "absolute";
    p.style.top = (x) + "px";
    p.style.right = "10px";
    p.style.textAlign = "right";
    p.style.margin = "0";
    p.style.padding = "0";
    p.className = "value-y";
    p.innerText = value;
    container.appendChild(p);
}

const createPolygon = (e, bool) => {
    let s = document.getElementById('contSvg');
    let svgNS = s.namespaceURI;
    let v = document.getElementById('v');
    let poly = document.createElementNS(svgNS, 'polygon');
    poly.setAttributeNS(null, "points", `${e.x},0 ${e.x},${e.last} ${e.w + e.x},${e.h} ${e.w + e.x},0`);
    poly.setAttribute("fill", e.color);
    poly.setAttribute("stroke", e.stroke);
    poly.setAttribute("stroke-width", e.strokeWidth);
    v.appendChild(poly);
    // let pos = e.last > e.h ? e.last : e.h;
    if (bool) {
        // pos > 475 ? 30 : 490 - pos
        createRectLabel({ title: e.title, opt: e, alt: 515 });
    }
}

const createRect = (e, bool) => {
    let s = document.getElementById('contSvg');
    let svgNS = s.namespaceURI;
    let v = document.getElementById('v');
    let rect = document.createElementNS(svgNS, 'rect');
    rect.setAttribute("x", e.x);
    rect.setAttribute("y", e.y);
    rect.setAttribute("width", e.w);
    rect.setAttribute("height", e.h);
    rect.setAttribute("fill", e.color);
    rect.setAttribute("stroke", e.stroke);
    rect.setAttribute("stroke-width", e.strokeWidth);
    v.appendChild(rect);
    if (bool) {
        // e.h > 475 ? 30 : 490 - e.h
        createRectLabel({ title: e.title, opt: e, alt: 515 });
    }
}

const createRectLabel = (e) => {
    let date = new Date();
    let id = 'text' + date.getTime();
    let s = document.getElementById('contSvg');
    let svgNS = s.namespaceURI;
    let v = document.getElementById('y');
    let t = document.createElementNS(svgNS, 'text');
    t.setAttributeNS(null, "x", e.opt.x + (e.opt.w / 2));
    t.setAttributeNS(null, "y", e.alt); //"420"
    t.setAttributeNS(null, "dominant-baseline", "middle");
    t.setAttributeNS(null, "text-anchor", "middle");
    t.setAttributeNS(null, "id", id);
    t.setAttributeNS(null, "fill", "#281B65");
    t.textContent = e.title;
    v.appendChild(t);
}

// const createLineUmbral = (y, w) => {
//     let s = document.getElementById('contSvg');
//     let svgNS = s.namespaceURI;
//     let v = document.getElementById('v');
//     let rect = document.createElementNS(svgNS, 'rect');
//     rect.setAttribute("x", 0);
//     rect.setAttribute("y", y);
//     rect.setAttribute("width", w);
//     rect.setAttribute("height", "1px");
//     rect.setAttribute("fill", "#FF0000");
//     v.appendChild(rect);
// }

const createData = (data = [], t = 0) => {
    const min = 0;
    let i = 0;
    let d = [];
    let mq = mediaQuery(11) / 10;
    while (i < data.length) {
        let type = t === 0 ? 2 : 3;
        let rect = {}
        let px = 10;
        if (i === 0) rect['x'] = min;
        else rect['x'] = d[i - 1]['x'] + data[i - 1][1] * px;

        //  resolver
        rect['y'] = (data[i][type] + 10) * mq;
        rect['w'] = data[i][1] * px;
        rect['h'] = ((data[i][type] === -10 ? -9 : data[i][type]) + 10) * mq;
        if (i === 0) rect['last'] = 0;
        else rect['last'] = d[i - 1]['h'];
        if (type === 2) {
            rect['color'] = "rgba(40,27,101,.5)";
            rect['stroke'] = "rgba(40,27,101,.5)";
            rect['strokeWidth'] = "1";
        } else {
            rect['color'] = "rgba(253,173,52,.2)";
            rect['stroke'] = "rgba(253,173,52,1)";
            rect['strokeWidth'] = "3";
        }

        rect['type'] = data[i][4] !== undefined && data[i][4].type !== undefined ? data[i][4].type : 0;
        rect['title'] = data[i][0];
        d.push(rect);
        if (rect['type'] === 0) createRect(rect, t === 0);
        else createPolygon(rect, t === 0);
        i++;
    }

    let s = document.getElementById('contSvg');
    const reducer = (previousValue, currentValue) => previousValue + currentValue.w;
    let w = d.reduce(reducer, 0)
    let p = parentWidth(s.parentNode) - 30;
    let width = w < p ? p : w;
    s.setAttribute("width", width);
}

const parentWidth = (elem) => {
    return elem.parentElement.clientWidth;
}

const mediaQuery = (count = 10) => {
    return 490 / count;
}


const generateChart = (obj) => {
    // eslint-disable-next-line no-unused-vars
    let arrDefault = [], titleXDefault = "", titleYDefault = "";
    document.getElementById('v').innerHTML = "";
    document.getElementById('x').innerHTML = "";
    document.getElementById('y').innerHTML = "";

    generateXAxis();
    createData(obj.data !== undefined ? obj.data : arrDefault, 0);
    createData(obj.data !== undefined ? obj.data : arrDefault, 1);

    var box = document.getElementById('boxChart');
    box.scrollLeft = box.scrollWidth;
}

export default generateChart;