import React, { useState, useEffect, Fragment } from 'react';
import '../assets/css/loginStyle.css';
import './style.css';
import { Grid, Fab } from '@material-ui/core';
// import { connect } from '../store'
// import { useTranslation } from 'react-i18next';
import Selet from '../components/selet';
// import Logo from '../assets/img/logos/logo-clinica.svg';
import Facebook from '../assets/img/social-media/facebook.svg';
import Instagram from '../assets/img/social-media/instagram.svg';
import Linkedin from '../assets/img/social-media/linkedin.svg';
import axios from 'axios';
import { useCookies } from 'react-cookie';
// import PublishIcon from '@material-ui/icons/Publish';
import { getUrl } from '../lib/utils'


const StaticSite = (props) => {
    // const { t, i18n } = useTranslation('');
    const [cookies] = useCookies(['tenantId']);
    const [custom, setCustom] = useState({});

    const [professional, setProfessionals] = useState([])
    const [subs, setSubs] = useState([])
    const [specialities, setSpecialities] = useState([])
    const [load, setLoad] = useState(true);

    const [nameForm, setNameForm] = useState("");
    const [lastnameForm, setLastNameForm] = useState("");
    const [emailForm, setEmailForm] = useState("");
    const [phoneForm, setPhoneForm] = useState("");
    const [messageForm, setMessageForm] = useState("");

    const [errName, setErrName] = useState(false);
    const [errLastName, setErrLastName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errMessage, setErrMessage] = useState(false);

    const [successSend, setSuccessSend] = useState(false);

    const [more, setMore] = useState(false);

    const getData = () => {
        let tenant = cookies.tenant || "agF";
        axios.get(`/custom/org/${tenant}`)
            .then(res => {
                console.log(res.data)
                setCustom(res.data.data)
                setLoad(false);
            })
            .catch(err => console.log(err))
    }


    const getAllProfesional = () => {
        let tenant = cookies.tenant || "agF";
        axios.get(`/professionale/by/tenant/${tenant}`)
            .then(res => setProfessionals(res.data.data))
            .catch(err => console.log(err))
    }

    const getAllSubsidiaries = () => {
        let tenant = cookies.tenant || "agF";
        axios.get(`/subsidiaries/by/tenant/${tenant}`)
            .then(res => setSubs(res.data.data))
            .catch(err => console.log(err))
    }

    const getAllSpecialities = () => {
        let tenant = cookies.tenant || "agF";
        axios.get(`/specialities/by/tenant/${tenant}`)
            .then(res => {
                console.log(res.data.data)
                setSpecialities(res.data.data)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getData();
        getAllProfesional();
        getAllSubsidiaries();
        getAllSpecialities();
    }, [])

    const scrollTo = (id) => {
        var element = document.getElementById(id);
        var headerOffset = 60;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }


    const getSocialMedia = (rrss, img) => {
        if (custom && custom.social && custom.social[rrss] && custom.social[rrss].active) {
            return <a href={custom.social[rrss].url}><img
                src={img} alt={rrss.charAt(0).toUpperCase() + rrss.slice(1)} /></a>
        }

    }


    const sendForm = () => {
        let tenant = cookies.tenant || "agF";

        if (nameForm == null || nameForm.length === 0 || nameForm === "") {
            setErrName(true);
            return false;
        } else setErrName(false);

        if (lastnameForm == null || lastnameForm.length === 0 || lastnameForm === "") {
            setErrLastName(true);
            return false;
        } else setErrLastName(false);

        // eslint-disable-next-line no-useless-escape
        let reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!reg.test(emailForm)) {
            setErrEmail(true);
            return false;
        } else setErrEmail(false);

        if (messageForm == null || messageForm.length === 0 || messageForm === "") {
            setErrMessage(true);
            return false;
        } else setErrMessage(false);

        let data = { name: nameForm, lastname: lastnameForm, phone: phoneForm, email: emailForm, message: messageForm }
        axios.post(`/send/form/contac/${tenant}`, data)
            .then(res => {
                console.log(res)
                setSuccessSend(true);
            })
            .catch(err => console.log(err))
    }


    const Loader = () => {
        return (
            <section style={{ width: "100vw", height: "100vh", position: "fixed", top: 0, right: 0, left: 0, bottom: 0, background: "#FAFAFA", zIndex: "99999999999" }}>
                <ul class="loader-static">
                    <li class="center"></li>
                    <li class="item item-1"></li>
                    <li class="item item-2"></li>
                    <li class="item item-3"></li>
                    <li class="item item-4"></li>
                    <li class="item item-5"></li>
                    <li class="item item-6"></li>
                    <li class="item item-7"></li>
                    <li class="item item-8"></li>
                </ul>
            </section>
        )
    }

    const toggleMore = () => setMore(!more);

    return (
        <Fragment>
            {load ? Loader() : null}
            <section id='nav' style={{ background: "#FFF" }}>
                <nav className="navbar fijo color-botton-nav py-3 navbar-expand-lg reverse" style={{ borderBottom: `3px solid ${custom && custom.color ? custom.color : "transparent"} ` }}>
                    <div className="container-fluid">
                        <img src={getUrl(custom.logo)} className="img-fluid" alt='' style={{ maxWidth: 180 }} />
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse nav-t navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a href="##" className="nav-link" onClick={c => scrollTo("inicio")}>Inicio</a>
                                </li>
                                <li className="nav-item">
                                    <a href="##" className="nav-link" onClick={c => scrollTo("especialidades")} >Especialidades</a>
                                </li>
                                <li className="nav-item">
                                    <a href="##" className="nav-link" onClick={c => scrollTo("equipo")} >Equipo</a>
                                </li>
                                <li className="nav-item">
                                    <a href="##" className="nav-link" onClick={c => scrollTo("sucursales")} >Sucursales</a>
                                </li>
                                <li className="nav-item">
                                    <a href="##" className="nav-link" onClick={c => scrollTo("contacto")} >Contacto</a>
                                </li>
                                <li className="nav-item">
                                    <button className="btn-homepage" style={{ backgroundColor: custom && custom.color2 ? custom.color2 : "transparent", borderColor: custom && custom.color2 ? custom.color2 : "transparent" }}><a href="##" onClick={c => scrollTo("reserva")} className="text-white">Reserva Horas</a></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>
            <section id='inicio' className='bg-banner' style={{ backgroundImage: `url(${getUrl(custom.banner)})` }}>
                <div className="container-fluid d-flex justify-content-center align-items-center" style={{ height: "480px" }}>
                    <div className="row py-5 d-flex justify-content-center align-items-center">
                        <div className="col">
                            <div className="">
                                <div dangerouslySetInnerHTML={{ __html: custom && custom.desc }}></div>
                                <div dangerouslySetInnerHTML={{ __html: custom && custom.slogan }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='especialidades' className="bg-white">
                <div className="container-fluid" style={{ maxWidth: "1500px" }}>
                    <div className="row py-5 d-flex justify-content-center d-flex align-items-center">
                        <div className="col pt-5 text-center text-especialidades">
                            <h2>Especialidades</h2>
                        </div>
                    </div>
                    <div className="row py-5">
                        {specialities.map(e => {
                            return (
                                <div className="col-xs-12 col-md-3 d-flex justify-content-center">
                                    <div className="card p-3 text-center w-100">
                                        <h2>{e.name}</h2>
                                        <p>{e.desc}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section id='reserva' className="bg-white">
                <div className="container">
                    <div className="row py-5 d-flex justify-content-center d-flex align-items-center">
                        <div className="col text-center text-especialidades">
                            <h2>Reserva de Horas</h2>
                            <p>Para reservar hora, sigue los siguientes pasos</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center py-5">
                        <div className="col-6 title">
                            <div className="card">
                                <div className="sub-title text-white d-flex justify-content-center align-items-center" style={{ backgroundColor: custom && custom.color2 ? custom.color2 : "transparent" }}>
                                    <p className="p-3"><span className="icon-search pr-2 fz-14"></span> Busca tu hora</p>
                                </div>
                                <Grid item xs={12} md={12} className="px-5 pt-5 pb-4">
                                    <label for="name" className="m-0 pt-2">Sucursal</label>
                                    <Selet
                                        name={"Sucursal"}
                                        /*default={current && current.type}
                                        data={setType()}
                                        value={(value) => editField('type', value)}*/ />
                                </Grid>
                                <Grid item xs={12} container direction="row" justify="center" alignItems="center" className="mb-5">
                                    <button className="btn-homepage" style={{ width: "50%", backgroundColor: custom && custom.color2 ? custom.color2 : "transparent", borderColor: custom && custom.color2 ? custom.color2 : "transparent" }}>Siguiente</button>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='equipo' className="bg-white">
                <div className="container">
                    <div className="row py-5">
                        <div className="col text-center text-especialidades">
                            <h2>Equipo Clínico</h2>
                        </div>
                    </div>
                    <div className="row pt-5 animate-more" style={{ overflow: "hidden", maxHeight: more ? '100%' : 400 }}>
                        {professional.map(e => {
                            return (
                                <div className="col-12 col-md-2 d-flex justify-content-center mb-5">
                                    <div className="text-center equipo mb-5 mb-lg-0">
                                        <img src={getUrl(e.avatar)} className='perfil-box' alt='Avatar' />
                                        <h6 className="pt-4">{e.name} {e.lastname}</h6>
                                        <p>{e.job !== undefined && e.job != null && e.job.length > 0 ? e.job : ""}</p>
                                        <p>{e.subjob !== undefined && e.subjob != null && e.subjob.length > 0 ? e.subjob : ""}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-12 pt-5 text-center w-100">
                        <p className='color-red m-0 text-right w-100 pointer' onClick={toggleMore}>{!more ? "Ver Más" : "Ver Menos"}<span className='icon-arrow-point-to-right pl-1 fz-10'></span></p>
                    </div>
                </div>
            </section>
            <section id='sucursales' className="bg-white">
                <div className="container-fluid pb-5" style={{ maxWidth: 1300 }}>
                    <div className="row pt-5 d-flex justify-content-center d-flex align-items-center">
                        <div className="col text-center text-especialidades">
                            <h2>Sucursales</h2>
                        </div>
                    </div>
                    <div className="row py-5">

                        {subs.map(e => {
                            return (
                                <div className="card d-flex justify-content-center d-flex align-items-center">
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                <h2 className='text-red p-0'>{e.name}</h2>
                                            </div>
                                            <div className='col-md-3'>
                                                <p className="m-0 d-flex align-items-center"><span className="icon-email-site pr-2 fz-14"></span><a href='mailto:'>{e.email}</a></p>
                                            </div>
                                            <div className='col-md-2'>
                                                <p className="m-0 d-flex align-items-center"><span className="icon-phone-site pr-2 fz-14"></span>{e.phone}</p>
                                            </div>
                                            <div className='col-md-2'>
                                                <p className="m-0 d-flex align-items-center"><span className="icon-location-site pr-2 fz-14"></span>{e.comune}</p>
                                            </div>
                                            <div className='col-md-3'>
                                                <p className="m-0 d-flex align-items-center"><span className="icon-location-site pr-2 fz-14"></span>{e.region}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            {/*
            <div className="col-xs-12">
                <div className="card">
                    <h2>{e.name}</h2>
                    <ul className="m-0 p-0">
                        <li className="d-flex align-items-center"><span className="icon-email-site pr-2 fz-16"></span><a href='mailto:'>{e.email}</a></li>
                        <li className="py-2 d-flex align-items-center"><span className="icon-phone-site pr-2 fz-16"></span>{e.phone}</li>
                        <li className="d-flex align-items-center"><span className="icon-location-site pr-2 fz-16"></span>{e.comune}</li>
                        <li className="d-flex align-items-center"><span className="icon-location-site pr-2 fz-16"></span>{e.region}</li>
                    </ul>
                </div>
            </div>
            <section id='contacto' style={{ backgroundColor: "#F5F7FA" }}>
                <div className="container height-700">
                    <div className="pt-5 text-especialidades text-center">
                        <h2>Contacto</h2>
                    </div>
                    <div className="p-5 agenda2 d-flex justify-content-center">
                        <form className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label for="name" className="col-form-label">Nombre<span className="text-red">*</span></label>
                                        <input type="text" style={{ height: "45px" }} className="form-control bg-white" id="name" />
                                        <span className="alert-form">Este campo es requerido</span>
                                    </div>
                                    <div className="form-group">
                                        <label for="phone" className="col-form-label">Teléfono<span className="text-red">*</span>:</label>
                                        <input type="text" style={{ height: "45px" }} className="form-control bg-white" id="phone" value="+56" />
                                        <span className="alert-form">Formato de telefono incorrecto</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label for="lastname" className="col-form-label">Apellido<span className="text-red">*</span></label>
                                        <input type="text" style={{ height: "45px" }} className="form-control bg-white" id="lastname" />
                                        <span className="alert-form">Este campo es requerido</span>
                                    </div>
                                    <div className="form-group">
                                        <label for="mail" className="col-form-label">E-mail<span className="text-red">*</span>:</label>
                                        <input type="email" style={{ height: "45px" }} className="form-control bg-white" id="mail" />
                                        <span className="alert-form">Formato de correo incorrecto</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-5">
                                <label for="message" className="col-form-label">Comentario:</label>
                                <textarea className="form-control bg-white" rows={4} id="message"></textarea>
                                <span className="alert-form">Este campo es requerido</span>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn-homepage" id="send" style={{ width: "20%", backgroundColor: custom && custom.color2 ? custom.color2 : "transparent", borderColor: custom && custom.color2 ? custom.color2 : "transparent" }}>Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>*/}
            <section id='contacto' className='bg-formulario'>
                <div className="container height-700">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 d-flex align-items-start justify-content-start">
                            <div className="col pt-5 text-especialidades">
                                <h2>Formulario de contacto</h2>
                                <p className="pb-2">Contáctanos y pronto te responderemos</p>
                            </div>
                        </div>
                        <div className="col-ms-12 col-lg-6 mt-5 mt-lg-0">
                            <div className="bg-white p-5 agenda" style={{ boxShadow: "0px 1px 70px #E7ECF4" }}>
                                <form>
                                    <div className="form-group">
                                        <label for="name" className="col-form-label">Nombre<span className="text-red">*</span></label>
                                        <input type="text" className="form-control" id="name" value={nameForm} onChange={v => setNameForm(v.target.value)} />
                                        {errName ? <span className="alert-form d-block">Este campo es requerido</span> : null}
                                    </div>
                                    <div className="form-group">
                                        <label for="lastname" className="col-form-label">Apellido<span className="text-red">*</span></label>
                                        <input type="text" className="form-control" id="lastname" value={lastnameForm} onChange={v => setLastNameForm(v.target.value)} />
                                        {errLastName ? <span className="alert-form d-block">Este campo es requerido</span> : null}
                                    </div>
                                    <div className="form-group">
                                        <label for="phone" className="col-form-label">Teléfono:</label>
                                        <input type="text" className="form-control" id="phone" value={phoneForm} onChange={v => setPhoneForm(v.target.value)} />
                                        {/* <span className="alert-form d-block">Formato de telefono incorrecto</span> */}
                                    </div>
                                    <div className="form-group">
                                        <label for="mail" className="col-form-label">E-mail<span className="text-red">*</span>:</label>
                                        <input type="email" className="form-control" id="mail" value={emailForm} onChange={v => setEmailForm(v.target.value)} />
                                        {errEmail ? <span className="alert-form d-block">Formato de correo incorrecto</span> : null}
                                    </div>
                                    <div className="form-group mb-5">
                                        <label for="message" className="col-form-label">Comentario<span className="text-red">*</span>:</label>
                                        <textarea className="form-control" id="message" value={messageForm} onChange={v => setMessageForm(v.target.value)} ></textarea>
                                        {errMessage ? <span className="alert-form d-block">Este campo es requerido</span> : null}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button onClick={sendForm} type="button" className="btn-homepage" id="send" style={{ width: "50%", backgroundColor: custom && custom.color2 ? custom.color2 : "transparent", borderColor: custom && custom.color2 ? custom.color2 : "transparent" }}>Enviar</button>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        {successSend ? <p style={{ color: "green", fontWeight: "bold", marginTop: 20 }}>Mensaje enviado con exito</p> : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white">
                <div className="container">
                    <div className="row r-s">
                        <div className="col text-left py-5">
                            <img alt='' src={getUrl(custom.logo)} className="img-fluid" style={{ maxWidth: 180 }} />
                        </div>
                        <div className="col text-right py-5">
                            {getSocialMedia('facebook', Facebook)}
                            {getSocialMedia('instagram', Instagram)}
                            {getSocialMedia('linkedin', Linkedin)}
                            {getSocialMedia('tiktok', Linkedin)}
                            {getSocialMedia('twitter', Linkedin)}
                            {getSocialMedia('youtube', Linkedin)}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='go-up' onClick={c => scrollTo("nav")}>
                            <Fab className="text-white" style={{ backgroundColor: custom && custom.color2 ? custom.color2 : "transparent", borderColor: custom && custom.color2 ? custom.color2 : "transparent" }} aria-label="add" size="small"><span className='icon-up-arrow pointer fz-18 text-white'></span></Fab>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default StaticSite;
