import { createStore, combineReducers } from "redux";
import reducers from "./reducer";
import actions from "./actions";
import { connect as conn } from "react-redux";
// import axios from 'axios'

export const store = createStore(
  combineReducers({
    store: reducers,
  })
);

const mapStateToProps = (state, ownProps) => {
  return state;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setVersion: (payload) => dispatch(actions.setVersion(payload)),
    notify: (payload = {}) => {
      let noty = {};
      noty["type"] = payload["type"] !== undefined ? payload["type"] : "info";
      noty["title"] =
        payload["title"] !== undefined ? payload["title"] : "Información";
      noty["text"] = payload["text"] !== undefined ? payload["text"] : "";
      noty["time"] =
        payload["time"] !== undefined ? parseInt(payload["time"]) : 2000;
      dispatch(actions.pushNotification(noty));
    },
    notifyRemove: (payload = {}) =>
      dispatch(actions.removeNotification(payload)),
    setUserProfile: (payload) => dispatch(actions.setUserProfile(payload)),
    setVideos: (payload) => dispatch(actions.setVideos(payload)),
    setFiles: (payload) => dispatch(actions.setFiles(payload)),
    setImages: (payload) => dispatch(actions.setImages(payload)),
    setCategoriesMedia: (payload) =>
      dispatch(actions.setCategoriesMedia(payload)),
    setDeviceMedia: (payload) => dispatch(actions.setDeviceMedia(payload)),
    setRooms: (payload) => dispatch(actions.setRooms(payload)),
    setActivePromp: (payload) => dispatch(actions.setActivePromp(payload)),
    setPrompMessage: (payload) => dispatch(actions.setPrompMessage(payload)),
  };
};

export const connect = (component) => {
  return conn(mapStateToProps, mapDispatchToProps)(component);
};
