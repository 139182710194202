/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Ctrl from "./controller";
import {
  ProgressBar,
  ProgressBarItem,
  IconEvaluation,
  PlanningContainer,
  ItemContainer,
  Accordeon,
  ArrowContainer,
  HeaderContainer,
  Header,
  // ContainerMain,
  TitlePlans,
  ItemDetail,
  ArrowSVG,
  SidebarContent
} from "./style";
import { Row, Col } from "../../components/grilla/grid.component";

import { SearchBar } from "trainfes-components-library";
import Pagination from "../../components/pagination";

import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import { DatePickerComponent } from "../../components/DatePickerv2";
import { Button } from "trainfes-components-library";

import { FilterIcon } from "../../assets/icons";
// import { getUserId } from "../../lib/router";
import styled from "styled-components";

const FlowView = ({ ...props }) => {

  const date = new Date();

  const history = useHistory();
  const { t, i18n } = useTranslation("data_clinical");
  const currentLanguage = i18n.language;
  const [dataPatients, setDataPatients] = useState([]);
  // const [filter, setFilter] = useState("");
  const [load, setLoad] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [allDataPatients, setAllDataPatients] = useState([]);
  const [sortName, setSortName] = useState(true);
  const [sortPercent, setSortPercent] = useState(true);
  const [sortSessions, setSortSessions] = useState(true);
  const [sortEvaluations, setSortEvaluations] = useState(true);
  // const [sortNextSession, setSortNextSession] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const handleOpenSidebar = () => setShowSidebar(true);
  const handleCloseSidebar = () => setShowSidebar(false);
  const [minimumDate, setMinimumDate] = useState({year: date.getFullYear(), month: date.getMonth() , day: date.getDate()});
  const [maximumDate, setMaximumDate] = useState({year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()});
  // const [dateSeleted, setDateSelected] = useState({ from: null, to: null });
  const currentDate = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);

  const [fromDate, setFromDate] = useState({year: oneMonthAgo.getFullYear(), month: oneMonthAgo.getMonth() + 1, day: oneMonthAgo.getDate()});
  const [toDate, setToDate] = useState({year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()});


  const handleDatePickerChange = (val, setDate, minMaxDate) => {
    // val          ?   Value de Datepicker.
    // setDate      ?   State (setFromDate || setToDate).
    // minMaxDate   ?   State (setMinimumDate || setMaximumDate).

    setDate(val);
    minMaxDate(val);
  };
  
  const getDefaultDateFrom = () => {
    const storage = Ctrl.getDateStorage();
    const newPrev = new Date();
    newPrev.setMonth(newPrev.getMonth() - 1);
    const from =
      storage.from != null
        ? new Date(storage.from)
        : new Date(newPrev.getFullYear(), newPrev.getMonth() + 1, newPrev.getDate());
    return from;
  };

  const getDefaultDateTo = () => {
    const storage = Ctrl.getDateStorage();
    const d = new Date();
    const to =
      storage.to != null
        ? new Date(storage.to)
        : new Date(d.getFullYear(), d.getMonth() + 1, d.getDate());
    return to;
  };

  const numCols = 13;
  const sizeColum = {
    patient: 3,
    planning: 2.5,
    complex: 1.5,
    progress: 2,
    evaluation: 2,
    // prox: 2,
    more: 2,
  };

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");

  const getPatientsData =  (pageNum, fromDate = null, toDate = null, value) => {
    setLoad(true);
    const from =
      fromDate != null
        ? new Date(fromDate.year, fromDate.month - 1, fromDate.day - 1 )
        : new Date(getDefaultDateFrom());
    const to =
      toDate != null
        ? new Date(toDate.year, toDate.month - 1, toDate.day)
        : new Date(getDefaultDateTo());
    Ctrl.setDateStorage({ from, to });
    Ctrl.getData( pageNum, limit, { from, to, value })
      .then((res) => {
        setDataPatients(res.data);
        setAllDataPatients(res.data);
        setLoad(false);
        setTotalPages(res.totalPages);
      })
      .catch((err) => console.log(err));
  };

  const handlePageChange = (newPage) => {
    setPage(newPage); 
    if (currentSearchTerm) {
      fetchData(currentSearchTerm, newPage);
    } else {
      getPatientsData(newPage);
    }
  }
  
  const sortBy = (key = "", type = true, set) => {
    Ctrl.sortBy(dataPatients, key, type, set)
      .then((res) => {
        setDataPatients(res);
        set(!type);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPatientsData(page, fromDate, toDate);
  }, []);

  const progressBar = (a, b, c, d) => {
    return (
      <ProgressBar>
        <ProgressBarItem
          role="progressbar"
          bg={a.color}
          width={`${a.value}%`}
          aria-valuenow={a.value}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {a.label}
        </ProgressBarItem>
        <ProgressBarItem
          role="progressbar"
          bg={b.color}
          width={`${b.value}%`}
          aria-valuenow={b.value}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {b.label}
        </ProgressBarItem>
        <ProgressBarItem
          role="progressbar"
          bg={c.color}
          width={`${c.value}%`}
          aria-valuenow={c.value}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {c.label}
        </ProgressBarItem>
        <ProgressBarItem
          role="progressbar"
          bg={d.color}
          width={`${d.value}%`}
          aria-valuenow={d.value}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {d.label}
        </ProgressBarItem>
      </ProgressBar>
    );
  };

  const iconArrow = (key, type, set) => {
    return (
      <div onClick={() => sortBy(key, type, set)} className="ml-2 pointer">
        <svg
          width="14"
          height="14"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2899 14.2899L11.9999 18.5899L7.70994 14.2899C7.52164 14.1016 7.26624 13.9958 6.99994 13.9958C6.73364 13.9958 6.47825 14.1016 6.28994 14.2899C6.10164 14.4782 5.99585 14.7336 5.99585 14.9999C5.99585 15.2662 6.10164 15.5216 6.28994 15.7099L11.2899 20.7099C11.3829 20.8037 11.4935 20.8781 11.6154 20.9288C11.7372 20.9796 11.8679 21.0057 11.9999 21.0057C12.132 21.0057 12.2627 20.9796 12.3845 20.9288C12.5064 20.8781 12.617 20.8037 12.7099 20.7099L17.7099 15.7099C17.8032 15.6167 17.8771 15.506 17.9276 15.3842C17.9781 15.2624 18.004 15.1318 18.004 14.9999C18.004 14.8681 17.9781 14.7375 17.9276 14.6157C17.8771 14.4939 17.8032 14.3832 17.7099 14.2899C17.6167 14.1967 17.506 14.1227 17.3842 14.0723C17.2624 14.0218 17.1318 13.9958 16.9999 13.9958C16.8681 13.9958 16.7375 14.0218 16.6157 14.0723C16.4939 14.1227 16.3832 14.1967 16.2899 14.2899ZM7.70994 9.70994L11.9999 5.40994L16.2899 9.70994C16.3829 9.80367 16.4935 9.87806 16.6154 9.92883C16.7372 9.9796 16.8679 10.0057 16.9999 10.0057C17.132 10.0057 17.2627 9.9796 17.3845 9.92883C17.5064 9.87806 17.617 9.80367 17.7099 9.70994C17.8037 9.61698 17.8781 9.50637 17.9288 9.38452C17.9796 9.26266 18.0057 9.13195 18.0057 8.99994C18.0057 8.86793 17.9796 8.73722 17.9288 8.61536C17.8781 8.4935 17.8037 8.3829 17.7099 8.28994L12.7099 3.28994C12.617 3.19621 12.5064 3.12182 12.3845 3.07105C12.2627 3.02028 12.132 2.99414 11.9999 2.99414C11.8679 2.99414 11.7372 3.02028 11.6154 3.07105C11.4935 3.12182 11.3829 3.19621 11.2899 3.28994L6.28994 8.28994C6.10164 8.47824 5.99585 8.73364 5.99585 8.99994C5.99585 9.26624 6.10164 9.52164 6.28994 9.70994C6.47825 9.89824 6.73364 10.004 6.99994 10.004C7.26624 10.004 7.52164 9.89824 7.70994 9.70994Z"
            fill="black"
          />
        </svg>
      </div>
    );
  };

  const arrow = (active) => {
    return (
      <ArrowContainer active={active}>
        <svg
          className="rotate-icons"
          width="10"
          height="10"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2778:1584)">
            <path
              d="M9.0099 15.2901L0.410482 6.69044C-0.136827 6.1434 -0.136827 5.25646 0.410482 4.70968C0.957304 4.16285 1.8442 4.16285 2.39097 4.70968L10.0001 12.3191L17.609 4.7099C18.1561 4.16308 19.0429 4.16308 19.5897 4.7099C20.1368 5.25672 20.1368 6.14362 19.5897 6.69066L10.9902 15.2904C10.7166 15.5638 10.3585 15.7003 10.0002 15.7003C9.6417 15.7003 9.28331 15.5635 9.0099 15.2901Z"
              fill="#281B65"
            />
          </g>
          <defs>
            <clipPath id="clip0_2778:1584">
              <rect width="20" height="20" fill="white" transform="translate(20) rotate(90)" />
            </clipPath>
          </defs>
        </svg>
      </ArrowContainer>
    );
  };

  const getCalifications = (value) => {
    return (
      <>
        <IconEvaluation
          disabled={value === 0}
          className={value >= 1 ? "icon-heart-filled" : "icon-heart-outlined"}
        ></IconEvaluation>
        <IconEvaluation
          disabled={value === 0}
          className={value >= 2 ? "icon-heart-filled" : "icon-heart-outlined"}
        ></IconEvaluation>
        <IconEvaluation
          disabled={value === 0}
          className={value >= 3 ? "icon-heart-filled" : "icon-heart-outlined"}
        ></IconEvaluation>
        <IconEvaluation
          disabled={value === 0}
          className={value >= 4 ? "icon-heart-filled" : "icon-heart-outlined"}
        ></IconEvaluation>
        <IconEvaluation
          disabled={value === 0}
          className={value >= 5 ? "icon-heart-filled" : "icon-heart-outlined"}
        ></IconEvaluation>
      </>
    );
  };

  const itemAsync = (item, element, user, sync = false, index) => {
    const [
      namePlanning,
      percent_pending,
      percent_success,
      percent_recovery,
      percent_lost,
      pendientes,
      realizadas,
      recovery,
      perdidas,
      starts,
      complex,
      diff,
      // enabledBtn,
    ] = item;

    return (
      <ItemDetail key={index}>
        <Row cols={numCols - (sizeColum.patient)}>
          <Col md={sizeColum.planning}>
            <PlanningContainer
              statusColor={getColorStatus(
                percent_success,
                percent_pending,
                percent_lost,
                percent_recovery
              )}
            >
              <p>{namePlanning}</p>
            </PlanningContainer>
          </Col>
          <Col md={sizeColum.complex}>
            <ItemContainer>
              <ArrowSVG>
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                ></svg>
              </ArrowSVG>
              <p>
                <b>{complex}%</b>
              </p>
            </ItemContainer>
          </Col>
          <Col md={sizeColum.progress}>
            <ItemContainer>
              {progressBar(
                { value: percent_success, label: realizadas, color: element.color_success },
                { value: percent_recovery, label: recovery, color: element.color_recovery },
                { value: percent_lost, label: perdidas, color: element.color_lost },
                { value: percent_pending, label: pendientes, color: element.color_pending }
              )}
            </ItemContainer>
          </Col>
          <Col md={sizeColum.evaluation}>
            <ItemContainer>{getCalifications(starts)}</ItemContainer>
          </Col>
          <Col md={sizeColum.prox}>
            <ItemContainer>
              {sync && diff ? (
                <>
                  <button
                    className="btn-table"
                    onClick={(v) => history.push(`/treatment/schedule/add/${user}`)}
                  >
                    {diff} {t("days")}
                  </button>
                </>
              ) : (
                sync && (
                  <button
                    className="btn-table"
                    onClick={(v) => history.push(`/treatment/schedule/add/${user}`)}
                  >
                    {t("schedule")}
                  </button>
                )
              )}
            </ItemContainer>
          </Col>
          <Col md={sizeColum.more}>
            <ItemContainer></ItemContainer>
          </Col>
        </Row>
      </ItemDetail>
    );
  };

  const notFound = () => {
    return (
      <>
        <Row>
          <Col md={12}>
            <PlanningContainer>
              <p>{t("no_data")}</p>
            </PlanningContainer>
          </Col>
        </Row>
      </>
    );
  };

  const onClick = (index) => {
    const data = Object.assign([], dataPatients);
    data[index]["open"] = !data[index]["open"];
    setDataPatients(data);
  };

  const getColorStatus = (success, pending, lost, recovery) => {
    const data = [success, pending, lost, recovery];
    const max = Math.max(...data);
    if (max === success) return "#49C39E";
    else if (max === pending) return "#4666DD";
    else if (max === lost) return "#FD0202";
    else if (max === recovery) return "#FF7F23";
    else return "#ccc";
  };

  const getArrowStatusCompetion = (bool) => {
    return bool ? (
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.00001 0L17.6603 15H0.339752L9.00001 0Z" fill="#49C39E" />
      </svg>
    ) : (
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.99999 15L0.339737 1.51421e-06L17.6602 0L8.99999 15Z" fill="#FD0202" />
      </svg>
    );
  };

  const column = (e, i, key) => {
    return (
      <Accordeon active={e.open} heightOpen={e.total_planning * 60 + 300} key={key}>
        <Row style={{ cursor: "pointer" }} cols={numCols} onClick={() => onClick(i)}>
          <Col md={sizeColum.patient}>
            <ItemContainer>
              <div className="mr-2">{arrow(e.open)}</div>
              <p>{e.name}</p>
            </ItemContainer>
          </Col>
          <Col md={sizeColum.planning}>
            <PlanningContainer
              statusColor={getColorStatus(
                e.percent_success,
                e.percent_pending,
                e.percent_lost,
                e.percent_recovery
              )}
            >
              <p>{t('follow.plans')}</p>
            </PlanningContainer>
          </Col>
          <Col md={sizeColum.complex}>
            <ItemContainer>
              <ArrowSVG>{getArrowStatusCompetion(e.arrowUp)}</ArrowSVG>
              <p>
                <b>{e.completion}%</b>
              </p>
            </ItemContainer>
          </Col>
          <Col md={sizeColum.progress}>
            <ItemContainer>
              {progressBar(
                { value: e.percent_success, label: e.all_realizadas, color: e.color_success },
                { value: e.percent_recovery, label: e.all_recuperadas, color: e.color_recovery },
                { value: e.percent_lost, label: e.all_perdidas, color: e.color_lost },
                { value: e.percent_pending, label: e.all_pendiente, color: e.color_pending }
              )}
            </ItemContainer>
          </Col>
          <Col md={sizeColum.evaluation}>
            <ItemContainer>{getCalifications(e.starts)}</ItemContainer>
          </Col>
          {/* <Col md={sizeColum.prox}>
            <ItemContainer>
              <button onClick={(v) => history.push(`/treatment/schedule/add/${e._id}`)} className="btn-table">
                {t("schedule")}
              </button>
            </ItemContainer>
          </Col> */}
          <Col md={sizeColum.more}>
            <ItemContainer>
              <button
                onClick={(v) => history.push(`/clinicaldata/${e._id}`)}
                className="btn-table-invert"
              >
                {t("see_more")}
              </button>
            </ItemContainer>
          </Col>
        </Row>
        <Row cols={numCols}>
          <Col md={sizeColum.patient}></Col>
          <Col md={numCols - (sizeColum.patient)}>
            <TitlePlans>{t('follow.SynchronousPlans')}</TitlePlans>
            {e.sync && e.sync.length > 0
              ? e.sync.map((a, index) => itemAsync(a, e, e._id, true, index))
              : notFound()}
            <TitlePlans>{t('follow.AsynchronousPlans')}</TitlePlans>
            {e.async && e.async.length > 0
              ? e.async.map((a, index) => itemAsync(a, e, e._id, false, index))
              : notFound()}
          </Col>
        </Row>
      </Accordeon>
    );
  };

  const fetchData = (value = "", pageNum = 1) => {
    const from =
      fromDate != null
        ? new Date(fromDate.year, fromDate.month - 1, fromDate.day - 1)
        : new Date(getDefaultDateFrom());
    const to =
      toDate != null
        ? new Date(toDate.year, toDate.month - 1, toDate.day)
        : new Date(getDefaultDateTo());
  
    setFromDate({
      year: from.getFullYear(),
      month: from.getMonth() + 1,
      day: from.getDate() + 1,
    });
    setToDate({
      year: to.getFullYear(),
      month: to.getMonth() + 1,
      day: to.getDate(),
    });
  
    setCurrentSearchTerm(value);
    setLoad(true);
  
    Ctrl.filterPatients(pageNum, limit, { from, to, value })
      .then((res) => {
        setDataPatients(res.data);
        setTotalPages(res.totalPages);
        setPage(pageNum);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
  

  const debounceRef = useRef();
  const [searchValue, setSearchValue] = useState("");
  const onQueryChanged = (event) => {
    const newValue = event;
    setSearchValue(newValue);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      fetchData(newValue);
    }, 1000);
  };

  const SearchPacient = () => {
    return (
      <SearchPatientStyled>
        <SearchBar placeholder={t("search")} onChange={(e) => onQueryChanged(e.target.value)} value={searchValue} />
        <Button
          type="ghost"
          icon={<FilterIcon />}
          label={t("filters")}
          width="102px"
          onClick={handleOpenSidebar}
        />
      </SearchPatientStyled>
    );
  };

  const HeaderTable = () => {
    return (
      <>
        <Header>
          <Row cols={numCols}>
            <Col md={sizeColum.patient}>
              <HeaderContainer>
                <p>{t("follow.patient")}</p> {iconArrow("name", sortName, setSortName)}
              </HeaderContainer>
            </Col>
            <Col md={sizeColum.planning}>
              <HeaderContainer>
                <p>{t("follow.plan")}</p>
              </HeaderContainer>
            </Col>
            <Col md={sizeColum.complex}>
              <HeaderContainer>
                <p>{t("follow.completion")}</p>{" "}
                {iconArrow("session", sortSessions, setSortSessions)}
              </HeaderContainer>
            </Col>
            <Col md={sizeColum.progress}>
              <HeaderContainer>
                <p>{t("follow.compliance")}</p> {iconArrow("percent", sortPercent, setSortPercent)}
              </HeaderContainer>
            </Col>
            <Col md={sizeColum.evaluation}>
              <HeaderContainer>
                <p>{t("follow.evaluation")}</p>{" "}
                {iconArrow("evaluations", sortEvaluations, setSortEvaluations)}
              </HeaderContainer>
            </Col>
            {/* <Col md={sizeColum.prox}>
              <HeaderContainer>
                <p>{t("follow.next_session")}</p>{" "}
                {iconArrow("nextSession", sortNextSession, setSortNextSession)}
              </HeaderContainer>
            </Col> */}
            <Col md={sizeColum.more}>
              <HeaderContainer>
                <p>{t("follow.see_more")}</p>
              </HeaderContainer>
            </Col>
          </Row>
        </Header>
      </>
    );
  };

  const handleClearFilter = () => {
    const currentDate = new Date();
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(currentDate.getDate() - 90);

    setFromDate({year: ninetyDaysAgo.getFullYear(), month: ninetyDaysAgo.getMonth() + 1, day: ninetyDaysAgo.getDate()});
    setToDate({year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()});
    setMinimumDate(ninetyDaysAgo);
    setMaximumDate(currentDate);
    handleCloseSidebar();
    setLoad(true);

    const formattedCurrentDate = currentDate.toISOString().split('T')[0] + "T03:00:00.000Z";
    const formattedNinetyDaysAgo = ninetyDaysAgo.toISOString().split('T')[0] + "T03:00:00.000Z";

    Ctrl.getData(page, limit, {
      from: formattedNinetyDaysAgo,
      to: formattedCurrentDate,
    })
      .then((res) => {
        setDataPatients(res.data);
        setAllDataPatients(res.data);
        setLoad(false);
        setTotalPages(res.totalPages);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    setPage(1);
    getPatientsData(1, fromDate, toDate, searchValue);
    handleCloseSidebar();
  };

  const sidebarFilter = {
    title: (
      <>
        <FilterIcon /> {t("filters")}
      </>
    ),
    content: (
      <SidebarContent>
        <div className="form">
          <p className="mrgn-top-30">{t("date_range")}</p>

          <label>{t("from")}</label>
          <DatePickerComponent
            height="36px"
            placeholder=""
            range={false}
            value={fromDate}
            onChange={(val) => {
              handleDatePickerChange(val, setFromDate, setMinimumDate);
            }}
            maximumDate={maximumDate}
            locale={currentLanguage}
          />

          <label>{t("to")}</label>
          <DatePickerComponent
            height="36px"
            placeholder=""
            range={false}
            value={toDate}
            onChange={(val) => handleDatePickerChange(val, setToDate, setMaximumDate)}
            minimumDate={minimumDate ? minimumDate : undefined}
            locale={currentLanguage}
          />
          <div style={{ marginTop: "20px" }}>
            <Button width="100%" type="ghost" label={t("last_seven_days")} onClick={() => {
              const currentDate = new Date();
              const oneWeekAgo = new Date();
              oneWeekAgo.setDate(currentDate.getDate() - 7);
              setFromDate({year: oneWeekAgo.getFullYear(), month: oneWeekAgo.getMonth() + 1, day: oneWeekAgo.getDate()});
              setToDate({year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()});
            }}/>
            <div style={{ marginTop: "10px" }}></div>
            <Button width="100%" type="ghost" label={t("last_thirty_days")} onClick={() => {
              const currentDate = new Date();
              const oneMonthAgo = new Date();
              oneMonthAgo.setMonth(currentDate.getMonth() - 1);
              setFromDate({year: oneMonthAgo.getFullYear(), month: oneMonthAgo.getMonth() + 1, day: oneMonthAgo.getDate()});
              setToDate({year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()});
            }}/>
            <div style={{ marginTop: "10px" }}></div>
            <Button width="100%" type="ghost" label={t("last_six_months")} onClick={() => {
              const currentDate = new Date();
              const twoMonthAgo = new Date();
              twoMonthAgo.setMonth(currentDate.getMonth() - 6);
              setFromDate({year: twoMonthAgo.getFullYear(), month: twoMonthAgo.getMonth() + 1, day: twoMonthAgo.getDate()});
              setToDate({year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()});
            }}/>
          </div>
        </div>

        <Button width="100%" label={t("apply_filters")} onClick={handleSubmit} />
        <Button
          width="100%"
          type="ghost"
          label={t("clear_filters")}
          onClick={handleClearFilter}
        />
      </SidebarContent>
    ),
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <CardLayoutPlatform
            padding={"0"}
            loading={load}
            title={t("title")}
            showSidebar={showSidebar}
            handleCloseSidebar={handleCloseSidebar}
            sidebarTitle={sidebarFilter.title}
            sidebarContent={sidebarFilter.content}
            header={SearchPacient()}
          >
            {HeaderTable()}
            {dataPatients.map((e, i) => column(e, i, i))}
            <Pagination 
              currentPage={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </CardLayoutPlatform>
        </Col>
      </Row>
    </>
  );
};

export default FlowView;

const SearchPatientStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 102px;
  place-items: center;
  padding: 10px 14px;
  grid-gap: 10px;

  & > div:first-child {
    width: 100%;
  }
`;