import { useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import API from "../services/api";

const api = new API();

export const useGetResources = () => {
    const [serviceResources, setServiceResources] = useState([])

    const resourcesQuery = useQuery({
        queryKey: ["resources"],
        queryFn: () => api.getResources()
            .then((res) => {

                setServiceResources(
                    res.data.map((e) => {
                        e["tipo"] = e["type"] ? e["type"]["name"] : "-";
                        e["tipo_id"] = e["type"] ? e["type"]["_id"] : "-";
                        e["sucursal"] = e["suc"] ? e["suc"]["name"] : "-";
                        e["sucursal_id"] = e["suc"] ? e["suc"]["_id"] : "-";
                        return e;
                    })
                )

                return res.data
            }),
        refetchOnWindowFocus: false,
    });

    const { mutate: addNewResource, isLoading: createLoading } = useMutation(
        ( newResource ) => {
            return api.createResource( newResource )
        },
        {
            onSuccess: async () => resourcesQuery.refetch()
        }
    );
    
    const { mutate: editResource, isLoading: editLoading } = useMutation(
        ( { id, data } ) => {
            return api.updateResource( id, data )
        },
        {
            onSuccess: async () => resourcesQuery.refetch()
        }
    );

    const { mutate: deleteResource, isLoading: deleteLoading } = useMutation(
        ( id ) => {
            return api.deleteResource( id )
        },
        {
            onSuccess: async () => resourcesQuery.refetch()
        }
    );

    const customLoading = createLoading || editLoading || deleteLoading;


    return {
        resourcesQuery,
        addNewResource,
        editResource,
        deleteResource,
        serviceResources,
        customLoading,
    }
}
