import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from '../grilla/grid.component'
import { WrapperTabFlow, HeaderWrapperTabFlow, WrapperTabFlowItemHeader, WrapperTabFlowItem, FlexContent, PlanningStatusName, ComplianceTotalBar, ComplianceBarProgess, SessionStatusName, DropdownMain, WrapperTabFlowItemSession, ButtonMore } from './tableflow.style'


// const getColor = (data) => {
//     switch (data.status) {
//         case 1: return "#39C4A5";
//         case 2: return "#FD0202";
//         case 4: return "#FF7F23";
//         case 3: return "#4666DD";
//         case 0: return "#4666DD";
//         default: return "#4666DD";
//     }
// }

// const getEstado = (data) => {
//     switch (data.status) {
//         case 1: return "Realizada";
//         case 2: return "Perdida";
//         case 4: return "Recuperada";
//         case 3: return "Pendiente";
//         case 0: return "Pendiente";
//         default: return "Pendiente";
//     }
// }


const getMaxColor = (done, pending, lost, recovery) => {
    const arr = [{ type: "done", amount: done }, { type: "pending", amount: pending }, { type: "lost", amount: lost }, { type: "recovery", amount: recovery }]
    var al = arr.length;
    var max = arr[al - 1];
    while (al--) {
        if (arr[al].amount > max.amount) max = arr[al]
    }

    switch (max.type) {
        case "done": return "#39C4A5";
        case "lost": return "#FD0202";
        case "recovery": return "#FF7F23";
        case "pending": return "#4666DD";
        default: return "#4666DD";
    }
};

const getCalifications = (value) => {
    return (
        <>
            {value >= 1 ? <span className="icon-heart-filled color-secundario fz-18 px-1"></span> : <span className="icon-heart-outlined color-secundario fz-18 px-1"></span>}
            {value >= 2 ? <span className="icon-heart-filled color-secundario fz-18 px-1"></span> : <span className="icon-heart-outlined color-secundario fz-18 px-1"></span>}
            {value >= 3 ? <span className="icon-heart-filled color-secundario fz-18 px-1"></span> : <span className="icon-heart-outlined color-secundario fz-18 px-1"></span>}
            {value >= 4 ? <span className="icon-heart-filled color-secundario fz-18 px-1"></span> : <span className="icon-heart-outlined color-secundario fz-18 px-1"></span>}
            {value >= 5 ? <span className="icon-heart-filled color-secundario fz-18 px-1"></span> : <span className="icon-heart-outlined color-secundario fz-18 px-1"></span>}
        </>
    )
}

const ButtonDroopDown = (props) => {
    return !props.active ? <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="2.02835" height="10.1418" rx="0.9" transform="matrix(0.673159 -0.739498 0.673159 0.739497 0 1.5)" fill="black" />
        <rect width="2.02835" height="10.1418" rx="0.9" transform="matrix(0.673159 0.739497 -0.673159 0.739498 12.2888 0.000366211)" fill="black" />
    </svg>
        : <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="2.02835" height="10.1418" rx="0.9" transform="matrix(-0.673159 0.739498 -0.673159 -0.739498 13.6543 7.50024)" fill="black" />
            <rect width="2.02835" height="10.1418" rx="0.9" transform="matrix(-0.673159 -0.739498 0.673159 -0.739498 1.36548 8.99976)" fill="black" />
        </svg>


}

ButtonDroopDown.defaultProps = {
    active: false
}

const PercentBarProgress = props => {
    const ref = useRef();
    const [done, setDone] = useState(0);
    const [lost, setLost] = useState(0);
    const [pending, setPending] = useState(0);
    const [recovery, setRecovery] = useState(0);

    useEffect(() => {
        setDone(resizePercent(props.done));
        setLost(resizePercent(props.lost));
        setPending(resizePercent(props.pending));
        setRecovery(resizePercent(props.recovery));
    }, [props])

    const resizePercent = (percent) => {
        if (ref.current && ref.current.clientWidth) {
            const total = ((ref.current && ref.current.clientWidth * 3) / 100) * percent;
            return total;
        }
        else return 0;
    }

    return (
        <>
            <ComplianceTotalBar ref={ref}>
                <ComplianceBarProgess percent={done} color="#39C4A5">
                    {props.done === 0 ? null : <div>
                        <p className="amount">{props.amountDone}</p>
                        <p>{props.done}%</p>
                    </div>}
                </ComplianceBarProgess>
                <ComplianceBarProgess percent={recovery} color="#FF7F23">
                    {props.recovery === 0 ? null : <div>
                        <p className="amount">{props.amountRecovery}</p>
                        <p>{props.recovery}%</p>
                    </div>}
                </ComplianceBarProgess>
                <ComplianceBarProgess percent={lost} color="#FD0202">
                    {props.lost === 0 ? null : <div>
                        <p className="amount">{props.amountLost}</p>
                        <p>{props.lost}%</p>
                    </div>}
                </ComplianceBarProgess>
                <ComplianceBarProgess percent={pending} color="#4666DD">
                    {props.pending === 0 ? null : <div>
                        <p className="amount">{props.amountPending}</p>
                        <p>{props.pending}%</p>
                    </div>}
                </ComplianceBarProgess>
            </ComplianceTotalBar>
        </>
    )
}

PercentBarProgress.defaultProps = {
    pending: 0,
    lost: 0,
    done: 0,
    recovery: 0,
    amountDone: 0,
    amountRecovery: 0,
    amountLost: 0,
    amountPending: 0
}

const SessionsTabFlow = props => {
    return (
        <>
            <Row cols={12} gap={8}>
                <Col xs={2}></Col>
                <Col xs={4}>
                    <WrapperTabFlowItemSession>
                        <FlexContent justifyContent="center" alignItems="center">
                            <SessionStatusName color={getMaxColor(props.data && props.data.percent_success_session, props.data && props.data.percent_pending_session, props.data && props.data.percent_lost_session, props.data && props.data.percent_recover_session)}>
                                {props.data && props.data.name}
                            </SessionStatusName>
                        </FlexContent>
                    </WrapperTabFlowItemSession>
                </Col>
                <Col xs={2}>
                    <WrapperTabFlowItemSession>
                        <FlexContent justifyContent="center" alignItems="center">
                            <PercentBarProgress
                                done={props.data && props.data.percent_success_session}
                                amountDone={props.data && props.data.realizadas}
                                lost={props.data && props.data.percent_lost_session}
                                amountLost={props.data && props.data.perdidas}
                                pending={props.data && props.data.percent_pending_session}
                                amountPending={props.data && props.data.pendientes}
                                recovery={props.data && props.data.percent_recover_session}
                                amountRecovery={props.data && props.data.recuperadas}
                            />
                        </FlexContent>
                    </WrapperTabFlowItemSession>
                </Col>
                <Col xs={1}>
                    <WrapperTabFlowItem>
                        <FlexContent justifyContent="center" alignItems="center">
                            <p style={{ margin: 0, marginRight: 8 }}>Total:</p>
                            <p style={{ margin: 0, fontWeight: 600 }}>{props.data && props.data.total}</p>
                        </FlexContent>
                    </WrapperTabFlowItem>
                </Col>
                <Col xs={2}>
                    <WrapperTabFlowItemSession>
                        <FlexContent justifyContent="center" alignItems="center">
                            {getCalifications(props.data && props.data.ratingSession)}
                        </FlexContent>
                    </WrapperTabFlowItemSession>
                </Col>
            </Row>
        </>
    )
}

const PLanningsTabFlow = props => {

    const [active, setActive] = useState(false);

    return (
        <>
            <div>
                <Row cols={12} gap={8}>
                    <Col xs={1}></Col>
                    <Col xs={4}>
                        <WrapperTabFlowItem>
                            <FlexContent justifyContent="center" alignItems="center">
                                <PlanningStatusName color={getMaxColor(props.data && props.data.percent_success, props.data && props.data.percent_pending, props.data && props.data.percent_lost, props.data && props.data.percent_recover)}>
                                    {props.data && props.data.name}
                                </PlanningStatusName>
                            </FlexContent>
                        </WrapperTabFlowItem>
                    </Col>
                    <Col xs={3}>
                        <WrapperTabFlowItem>
                            <FlexContent justifyContent="center" alignItems="center">
                                <PercentBarProgress
                                    done={props.data && props.data.percent_success}
                                    amountDone={props.data && props.data.realizadas}
                                    lost={props.data && props.data.percent_lost}
                                    amountLost={props.data && props.data.perdidas}
                                    pending={props.data && props.data.percent_pending}
                                    amountPending={props.data && props.data.pendientes}
                                    recovery={props.data && props.data.percent_recover}
                                    amountRecovery={props.data && props.data.recuperadas}
                                />
                            </FlexContent>
                        </WrapperTabFlowItem>
                    </Col>
                    <Col xs={1}>
                        <WrapperTabFlowItem>
                            <FlexContent justifyContent="center" alignItems="center">
                                <p style={{ margin: 0, marginRight: 8 }}>Total:</p>
                                <p style={{ margin: 0, fontWeight: 600 }}>{props.data && props.data.total}</p>
                            </FlexContent>
                        </WrapperTabFlowItem>
                    </Col>
                    <Col xs={2}>
                        <WrapperTabFlowItem>
                            <FlexContent justifyContent="center" alignItems="center">
                                {getCalifications(props.data && props.data.rating)}
                            </FlexContent>
                        </WrapperTabFlowItem>
                    </Col>
                    <Col xs={1}>
                        <WrapperTabFlowItem>
                            <FlexContent justifyContent="center" alignItems="center">
                                <ButtonMore onClick={v => setActive(!active)}>
                                    <ButtonDroopDown active={active} />
                                </ButtonMore>
                            </FlexContent>
                        </WrapperTabFlowItem>
                    </Col>
                </Row>
            </div>

            <div >
                <DropdownMain active={active}>
                    {
                        props.data && props.data.data.map(e => {
                            return (
                                <SessionsTabFlow data={e} />
                            )
                        })
                    }
                </DropdownMain>
            </div>
        </>
    )
}

PLanningsTabFlow.defaultProps = {
    data: {
        data: []
    }
}

const TotalDetailTabFlow = props => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (props.onActive !== undefined) props.onActive(active);
    }, [active])

    return (
        <>
            <Row cols={12} gap={8}>
                <Col xs={4}>
                    <WrapperTabFlowItem>
                        <FlexContent justifyContent="flex-start" alignItems="center">
                            <PlanningStatusName color={getMaxColor(props.data && props.data.percent_success, props.data && props.data.percent_pending, props.data && props.data.percent_lost, props.data && props.data.percent_recover)}>
                                Total Planificaciones
                            </PlanningStatusName>
                        </FlexContent>
                    </WrapperTabFlowItem>
                </Col>
                <Col xs={4}>
                    <WrapperTabFlowItem>
                        <FlexContent justifyContent="center" alignItems="center">
                            <PercentBarProgress
                                done={props.data && props.data.percent_success}
                                amountDone={props.data && props.data.all_realizadas}
                                lost={props.data && props.data.percent_lost}
                                amountLost={props.data && props.data.all_perdidas}
                                pending={props.data && props.data.percent_pending}
                                amountPending={props.data && props.data.all_pendientes}
                                recovery={props.data && props.data.percent_recover}
                                amountRecovery={props.data && props.data.all_recuperadas}
                            />
                        </FlexContent>
                    </WrapperTabFlowItem>
                </Col>
                <Col xs={1}>
                    <WrapperTabFlowItem>
                        <FlexContent justifyContent="center" alignItems="center">
                            <p style={{ margin: 0, marginRight: 8 }}>Total: </p>
                            <p style={{ margin: 0, fontWeight: 600 }}>{props.data && props.data.total}</p>
                        </FlexContent>
                    </WrapperTabFlowItem>
                </Col>
                <Col xs={2}>
                    <WrapperTabFlowItem>
                        <FlexContent justifyContent="center" alignItems="center">
                            {getCalifications(props.data && props.data.rating)}
                        </FlexContent>
                    </WrapperTabFlowItem>
                </Col>
                <Col xs={1}>
                    <WrapperTabFlowItem>
                        <FlexContent justifyContent="center" alignItems="center">
                            <ButtonMore onClick={() => setActive(!active)}>
                                <ButtonDroopDown active={active} />
                            </ButtonMore>
                        </FlexContent>
                    </WrapperTabFlowItem>
                </Col>
            </Row>
        </>
    )
}

const ColTabFlow = (props) => {

    const [active, setActive] = useState(false);

    return (
        <>
            <div>
                <TotalDetailTabFlow data={props.data} onActive={v => setActive(v)} />
            </div>
            <DropdownMain active={active}>
                {props.data && props.data.plannings && props.data.plannings.map(e => {
                    return (
                        <PLanningsTabFlow data={e} />
                    )
                })}
            </DropdownMain>

        </>
    )
}

ColTabFlow.defaultProps = {
    data: {}
}


const HeaderTableFlow = (props) => {
    return (
        <>
            <HeaderWrapperTabFlow>
                <Row cols={12} gap={8}>
                    <Col xs={4}>
                        <WrapperTabFlowItemHeader>
                            <FlexContent justifyContent="flex-start" alignItems="center">
                                Planificaciones y sesiones
                            </FlexContent>
                        </WrapperTabFlowItemHeader>
                    </Col>
                    <Col xs={4}>
                        <WrapperTabFlowItemHeader>
                            <FlexContent justifyContent="flex-end" alignItems="center">
                                Cumplimiento
                            </FlexContent>
                        </WrapperTabFlowItemHeader>
                    </Col>
                    <Col xs={1}>
                        <WrapperTabFlowItemHeader>
                            <FlexContent justifyContent="center" alignItems="center">
                                Total
                            </FlexContent>
                        </WrapperTabFlowItemHeader>
                    </Col>
                    <Col xs={2}>
                        <WrapperTabFlowItemHeader>
                            <FlexContent justifyContent="center" alignItems="center">
                                Satisfacción usuario
                            </FlexContent>
                        </WrapperTabFlowItemHeader>
                    </Col>
                    <Col xs={1}>
                        <WrapperTabFlowItemHeader>
                            <FlexContent justifyContent="center" alignItems="center">
                                Ver Más
                            </FlexContent>
                        </WrapperTabFlowItemHeader>
                    </Col>
                </Row>
            </HeaderWrapperTabFlow>
        </>
    )
}

const TableFlow = (props) => {
    return (
        <>
            <div style={{ boxShadow: '0px 2px 20px rgb(221 221 221 / 30%)' }}>
                <HeaderTableFlow />
                <WrapperTabFlow>
                    <ColTabFlow data={props.data} />
                </WrapperTabFlow>
            </div>
        </>
    )
}

TableFlow.defaultProps = {
    data: {}
}

export default TableFlow;