/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { setToken } from "../lib/router";
import "../assets/css/loginStyle.css";
import "../assets/img/icomoon/style.css";
import { Grid, TextField, Hidden } from "@material-ui/core";
import ModalSimple from "../components/modalSimple";
import Notifications from "../components/notifications/index";
import { connect } from "../store";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import API from "../services/api";

const api = new API();

const Login = ({ notify, history }) => {
  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");
  const [recovery, setRecovery] = useState("");
  const [open, setOpen] = useState(false);
  const [cookies] = useCookies(["tenantId"]);
  const { t, i18n } = useTranslation("login");

  const handleKeypress = (e) => {
    if (e.key === "Enter") sign();
  };

  const sign = async () => {
    const form = new FormData();
    form.append("rut", mail);
    form.append("password", pass);
    form.append("tenant", cookies.tenant);
    // form.append("tenant", "vpkPxUlSSLzuk7Hs8SSF");

    try {
      const { success, token } = await api.login(form);

      if (!success) {
        notify({
          type: "error",
          title: t("login_error"),
          text: t("wrong_username_password"),
          time: 3000,
        });
        return;
      }

      setToken(token);
      history.push("/");
    } catch (error) {
      notify({
        type: "error",
        title: t("login_error"),
        text: t("an_error_has_ocurred"),
        time: 3000,
      });
    }
  };

  const recoverySend = async () => {
    if (cookies.tenant === undefined) {
      cookies.tenant = "agF";
    }

    const form = new FormData();
    form.append("rut", recovery);
    form.append("tenant", cookies.tenant);
    return api
      .recovery(form)
      .then((res) => {
        if (res.success)
          notify({
            type: "success",
            title: t("success"),
            text: t("recovery_email_sent"),
            time: 3000,
          });
        else
          notify({
            type: "error",
            title: "Error",
            text: t("user_does_not_exist"),
            time: 3000,
          });
      })
      .catch((err) =>
        notify({
          type: "error",
          title: "Error",
          text: t("an_error_has_ocurred"),
        })
      );
  };

  return (
    <div>
      <Grid className="mt-5" container direction="row" justifyContent="center" alignItems="center">
        <Hidden mdDown>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="welcome shadow-left bg-primario pt-130 p-3"
          >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <h2 className="text-white text-center w-100">
                <strong>{t("welcome")}</strong>
              </h2>
              <p className="text-white fz-13">{t("subtitle")}</p>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid
                item
                xs={4}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <p className="m-0 fz-60 color-secundario">
                  <strong>#</strong>
                </p>
              </Grid>
              <Grid
                item
                xs={8}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <p className="w-100 text-white fz-18 fw-500" style={{ margin: "-4px 0" }}>
                  {t("hashtag1")}
                </p>
                <p
                  className="w-100 text-white fz-26 fw-500"
                  style={{ margin: "-8px 0", letterSpacing: "1.4px" }}
                >
                  {t("hashtag2")}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          className="form-login text-center shadow-right"
        >
          <Grid container direction="row" justifyContent="flex-end" className="pt-2 pointer hover">
            <Grid
              item
              xs={1}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {i18n.language === "es" ? (
                <p
                  className="m-0"
                  onClick={() => {
                    i18n.changeLanguage("en");
                  }}
                >
                  Es
                </p>
              ) : (
                <p
                  className="m-0"
                  onClick={() => {
                    i18n.changeLanguage("es");
                  }}
                >
                  En
                </p>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <span className="icon-arrow-point-to-right color-secundario fz-10"></span>
            </Grid>
          </Grid>

          <Grid className="w-90">
            <h5 className="color-primario mb-4">{t("title")}</h5>
            <Grid>
              <TextField
                className="w-100 mb-5 qa-login-username"
                label={t("labelRut")}
                id="standard-basic"
                value={mail}
                onChange={(value) => setMail(value.target.value)}
              />
            </Grid>

            <Grid>
              <TextField
                className="w-100 qa-login-password"
                type="password"
                label={t("labelPass")}
                id="standard-basic"
                value={pass}
                onKeyPress={handleKeypress}
                onChange={(value) => setPass(value.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <button className="btn-tf mt-5 mb-2" onClick={sign}>
                {t("btn")}
              </button>
            </Grid>
            <Grid item xs={12}>
              <a className="hover fz-13 color-primario pointer" onClick={() => setOpen(true)}>
                {t("message")}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalSimple
        textAction={t("accept")}
        textCancel={t("cancel")}
        open={open}
        title={t("recovery")}
        cancel={() => setOpen(false)}
        action={() => {
          recoverySend();
          setOpen(false);
        }}
      >
        <p className="fz-14">{t("messageRecovery")}</p>
        <div className="mx-auto w-100 py-3">
          <input
            type="text"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder={t("rut")}
            onChange={(val) => setRecovery(val.target.value)}
          />
        </div>
      </ModalSimple>
      <Notifications />
    </div>
  );
};

export default connect(Login);
