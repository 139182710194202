import styled from "styled-components";

export const ColorDiv = styled.div`
    background-color: ${ ({ bg }) => bg ? bg : "#fff" };
    border-radius: 4px;
    height: 20px;
    width: 20px;
    margin: 0 auto;
`;

export const IconsContainer = styled.div`
  & > svg {
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 100px repeat(2, minmax(100px, 200px));
  margin: 20px 0;
  place-items: center;
  padding: 0 12px;

  & p {
    margin: 0;
  }

  & > div {
    width: 100%;
  }
`;