import styled from "styled-components";
import { TableServices } from "trainfes-components-library";

export const TableHeader = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 100px repeat(2, minmax(100px, 200px));
  margin: 20px 0;
  place-items: center;
  padding: 0 12px;

  & p {
    margin: 0;
  }

  & > div {
    width: 100%;
  }
`;

export const IconsContainer = styled.div`
  & > svg {
    margin: 0 12px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const TableStyled = styled(TableServices)``;
