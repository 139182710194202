export const initialState = {
    isEditing: false,
    idCurrent: null, // id selected to Edit or Delete.

    isPosting: false,
  
    name: "",
    representative: "",
    
    country:    "",
    address:    "",
    address2:   "",
    commune:    "",
    region:     "",
    stateAddress: "",
    
    email: "",

    phone_one:  "",
    phone_two:  "",
    phone_three: "",
  
    subsidiary: [],
    admins: [],
  
    services: [],
    specialities: [],
  
    idToDelete: null,
  
    //   Modals
    removeModal: false,
    newSubsidiaryModal: false,
  
    //   Filters
    dropdownFilter: "name",
    inputFilter: "",
  
    filteredSubsidiaries: [],
  };
  
  export const CLEAR_STATE = "[Subsidiary] Clear state to initial";
  export const EDITING_STATE = "[Subsidiary] Set editing state";
  export const SET_FIELDS = "[Subsidiary] Set fields";
  export const SET_INPUT_FIELDS = "[Subsidiary] Set inputs fields";
  export const SET_SUBSIDIARY = "[Subsidiary] Set Subsidiary";
  export const SET_SPECIALITIES = "[Subsidiary] Set Specialities";
  export const SET_OPEN_MODAL = "[Subsidiary] Set Open modals";

  
  export const reducer = (state, action) => {
    switch (action.type) {
      case EDITING_STATE:
        return {
          ...state,
          isEditing: true,
          idCurrent: action.payload._id,

          newSubsidiaryModal: true,

          name: action.payload.name,
          representative: { label: `${action.payload.representative.name} ${action.payload.representative.lastname}` , value: action.payload.representative._id },
          stateAddress: action.payload.address, 
          country: action.payload.country     || "",
          address: action.payload.address     || "",
          address2: action.payload.address2   || "",
          commune: action.payload.comune      || "",
          region: action.payload.region       || "",
          email: action.payload.email         || "",
          phone_one: action.payload.phone1    || "",
          phone_two: action.payload.phone2    || "",
          phone_three: action.payload.phone3  || "",
          specialities: action.payload.specialities.map(({ name, _id }) => ({ label: name, value: _id  })) || [],
        };
  
      case CLEAR_STATE:
        return {
          ...state,
          isEditing: false,
          idCurrent: null,
          isPosting: false,
          name: "",
          representative: "",
          country: "",
          address: "",
          address2: "",
          stateAddress: "",
          commune: "",
          region: "",
          email: "",
          phone_one: "",
          phone_two: "",
          phone_three: "",
          idToDelete: null,
          newSubsidiaryModal: false,
          removeModal: false,
          createLoading: false,
          // dropdownFilter: "name",
          // inputFilter: "",
          specialities: []
        };
  
      case SET_FIELDS:
        return {
          ...state,
          [action.payload.field]: action.payload.value,
        };
  
      case SET_INPUT_FIELDS:
        return {
          ...state,
          [action.payload.input]: action.payload.value,
        };
  
      case SET_SPECIALITIES:
        return {
          ...state,
          specialities: action.payload,
        };
  
      case SET_OPEN_MODAL:
        return {
          ...state,
          [action.payload.modal]: action.payload.value,
        };
  
      default:
        return state;
    }
  };
  