import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown, Button } from "trainfes-components-library";
import { connect } from "../../../../store";
import { SidebarContent } from "../../../CardLayoutPlatform/styles";

const FilterSidebar = ({
  store,
  t,
  filterCategory,
  setFilterCategory,
  filterDevice,
  setfilterDevice,
  setShowSidebar,
}) => {
  // * Lista dropdowns
  const [categories, setCategories] = useState([]);
  const [devicesDropdown, setDevicesDropdown] = useState([]);

  const [categorySelected, setCategorySelected] = useState("");
  const [deviceSelected, setDeviceSelected] = useState("");

  useEffect(() => {
    const categoriesCopy = [];
    const devicescopy = [];

    store.categories_media.forEach((category) =>
      categoriesCopy.push({ value: category._id, label: category.name })
    );
    setCategories(categoriesCopy);

    store.devices_media.forEach((device) =>
      devicescopy.push({ value: device._id, label: device.name })
    );
    setDevicesDropdown(devicescopy);
  }, []);

  const handleClearFilter = () => {
    setFilterCategory("");
    setfilterDevice("");

    setCategorySelected("");
    setDeviceSelected("");
    setShowSidebar(false);
  };

  const handleSubmit = () => {
    setFilterCategory(categorySelected);
    setfilterDevice(deviceSelected);

    setShowSidebar(false);
  };

  return (
    <SidebarContent>
      <div className="form">
        <p>{t("category")}</p>
        <Dropdown
          height={36}
          placeholder={""}
          value={categorySelected}
          onChange={setCategorySelected}
          options={categories}
        />

        <p className="mrgn-top-30">{t("devices")}</p>
        <Dropdown
          height={36}
          placeholder={""}
          value={deviceSelected}
          onChange={setDeviceSelected}
          options={devicesDropdown}
        />
      </div>

      <Button width="100%" label={t("apply_filters")} onClick={handleSubmit} />
      <Button width="100%" type="ghost" label={t("clear_filters")} onClick={handleClearFilter} />
    </SidebarContent>
  );
};

export default connect(FilterSidebar);
