import axios from "axios";

// LAYOUT
const LOGIN = "/login";
const RECOOVERY = "/user/recoverypass/platform";

// PROFESIONALES
const CREATE_PROFESIONAL = "/user/create";
const GET_ALL_TERAPHIST = "/user/getalltherapist";
const GET_ALL_MEDICS = "/user/getallmedics";
const GET_ALL_ADMIN_CLINICS = "/user/getalladminclinic";
const DELETE_PROFESIONAL = "/user/deleteuser/";
const UPDATE_PROFESIONAL = "/user/updateuser/";

// ADMIN
const GET_ALL_ADMINS = "/user/getalladmin";
const CREATE_ADMIN = "/user/create";
const DELETE_ADMIN = "/user/deleteuser/";
const UPDATE_ADMIN = "/user/updateuser/";

// SPECIALITIES
const GET_ALL_SPECIALITIES = "/speciality";

// SUBSIDIARIES
const GET_ALL_SUBSIDIARIES = "/subsidiary";
const CREATE_SUBSIDIARIES = "/subsidiary";
const EDIT_SUBSIDIARIES = "/subsidiary/";
const DELETE_SUBSIDIARIES = "/subsidiary/";

// SERVICES
const CREATE_SERVICE = "/services";
const GET_ALL_SERVICE = "/services";
const UPDATE_SERVICE = "/services/";
const DELETE_SERVICE = "/services/";

// PATIENTS
const GET_ALL_PATIENTS = "/user/getallpatients";
const GET_ALL_PATIENTS_SUMMARY = "/user/getallpatientssummary";
const GET_PATIENT_ID = "/user/patient/";
const GET_RECORDS_PATIENT = "/user/getallpatients/";
const CREATE_PATIENS = "/user/create";
const UPDATE_PATIENTS = "/user/updateuser/";

// SALES
const CREATE_SALE = "/sales";
const GET_ALL_SALE = "/sales";
const GET_SALE_BY_ID = "/sales/by/id/";

// EVENTS
const GET_SALE_BY_PATIENT_ID = "/events/";
const GET_DISPONIBILIDAD = "/event/disponibilidad/";
const GET_SUCURSALES = "/event/sucursales/";
const UDPDATE_EVENT = "/event/";
const DELETE_EVENT = "/event/";
const GET_EVENTS_BY_PROFESSIONAL = "/event/professional/";
const BLOCKING_EVENT = "/event/blocking/";
const EVENTS_CHANGE_STATUS = "/events/change/status/";
const GETALL_EVENTS = "/events/all";
const GETALL_EVENTS_USER = "/events/user/";
const GETALL_AVAILABLE = "/events/available/";
const GETALL_RESOURCE_BLOK = "/events/resource/";
const SCHEDULE_EVENT = "/events/schedule";
const EVENTS_BY_PATIENT_SCHEDULE = "/events/schdule/patient/";
const EVENTS_BY_PATIENT_SERVICES = "/events/services/patient/";
const EVENTS_BY_PATIENT_ALL_SERVICES = "/events/all/services/patient/";
const EVENTS_BY_PATIENT_PROFESSIONALS = "/events/professional/patient/";
const DELETE_BLOCKING = "/event/blocking/";
const CHANGE_PUT_STATUS_EVEN = "/events/status/";
const GET_EVENT_BY_ID_INFO = "/event/by/id/";

// RESOURCES
const GET_RESOURCES = "/boxes";
const POST_RESOURCES = "/boxes";
const DELETE_RESOURCES = "/boxes/";
const PUT_RESOURCES = "/boxes/";
const GET_RESORCES_BY_SUBSIDIARY = "/boxes/by/suc/";

// PROTOCOLS
const GET_ALL_PROTOCOLS = "/training/protocol";

// PROTOCOLS SESIONS
const GET_ALL_PROTOCOLS_SESSIONS = "/training/session/params?protocol=";
const GET_ALL_PROTOCOLS_SESSIONS_PLANS =
  "/training/session/params?empty=1&protocol=";

// PLANNIGS
const CREATE_PLANNING = "/user/calendar/planning";
const GET_ALL_PLANNINGS_BY_PATIENT = "/user/calendar/patiente/planning/";
const CHANGE_STATUS_SESSION_PLANNING = "/plann/change/status/";

// EVALUAIONS_PLANNING
const GET_EVALUATION_PLANNIG = "/user/evaluations/";

// DASHBOARD
const GET_DASHBOARD = "/statistics/patients/plannings/";

// STATISTICS
const GET_STATISICS_PLANNINGS = "/statistics/patients/plannings";
const GET_DATA_CHART = "/professional/tendencia/day/";
const GET_DATA_CHART_MONTH = "/professional/tendencia/month/";

// EVALUATIONS
const GET_EVALUATIONS = "/evaluations";

// RECORDS
const CREATE_NEW_RECORDS = "/records/create";

// FOLLOW
const PATIENT_FOLLOW = "/follow";
const PATIENT_FOLLOW_PAGINATED = "/follow/";
const FOLLOW_COMPLIANCE = "/follow/";
const FOLLOW_CHART_EVALUATION = "/follow/chart/evaluation/";
const FOLLOW_EVENTS_PLANNINGS = "/follow/eventsandplannings/";
const FOLLOW_ALL_EVAL_CHART = "/follow/chart/all/evaluations";
const TERMINATE_PLANNING = "/plann/terminate/";
const TERMINATE_PROGRAM = "/sales/terminate/";
const FOLLOW_SCORE_DATA = "/follow/score/";
const PLANNING_EVENTS_FOLLOW = "/planning/events/";
const FOLLOW_PLANS_PROGRAMS = "/follow/planschedule/";

// PROFILE
const UPDATE_PASSWORD_PROFILE = "/user/setpass/";
const UPDATE_PROFILE = "/user/updateuser/";
const NOTIFICATIONS_PROFILE = "/user/notifications/";

// MEDIA
const GET_IMAGES = "/media/images";
const POST_IMAGES = "/media/images";
const GET_FILES = "/media/files";
const POST_FILES = "/media/files";
const GET_VIDEOS = "/media/videos";
const POST_VIDEOS = "/media/videos";
const DELETE_MEDIA = "/media/";

// USERS
const GET_ALL_USER_PATIENTS = "/user/getallpatients";
const GET_ALL_USER_PATIENTS_PAGINATED = "/user/getallpatients";
const GET_USER_RECORDS_BY_ID = "/user/records/";
const GET_USER_EVOLUTION_BY_ID = "/user/evolution/";

// POSITIONING (LIBRERIA MEDIOS)
const GET_ALL_POSITIONING = "/positioning";

const GET_ALL_USERS = "/user/getallusersystem";

class API {
  promises(promises) {
    return axios.all(promises);
  }

  getMediaImages() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_IMAGES)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createMediaImages(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(POST_IMAGES, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getMediaFiles() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_FILES)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createMediaFiles(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(POST_FILES, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getMediaVideos() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_VIDEOS)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createMediaVideos(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(POST_VIDEOS, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  deleteMedia(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_MEDIA}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  setProfile(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${UPDATE_PROFILE}${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  setPasswordProfile(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${UPDATE_PASSWORD_PROFILE}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getNotificationsProfile(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${NOTIFICATIONS_PROFILE}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getEvaluations() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_EVALUATIONS)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createEvaluation(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(GET_EVALUATIONS, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  updateEvaluation(data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${GET_EVALUATIONS}/${data.selectedId}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  deleteEvaluation(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${GET_EVALUATIONS}/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getAllMedics() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_MEDICS)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getAllAdminClinics() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_ADMIN_CLINICS)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createProfesional(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(CREATE_PROFESIONAL, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  removeProfesional(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_PROFESIONAL}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  updateProfesional(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${UPDATE_PROFESIONAL}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createPatien(data, loading) {
    if (loading) return;
    return new Promise((resolve, reject) => {
      axios
        .post(CREATE_PATIENS, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  updatePatient(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${UPDATE_PATIENTS}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getTherapist() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_TERAPHIST)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getSpeciality(init) {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_SPECIALITIES, init)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createSpeciality( data ) {
    return new Promise((resolve, reject) => {
      axios
        .post( GET_ALL_SPECIALITIES , data )
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  editSpeciality( id, data ) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${GET_ALL_SPECIALITIES}/${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  deleteSpeciality( id ) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${GET_ALL_SPECIALITIES}/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getSubsidiary() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_SUBSIDIARIES)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createSubsidiary(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(CREATE_SUBSIDIARIES, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  editSubsidiary(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${EDIT_SUBSIDIARIES}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  deleteSubsidiary(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_SUBSIDIARIES}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createServices(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(CREATE_SERVICE, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getServices() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_SERVICE)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  deleteService(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_SERVICE}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  updateService(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${UPDATE_SERVICE}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getPatiens() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_PATIENTS)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getPatienssummary() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_PATIENTS_SUMMARY)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getPatienId(id, init) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_PATIENT_ID}${id}`, init)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  createSale(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(CREATE_SALE, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getSales() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_ALL_SALE)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getSalesById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_SALE_BY_ID}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getSalesByPatientId(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_SALE_BY_PATIENT_ID}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getDisponibilidad(id, body) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GET_DISPONIBILIDAD}${id}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getSucursalesByProfesional(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_SUCURSALES}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  updateEvent(id, body) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${UDPDATE_EVENT}${id}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getServiceTypes() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/resourcestypes`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  createServiceTypes( data ) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/resourcestypes`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  editServiceTypes( id, data ) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/resourcestypes/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }
  
  deleteServiceTypes( id ) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/resourcestypes/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }


  getResources() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_RESOURCES}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  createResource(body) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${POST_RESOURCES}`, body)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  deleteResource(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_RESOURCES}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  updateResource(id, body) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${PUT_RESOURCES}${id}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getResourcesBysubsidary(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_RESORCES_BY_SUBSIDIARY}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  deleteEvent(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_EVENT}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getEventsByProfesional(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_EVENTS_BY_PROFESSIONAL}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  createBlockingEvent = (id, body) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BLOCKING_EVENT}${id}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };

  eventChangeStatus = (id, body) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${EVENTS_CHANGE_STATUS}${id}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };

  getBlockings(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BLOCKING_EVENT}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getProtocols() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_PROTOCOLS}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  createProtocol( form ) {
    return new Promise((resolve, reject) => {
      axios
        .post("/training/create/protocol", form)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  editProtocol( id, form ) {
    return new Promise((resolve, reject) => {
      axios
        .put( `/training/update/protocol/${id}`, form )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  deleteProtocol( id ) {
    return new Promise((resolve, reject) => {
      axios
        .delete( `/training/delete/protocol/${id}` )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  cloneProtocol(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/training/clone/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getProtocolsSession(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_PROTOCOLS_SESSIONS}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  createProtocolsSession(form) {
    return new Promise((resolve, reject) => {
      axios
        .post( "/training/create/session", form )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  editProtocolsSession(id, form) {
    return new Promise((resolve, reject) => {
      axios
        .put( `/training/session/edit/${id}`, form )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  deleteProtocolsSession(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete( `/training/delete/session/${id}` )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  cloneSession( idSession ) {
    return new Promise((resolve, reject) => {
      axios
        .get( `/training/clone/session/${idSession}` )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  cloneSessionToOtherProtocol( idProtocol, idSession ) {
    return new Promise((resolve, reject) => {
      axios
        .get( `/training/copy/${idProtocol.value}/${idSession}` )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getProtocolsSessionPlans(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_PROTOCOLS_SESSIONS_PLANS}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  createPlanning(body) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${CREATE_PLANNING}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  changeSessionPlanning(id, body) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${CHANGE_STATUS_SESSION_PLANNING}${id}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getAllPlanningByPatient(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_PLANNINGS_BY_PATIENT}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getEvaluationPalnning(rut) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_EVALUATION_PLANNIG}${rut}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getRecords(id, body) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GET_RECORDS_PATIENT}${id}`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getDashboard(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_DASHBOARD}${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getStatisticsPlannings(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_STATISICS_PLANNINGS}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getDataChart(id, day) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_DATA_CHART}${id}/${day}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getDataChartMonth(id, month) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_DATA_CHART_MONTH}${id}/${month}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  login(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${LOGIN}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  recovery(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${RECOOVERY}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  createRecord(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${CREATE_NEW_RECORDS}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  getAllAdmins() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_ADMINS}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  createAdmin(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${CREATE_ADMIN}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  removeAdmin(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_ADMIN}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  updateAdmin(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${UPDATE_ADMIN}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getFollowPatient() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${PATIENT_FOLLOW}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getFollowPatientDate(data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${PATIENT_FOLLOW}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getFollowPatientDatePaginated(page, limit, data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${PATIENT_FOLLOW_PAGINATED}${page}/${limit}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getFollowChartPatient(id, data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${FOLLOW_SCORE_DATA}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getFollowComplaince(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${FOLLOW_COMPLIANCE}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getFollowCharteval(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${FOLLOW_CHART_EVALUATION}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getEventsAndPlanningsByUser(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${FOLLOW_EVENTS_PLANNINGS}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getAllEvalsChart() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${FOLLOW_ALL_EVAL_CHART}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  terminateProgram(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${TERMINATE_PROGRAM}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  terminatePlanning(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${TERMINATE_PLANNING}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getAllUserPatients() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_USER_PATIENTS}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
  getAllUserPatientsPaginated(page, limit, search = "") {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_USER_PATIENTS_PAGINATED}/${page}/${limit}?search=${search}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
  getUserRecordsById(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GET_USER_RECORDS_BY_ID}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
  getUserEvolutionById(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GET_USER_EVOLUTION_BY_ID}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getAllPositioning() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_POSITIONING}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  newPositioning(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GET_ALL_POSITIONING}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getPositioningById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_POSITIONING}/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  putPositioningById(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${GET_ALL_POSITIONING}/${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  deletePositioningById(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${GET_ALL_POSITIONING}/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getAllEvents() {
    return new Promise((resolve, reject) => {
      axios
        .get(GETALL_EVENTS)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getEventsByUserID(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GETALL_EVENTS_USER}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getEventsAvailable(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${GETALL_AVAILABLE}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getEventsResourceBlock(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GETALL_RESOURCE_BLOK}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  scheduleEvents(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${SCHEDULE_EVENT}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  scheduleEventsByPatient(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${EVENTS_BY_PATIENT_SCHEDULE}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  scheduleEventsByPatientPost(id, data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${EVENTS_BY_PATIENT_SCHEDULE}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  scheduleServicesByPatient(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${EVENTS_BY_PATIENT_SERVICES}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  scheduleAllServicesByPatient(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${EVENTS_BY_PATIENT_ALL_SERVICES}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  scheduleProfessinoalsByPatient(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${EVENTS_BY_PATIENT_PROFESSIONALS}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  deleteBlocking(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${DELETE_BLOCKING}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  changeStatusEvent(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${CHANGE_PUT_STATUS_EVEN}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getEventById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_EVENT_BY_ID_INFO}${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getEventsPlanningByUser(id, data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${PLANNING_EVENTS_FOLLOW}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getPlansAndPrograms(id, data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${FOLLOW_PLANS_PROGRAMS}${id}`, data)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getAllUsers() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_USERS}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getAllUsersPaginated(page, limit, search = "") {
    return new Promise((resolve, reject) => {
      axios
        .get(`${GET_ALL_USERS}/${page}/${limit}?search=${search}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  assignCatPatient(id_patient, id_evolution, id_category) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/cat_variables/assign/${id_patient}/${id_evolution}/${id_category}`)
        .then((res) => resolve(res.data))
        .catch((err) => {
          console.error("Error POST:", err);
          reject(err)
        });
    });
  }

  getCategoryPatientId(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cat_variables/assignables/${id}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getCatVariablesWithEval() {
    return new Promise((resolve, reject) => {
      axios
        .get("/cat_variables/withevaluations")
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getCatVariablesID(id_category) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cat_variables/withevaluations/${id_category}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getCatVariablesAssignables(id_patient) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/cat_variables/assignables/${id_patient}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }

  getCategoriesEvaluations = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/cat_variables`)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
  }

  createCategoriesEvaluations = ({ name, desc, assignable, evaluations }) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/cat_variables`, { name, desc, assignable, evaluations })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
  }

  deleteCategoriesEvaluations = ( _id ) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/cat_variables/${_id}`)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
  }
}

export default API;
