import React, { useEffect, useState, useRef } from "react";
import "../../assets/img/icomoon/style.css";
import { Grid, TextField } from "@material-ui/core";
import ModalSimple from "../../components/modalSimple";
import { getUserId } from "../../lib/router";
import { connect } from "../../store";
import { useTranslation } from "react-i18next";
import LoaderContainer from "../../components/loader/container";
import DropdownSelect from "../../components/dropdown-select";
import Main from "../../components/main";
import Calendar from "../../components/calendar/old";
import API from "../../services/api";

const api = new API();

let dateContraints = new Date();
dateContraints.setDate(new Date().getDate() - 1);

const CalendarView = (props) => {
	const { t, i18n } = useTranslation("plannings");
	const [load, setLoad] = useState(true);
	const [sessions, setSessions] = useState([]);
	const [events, setEvents] = useState([]);
	const [protocols, setProtocols] = useState([]);
	const [openNotes, setOpenNotes] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [protocolsSelect, setProtocolsSelect] = useState("0000");
	const [note, setNote] = useState("");
	const [nameplanning, setNamePlanning] = useState("");
	const [recommended, setrecommended] = useState(false);
	const [recommendedProtocols, ] = useState([]);
	// const [recommendedActive, setrecommendedActive] = useState(true);
	const [patients, setPatienst] = useState([]);
	const [patient, setPatient] = useState(null);
	const [schedules, setsShedules] = useState([]);
	const [loadProtocol, setLoadProtocol] = useState(false);
	const [inProcess, setInProcess] = useState(false);

	const clearFields = () => {
		setPatient(null);
		setEvents([]);
		setsShedules([]);
		setProtocolsSelect(null);
		setSessions([]);
		setNote("");
		setNamePlanning(null);
	};

	const drag = useRef();
	const buttons = {
		add: {
			text: t("create"),
			click: () => setOpenConfirm(true),
		},

		note: {
			text: t("note"),
			click: () => setOpenNotes(true),
		},
	};

	// dayGridDay
	const headers = {
		left: "prev,next today dayGridWeek,dayGridMonth",
		center: "title",
		right: "note add",
	};

	const protocolToSelect = (data) => {
		let result = [];
		data.map((e) =>
			result.push({ value: e._id, label: e.name + " | " + e.fase })
		);
		setProtocols(result);
	};

	const getPatientParams = (id) => {
		api.getPatienId(id)
			.then(response => {
				setPatient({ ...response.data, _id: id, value: id, label: response.data.name + " " + response.data.lastname + " | " + response.data.rut })
				getScheduleUSer(id);
			})
			.catch(err => console.log(err));
	}

	const getProtocol = () => {
		api
			.getProtocols()
			.then((res) => {
				if (res.success) {
					protocolToSelect(res.data);
					setLoad(false);
				}
			})
			.catch((err) =>
				props.notify({ type: "error", title: "Error", text: err.toString() })
			);
	};

	const getSessions = (id) => {
		setLoadProtocol(true);
		api
			.getProtocolsSessionPlans(id)
			.then((res) => {
				setLoadProtocol(false);
				if (res.success) setSessions(res.data);
			})
			.catch((err) => {
				setLoadProtocol(false);
				props.notify({ type: "error", title: "Error", text: err.toString() });
			});
	};

	useEffect(() => {
		getProtocol();
		getUser();
		props.setActivePromp(false);
		if (props.match.params.id) {
			getPatientParams(props.match.params.id);
		}
	}, []);

	const eventDragMount = ({ title, id, event }) => {
		return {
			title: title,
			groupId: Math.random().toString(36).slice(-8),
			id: Math.random().toString(36).slice(-8),
			sessionId: id,
			startTime: "00:00",
			create: false,
			data: event,
		};
	};

	const evenDrop = (data) => {
		const { event } = data;
		const { startStr, extendedProps } = event;
		const { idTemp } = extendedProps;

		let ev = Object.assign([], events);
		let i = 0;
		while (i < ev.length) {
			if (ev[i].idTemp === idTemp) ev[i]["start"] = startStr;
			i++;
		}
		setEvents(ev);
	};

	const getUser = () => {
		api
			.getPatienssummary()
			.then((res) =>
				setPatienst(
					res.data.map((e) => {
						e["label"] = e.name + " " + e.lastname + " - " + e.rut;
						e["value"] = e._id;
						return e;
					})
				)
			)
			.catch((err) =>
				props.notify({ type: "error", title: "Error", text: err.toString() })
			);
	};

	const createPlanification = () => {
		setOpenConfirm(false);
		if (nameplanning == null || nameplanning.length === 0 || nameplanning === "") {
			props.notify({
				type: "error",
				title: t("plannings"),
				text: t("errName"),
			});
			return false;
		}

		if (patient == null) {
			props.notify({
				type: "error",
				title: t("plannings"),
				text: t("errPtient"),
			});
			return false;
		}

		if (!inProcess) {
			setInProcess(true);
			addPlanification(patient._id)
				.then((res) => {
					props.setActivePromp(false);
					clearFields();
					setInProcess(false);
					props.notify({
						type: "success",
						title: t("plannings"),
						text: t("success"),
					});
				})
				.catch((err) => {
					props.notify({ type: "error", title: t("plannings"), text: t("err") })
					setInProcess(false);
				});
		}

	};

	const addPlanification = (userId) => {
		const f = new FormData();
		f.append("name", nameplanning);
		f.append("user", userId);
		f.append("profesional", getUserId().userId);
		f.append("notes", note);
		f.append("data", JSON.stringify(events.map((e) => ({ idSession: e.id, date: e.date, ...e }))));

		return api.createPlanning(f);
	};

	const changeProtocol = (id) => {
		getSessions(id.value);
		setProtocolsSelect(id);
	};

	const setNotesValue = (value) => {
		setNote(value);
		setOpenNotes(false);
	};

	const editDropEvent = (type, all, event) => {
		let ev = Object.assign([], events);
		props.setActivePromp(true);
		ev.push({
			title: all.title,
			id: all.id,
			idTemp: Math.random().toString(36).slice(-8),
			backgroundColor: "#4666DD",
			textColor: "#FAFAFA",
			borderColor: "#4666DD",
			eventBackgroundColor: "#4666DD",
			eventBorderColor: "#4666DD",
			eventTextColor: "#FAFAFA",
			allDay: false,
			data: all.event,
			start: event.dateStr,
			create: true,
		});
		setEvents(ev);
	};

	const removeEvents = (idTemp) => {
		let s = Object.assign([], events);
		s.splice(
			s.findIndex((i) => i.idTemp === idTemp),
			1
		);
		setEvents(s);
	};

	const getScheduleUSer = (id) => {
		api
			.getAllPlanningByPatient(id)
			.then((res) => {
				let data = [];
				let i = 0;
				while (i < res.data.length) {
					let j = 0;
					while (j < res.data[i].data.length) {
						if (res.data[i].data[j].session) {
							data.push({
								title: res.data[i].data[j].session.name,
								id: i + "-" + j + "-" + res.data[i]._id + "-" + res.data[i].data[j]._id,
								groupId: "bloqueado",
								backgroundColor: res.data[i].data[j].color,
								textColor: "#fff",
								borderColor: res.data[i].data[j].color,
								eventBackgroundColor: res.data[i].data[j].color,
								eventBorderColor: res.data[i].data[j].color,
								eventTextColor: "#fff",
								allDay: false,
								data: {
									planning: res.data[i].name,
									...res.data[i].data[j].session,
								},
								start: res.data[i].data[j].date,
								block: false,
								editable: false,
								droppable: false,
								create: true,
							});
						}
						j++;
					}
					i++;
				}

				setsShedules(data);
			})
			.catch((err) => console.log(err));
	};

	const renderEventContent = (event, props, all) => {

		if (all.view.type === "dayGridMonth") {
			return (
				<div onDoubleClick={() => {
					if (event.groupId !== "bloqueado") removeEvents(props.idTemp);
				}} style={{
					background: all.backgroundColor,
					width: '98%',
					margin: 0,
					padding: '6px',
					borderRadius: '3px',
					display: 'flex',
					alignItems: "center",
					justifyContent: 'flex-start',
				}} >
					<label className="m-0" style={{ color: all.textColor }}>
						<b>{props.data.name}</b>
					</label>
				</div>
			);
		} else if (event.groupId === "bloqueado") {
			return (
				<div className="calendar-item-planning" style={{
					background: all.backgroundColor,
				}} >
					<label className="calendar-item-session-label" style={{ color: all.textColor }}>
						{t("others.calendar.event.session")}:
					</label>
					<p className="ellipsis calendar-item-session-p" style={{ fontSize: 13, color: all.textColor }}>
						<b>{props.data.name}</b>
					</p>
					<p className="ellipsis calendar-item-session-p" style={{ fontSize: 11, color: all.textColor }}>
						{props.data.fullaname}
					</p>
					<label className="calendar-item-session-label" style={{ color: all.textColor }}>
						{t("others.calendar.event.plans")}:
					</label>
					<p className="ellipsis calendar-item-session-p" style={{ fontSize: 11, color: all.textColor }}>
						{props.data.planning}
					</p>
				</div>
			);
		} else {
			return (
				<div className="calendar-item-planning" style={{ background: all.backgroundColor }}>
					<label className="calendar-item-session-label" style={{ color: all.textColor }}>
						{t("others.calendar.event.name")}:
					</label>
					<p className="ellipsis calendar-item-session-p" style={{ fontSize: 13, color: all.textColor }}>
						<b>{props.data.name}</b>
					</p>
					<label className="calendar-item-session-label" style={{ color: all.textColor }}>
						{t("others.calendar.event.complement")}:
					</label>
					<p className="ellipsis calendar-item-session-p" style={{ fontSize: 11, color: all.textColor }}>
						{props.data.fullaname}
					</p>
					<span onClick={() => removeEvents(props.idTemp)} className="icon-trash mt-2" style={{ color: props.textColor ? props.textColor : all.textColor }}></span>
				</div>
			);
		}
	};

	return (
		<Main>
			<Grid container className="mx-3 bg-white card-plataform">
				<Grid item xs={12} className="box-titulo bg-primario d-flex align-items-center">
					<p className="text-left ml-3 m-0 fz-18">{t("title")}</p>
				</Grid>
				<Grid item xs={12} md={3} className="pt-3 px-3">
					<LoaderContainer active={load} heightContent="auto">
						<p className="title-calendar m-0">{t("name")}</p>
						<Grid item xs={12}>
							<TextField
								className="w-100 mb-2"
								id="outlined-multiline-static"
								variant="outlined"
								margin="dense"
								value={nameplanning == null ? "" : nameplanning}
								onChange={(value) => setNamePlanning(value.target.value)}
							/>
						</Grid>
						<p className="title-calendar mt-3 mb-2">{t("patient")}</p>
						<Grid item xs={12}>
							<DropdownSelect
								options={patients}
								placeholder={t("others.placeholders.patients")}
								value={patient}
								onChange={(v) => {
									// getEvaluations(v.rut);
									setPatient(v);
									getScheduleUSer(v._id);
								}}
							/>
						</Grid>
						<p className="title-calendar mb-2 mt-4">{t("protocols")}</p>
						<Grid item xs={12}>
							<DropdownSelect
								options={protocols}
								placeholder={t("others.placeholders.protocols")}
								value={protocolsSelect}
								onChange={changeProtocol}
							/>
						</Grid>
					</LoaderContainer>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start"
						ref={drag}
						className="mt-4"
					>
						{load ? null : (
							<p className="title-calendar mb-3">{t("sessions")}</p>
						)}

						<LoaderContainer
							className="w-100"
							active={loadProtocol}
							heightContent="auto"
						>
							{sessions.length === 0 ? (
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
								>
									{load ? null : (<p className="color-sub-text fz-12">{t("notFound")}</p>)}
								</Grid>
							) : (
								<div className="scroll-list w-100" style={{ maxHeight: "calc(100vh - 385px)" }}>
									{sessions.map((e, i) => {
										return (
											<div
												key={i}
												className="tf-event"
												// calendar-main-planning 
												style={{ background: "#4666DD", borderRadius: 4, padding: 12, color: "#fff", marginBottom: 12 }}
												title={e.name + " | " + e.fullaname}
												id={e._id}
												event={JSON.stringify(e)}
											>
												<label className="calendar-item-session-label m-0 p-0" style={{ fontSize: 12 }}>
													{t("others.calendar.event.name")}:
												</label>
												<p className="ellipsis calendar-item-session-p  m-0 p-0" style={{ fontSize: 13 }}>
													<b>{e.name}</b>
												</p>
												<label className="calendar-item-session-label m-0 p-0" style={{ fontSize: 12 }}>
													{t("others.calendar.event.complement")}:
												</label>
												<p className="ellipsis calendar-item-session-p m-0 p-0" style={{ fontSize: 13 }}>
													<b>{e.fullaname}</b>
												</p>
											</div>
										);
									})}
								</div>
							)}
						</LoaderContainer>
					</Grid>
				</Grid>
				<div className="col-md-9 pl-0">
					<Calendar
						refDrag={drag}
						dragSelector=".tf-event"
						resources={[{ events: events }, { events: schedules }]} //, resourceId: "schedule01", 
						headers={headers}
						buttons={buttons}
						locale={i18n.language}
						expandRows={true}
						onDragable={eventDragMount}
						eventDrop={evenDrop}
						dropEvent={editDropEvent}
						initialView="dayGridWeek"
						renderEvent={renderEventContent}
						eventOverlap={true}
						slotEventOverlap={true}
						selectOverlap={true}
						dayMaxEventRows={3}
						dayMaxEvents={false}
						validRange={{}}
						selectConstraint={{
							start: dateContraints
						}}
					/>
				</div>

				<ModalSimple
					open={openNotes}
					title={t("note")}
					textCancel={t("cancel")}
					textAction={t("accept")}
					action={() => setNotesValue(note)}
					cancel={() => setOpenNotes(false)}
				>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<TextField
								className="w-100 mb-2"
								id="outlined-multiline-static"
								multiline
								minRows="4"
								variant="outlined"
								defaultValue={note}
								onChange={(value) => setNote(value.target.value)}
							/>
						</Grid>
					</Grid>
				</ModalSimple>

				<ModalSimple
					open={openConfirm}
					title={t("create")}
					textCancel={t("cancel")}
					textAction={t("accept")}
					action={() => createPlanification()}
					cancel={() => setOpenConfirm(false)}
				>
					<p className="color-sub-text">{t("quetion")}</p>
				</ModalSimple>

				<ModalSimple
					onlyCancel={true}
					open={recommended}
					title="Protocolos recomendados"
					textCancel={t("cancel")}
					textAction={"Aceptar"}
					action={() => { }}
					cancel={() => {
						setrecommended(false);
					}}
				>
					<Grid container>
						<Grid item xs={12} className="mb-4">
							<p className="color-tit-text mb-1">{t('others.alert_recommendation')}</p>
							<br />
							<table className="table table-responsive">
								<thead>
									<th>{t('others.table_recommendation.name')}</th>
									<th>{t('others.table_recommendation.complement')}</th>
									<th>{t('others.table_recommendation.diagnosis')}</th>
									<th>{t('others.table_recommendation.select')}</th>
								</thead>
								<tbody>
									{recommendedProtocols.map((e) => {
										return (
											<tr>
												<td style={{ width: "100%", color: "#281B65" }}>
													{e.name}
												</td>
												<td style={{ width: "100%", color: "#281B65" }}>
													{e.fase}
												</td>
												<td style={{ color: "#281B65" }}>{e.diagnostico}</td>
												<td>
													<p
														style={{
															color: "#FDAD34",
															fontWeight: "bold",
															cursor: "pointer",
														}}
														onClick={() => {
															changeProtocol({
																label: e.name + " | " + e.fase,
																value: e.id,
															});
															setrecommended(false);
														}}
													>
														{t('others.table_recommendation.button')}
													</p>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</Grid>
					</Grid>
				</ModalSimple>
			</Grid>
		</Main>
	);
};

export default connect(CalendarView);
