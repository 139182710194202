import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Container, Row, Col, MenuDropdown } from "trainfes-components-library";

import * as S from "./sessions.styles";
import * as P from "../Protocolos/protocolo.styles";
import {
  ArrowPatientCardIcon,
  ToolsPatientCardIcon,
} from "../../../ClinicalRecordsPage/components/PatientCard/icons";
import Expand from "../../../../components/expand";

export const Card = ({
  e,
  handleMenuEdit,
  handleMenuDelete,
  handleMenuClone,
  handleCloneToOtherProtocol,
  handleToggle,
  currentSessionId,
}) => {
  const { t } = useTranslation("protocols");

  const [showBody, setShowBody] = useState(currentSessionId === e._id);

  const handleClic = () => {
    if (currentSessionId !== e._id) handleToggle(e);
    setShowBody((prev) => !prev);
  };

  useEffect(() => {
    if (currentSessionId === e._id) {
      setShowBody(true);
    } else {
      setShowBody(false);
    }
  }, [currentSessionId]);

  return (
    <P.CardContainer active={currentSessionId === e._id}>
      <P.CardHeader onClick={handleClic}>
        <div className="header--info">
          <p className="_title">{e.name}</p>
          <p className="_sub-title">{e.fullaname}</p>
        </div>

        <div className={`_icon ${showBody ? "_active" : ""}`}>
          <ArrowPatientCardIcon />
        </div>
      </P.CardHeader>

      {showBody && (
        <P.CardBody>
          <Container>
            <Row>
              <Col xs={12} className="padding-0">
                <P.DescriptionTitle>{t("desc")}</P.DescriptionTitle>
                <hr className="my-1"></hr>
              </Col>

              <Col xs={11} className="padding-0">
                <S.Description
                  dangerouslySetInnerHTML={{
                    __html: e.description,
                  }}
                ></S.Description>
              </Col>
              <Col xs={1}>
                <MenuDropdown
                  icon={
                    <div className="_icon">
                      <ToolsPatientCardIcon />
                    </div>
                  }
                  items={[
                    {
                      anchor: t("formProtocol.edit"),
                      onClick: () => handleMenuEdit(),
                    },
                    {
                      anchor: t("formProtocol.clone"),
                      onClick: () => handleMenuClone(),
                    },
                    {
                      anchor: t("formProtocol.clone_to_other_protocol"),
                      onClick: () => handleCloneToOtherProtocol(),
                    },
                    {
                      anchor: t("formProtocol.delete"),
                      onClick: () => handleMenuDelete(),
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Expand titulo={t("session.files")}>
                <ul className="files-session">
                  {e.files.map((f, i) => {
                    return <li key={i}>{f.name}</li>;
                  })}
                </ul>
              </Expand>
            </Row>
          </Container>
        </P.CardBody>
      )}
    </P.CardContainer>
  );
};
