import styled from "styled-components";

export const PatientCardHeader = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  & p {
    font-weight: 500;
    letter-spacing: 0.850962px;
    margin: 0;
  }

  & > .header--info {
    & > ._title {
      color: #281b65;
      font-size: 16px;
      padding-bottom: 6px;
      font-weight: 500;
    }

    & > ._rut {
      color: #fdad34;
      font-size: 14px;
      font-weight: 500;
    }
  }

  & > ._icon {
    display: flex;
    height: 10px;
    transform: rotate(180deg);
    transition: 0.3s;
    width: 10px;
  }

  & > ._active {
    transform: rotate(0deg);
    transition: 0.3s;
  }
`;

export const PatientCardContainer = styled.div`
  border-radius: 4px;
  border: ${({ active }) =>
    active ? "0.5px solid #6d59c9" : "0.5px solid #ced4da"};
  box-shadow: 0px 1px 70px rgba(231, 236, 244, 0.5);
  margin: 10px 14px;
  padding: 14px 20px;

  &:hover {
    border: 1px solid #6d59c9;
  }
`;

export const PatientCardBody = styled.div`
  /* margin-top: 14px; */

  & > ._underline {
    border-bottom: 0.5px solid #ced4da;
  }

  & > ._row {
    display: grid;
    grid-template-columns: minmax(120px, 140px) 1fr 14px;
    place-items: baseline;
    padding: 5px 0;
    position: relative;

    & ._icon {
      cursor: pointer;
      /* position: absolute; */
      /* right: 0; */
      /* top: 5px; */

      &:hover {
        opacity: 0.7;
      }
    }

    & > ._bold {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.850962px;
      color: #281b65;
    }

    & > ._bold-14 {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.850962px;
      color: #281b65;
    }

    & > ._header {
    margin-bottom: 4px;
    }

    & ._th {
      color: #182024;
      font-weight: 400;
      letter-spacing: 0.850962px;
    }

    & > span {
      font-size: 12px;
    }
  }

  & > .__mb-20 {
    margin-bottom: 20px;
  }
`;

export const ExpandComponentStyled = styled.div`
  & p {
    margin: 0;
  }

  & > ._header {
    align-items: center;
    border-bottom: 0.5px solid #ced4da;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    & > ._title {
      color: #182024;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.850962px;
    }

    & > ._icon {
      display: flex;
      height: 10px;
      transform: rotate(180deg);
      transition: 0.3s;
      width: 10px;
    }
    & > ._active {
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }

  & ul {
    list-style: none;
    margin: 0;
    padding: 5px 0;

    & > li {
      font-weight: 400;
      font-size: 11px;
      letter-spacing: 0.850962px;
      color: #182024;
    }

    & > li > span {
      font-weight: 300;
      font-size: 11px;
      letter-spacing: 0.850962px;
      color: #9aa2a8;
    }
  }
`;