import React, { Component } from 'react';
import Axios from 'axios';
import { Grid, TextField } from '@material-ui/core';
import { connect } from '../../../../store';
import { withTranslation } from 'react-i18next';


class PatientsData extends Component {
    constructor() {
        super();
        this.state = {
            data: {}
        }
    }

    componentDidMount() {
        const { t } = this.props;
        Axios.get('/user/patient/' + this.props.id)
            .then(res => {
                if (res.data.success) {
                    this.setState({ data: res.data.data });
                }
            })
            .catch(err => {
                this.props.notify({ type: 'error', title: "Error", text: t('notify.error_data') })
            })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="bg-white mt-5 ds">
                <Grid container spacing={3} className="px-4 scroll-list">
                    <Grid container direction="row" justifyContent="center" alignItems="center" item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label="RUT"
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.rut || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('name')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.name || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('lastname')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.lastname || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('email')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.email || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('phone')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.phone || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('genre')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.genre || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('country')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.country || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('region')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.region || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('comune')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.commune || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('address')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.address || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('company')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.innsurance_company || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('clinic')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.clinic || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('treating_physician')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.treating_doctor || ""}
                            disabled />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            className="w-100 mb-5"
                            label={t('years_of_injury')}
                            id="outlined-margin-dense"
                            margin="dense"
                            variant="outlined"
                            value={this.state.data.years_injury || ""}
                            disabled />
                    </Grid>
                </Grid>
            </div>
        )
    }
}


export default connect(withTranslation("patient_file")(PatientsData));
