import React from "react";
import { Route, Switch } from 'react-router-dom';
import ViewFlowTeleton from "../../../features/teleton/flow.teleton.view";
import Navbar from "./teleton.navbar";
import { withRouter } from "react-router-dom";
import Notifications from "../../../components/notifications/index";
import { connect } from "../../../store";
import NoMobile from "../../noMobile";
import { Row, Col } from "../../../components/grilla/grid.component";

const Layout = (props) => {
  return (
    <>
      <section>
        <Row gap={0}>
          <Col xs={12}>
            <div className="mobile"><NoMobile /></div>
          </Col>
          <Col xs={12}>
            <div className="scroll-row no-mobile">
              <Navbar />
              <Switch>
                <Route exact path="/o/teleton/seguimiento">
                  <ViewFlowTeleton />
                </Route>
                <Route path="*">
                  <>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh", flexDirection: "column" }}>
                      <h1 style={{ fontSize: 36, fontWeigth: 600 }}>Ups,La página que buscas no se encuentra disponible</h1>
                    </div>
                  </>
                </Route>
              </Switch>
            </div>
          </Col>
        </Row>
      </section>
      <Notifications />
    </>
  );
};

export default connect(withRouter(Layout));
