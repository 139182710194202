import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box, Paper } from "@material-ui/core";
import PatientsData from "../pages/ClinicalRecordsPage/components/viewsPatients/patientsData";
import { useTranslation } from "react-i18next";
import { Grid, FormControl } from "@material-ui/core";
import { getUserId } from "../lib/router";
import UploadFiles from "../components/uploadFiles/static";
import { ImageQuestion } from '../../src/pages/EvaluationsPage/components/ImagePreview/';
import { connect } from "../store";
import { useHistory } from "react-router-dom";
import Editor from "../components/editor";
import API from "../services/api";

import { RadioButton, Checkbox, Textarea, Dropdown } from "trainfes-components-library";
import { LinearScale } from "../components/linearScale";

import "../assets/css/viewsPatients/medicalVisitStyle.css";
import { useGetEvaluations } from "../hooks/useGetEvaluations";
import styled from "styled-components";
import { useGetCategoriesEvaluations } from "../hooks/useGetCategoriesEvaluations";


const api = new API();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const styles = makeStyles({
  root: {
    zIndex: 0,
    maxWidth: "none",
    color: "#281B65",
    boxShadow: "none",
    height: "52px",
    borderBottom: "4px solid #E7ECF4",
    "&:focus": {
      outline: "none",
    },
  },
  label: {
    textTransform: "capitalize",
  },
});

const DisabledTabs = (props) => {
  const { t } = useTranslation(["patient_file","evaluations"]);
  const classes = styles();

  const { evaluationsDropdown: evaluaciones } = useGetEvaluations();
  const { categoriesEvaluationsDropdown } = useGetCategoriesEvaluations();

  const [value, setValue] = React.useState(0);

  const history = useHistory();
  const [pacient_id, setPacient_id] = useState("");
  const [files, setFiles] = useState([]);
  const [therapist, setTherapist] = useState("");
  const [therapistId, setTherapistId] = useState("");
  const [evaluationCurrent, setEvaluationCurrent] = useState(null);
  const [variables, setVariables] = useState([]);
  const [editor, setEditor] = useState("");

  const [postLoading, setPostLoading] = useState(false)

  useEffect(() => {
    setPacient_id(props.id);
    setTherapist(getUserId().name);
    setTherapistId(getUserId().userId);
    if (props.event) {
      getInfoEventById(props.event);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createRecord = (confirmButtonEvolve) => {
    if(postLoading) return;
    if (editor === "") {
      props.notify({ type: "error", title: "Error", text: t("notify.description_req") });
      setPostLoading(false)
      return;
    }

    const form = new FormData();
    form.append("pacient_id", pacient_id);
    form.append("comment", editor);
    form.append("mode", "visit");
    form.append("therapist", therapist);
    form.append("therapistId", therapistId);
    form.append("evaluations", JSON.stringify(variables));

    if (props.event) {
      form.append("event", props.event);
    }

    files.map((e) => form.append("files", e, e.name));

    setPostLoading(true);
    api
      .createRecord(form)
      .then((res) => {
        if (res.success) {
          if (props.event) {
            api
              .changeStatusEvent(props.event, { status: 2 })
              .then((res) => {
                props.notify({ type: "success", title: t('notify.success'), text: t("notify.successful") });
                setPostLoading(false);
                history.push(`/clinical-records/${pacient_id}`);
              })
              .catch((err) => props.notify({ type: "error", title: t('notify.error'), text: t('notify.errorText') }));
          } else {
            props.notify({ type: "success", title: t('notify.success'), text: t("notify.successful") });
            history.push(`/clinical-records/${pacient_id}`);
          }
        }
      })
      .catch((err) => props.notify({ type: "error", title: t('notify.error'), text: t('notify.errorText') }));
  };

  const getInfoEventById = (id) => {
    // api.getEventById(id)
    // 	.then(res => console.log(res))
    // 	.catch(err => console.log(err))
  };
  
  const addEvaluation = () => {
    if (evaluationCurrent !== undefined) {
      const d = Object.assign([], variables);
      evaluationCurrent["id"] = Math.random().toString();
      d.push(evaluationCurrent);
      setVariables(d);
    } else {
      props.notify({ type: "error", title: "Error", text: t("notify.select_evaluation") });
    }
  };
  
  const remove = (id) => {
    const d = Object.assign([], variables);
    d.splice(
      d.findIndex((r) => r.id === id),
      1
    );
    setVariables(d);
  };

  const [selected, setSelected] = useState([]); 

  const setDataType = (id, i, e, v) => {
    let d = Object.assign([], variables);
    let a = d.find((e) => e._id === id);
    let k = a.variables.find((r) => r._id === i);
    if(e.type === 'checkboxes') {
      k["result"] = selected; 
    } else {
      k["result"] = v;
    }
    setVariables(d);
  };

  const modes = [
    { name: "Normal", cod: 0 },
    { name: "Principal", cod: 1 },
    { name: "Orden", cod: 2 },
  ];

  const EVAL_OPTIONS = { 
    "multi_select": t("evaluations:multi_select"),
    "checkboxes": t("evaluations:checkboxes"),
    "dropdown": "Dropdown",
    "paragraph": t("evaluations:paragraph"),
    "bool": t("evaluations:yes/no"),
    "number": t("evaluations:number"),
    "scale_linear": t("evaluations:scale_linear"),
    "string": t("evaluations:paragraph"),
  }

  const typeRender = (e, question) => {
    switch (question.type) {
      case "multi_select":
        return (
          <div>
            <ImageQuestion question={question} />
            {question.data.map(({ name }, i) => (
                <div key={i}> 
                  <RadioButton
                    name={ question.name } 
                    onChange={() => {
                      setDataType(e._id, question._id, question.name, name)
                    }}
                  /> 
                  <span className='margin-left-35'>{ name }</span> 
                </div>
              ))}
          </div>
        )        
    
      case "checkboxes":
        return (
          <div>
            <ImageQuestion question={question} />
            {question.data.map(({ name }, i) => (
                <div key={i}> 
                  <Checkbox 
                    name={question.name}
                    onChange={() => {
                      let selected = Object.assign([], question.result)
                      if (selected.includes(name)) {
                        selected.splice(selected.indexOf(name), 1)
                      } else {
                        selected.push(name)
                      }
                      setSelected(selected)
                      setDataType(e._id, question._id, question.name, selected)
                    }}
                  /> 
                  <span className='margin-left-35'>{ name }</span> 
                </div>
              ))}
          </div>
        )
        
      case "paragraph":
        return ( 
          <div>
            <ImageQuestion question={question} />
            <Textarea 
              placeholder="" 
              onChange={({ target }) => {
                setDataType(e._id, question._id, question.name, target.value)
              }}
            ></Textarea> 
          </div>
        )

      case "string":
        return (
          <div>
            <ImageQuestion question={question} />
            <Textarea
              placeholder=""
              onChange={({ target }) => {
                setDataType(e._id, question._id, question.name, target.value)
              }}
            ></Textarea>
          </div>
        )

      case "textarea":
        return (
          <div>
            <ImageQuestion question={question} />
            <Textarea
              placeholder=""
              onChange={({ target }) => {
                setDataType(e._id, question._id, question.name, target.value)
              }}
            ></Textarea>
          </div>
        )
      
      case "dropdown":
        return ( 
          <div>
            <ImageQuestion question={question} />
            <Dropdown 
              placeholder={t("evaluations:select")} 
              options={ 
                question.data.map(({ name }) => { return { label: name, value: name } }) 
              }
              onChange={(el) => {
                setDataType(e._id, question._id, question.name, el.value)
              }}
            /> 
          </div>
        )  
      
      case "bool":
        return ( 
          <>
            <ImageQuestion question={question} />
            <div> 
              <RadioButton 
                name={ question.name }
                onChange={() => {
                  setDataType(e._id, question._id, question.name, true)
                }}
              /> 
              <span className='margin-left-35'>{ t("evaluations:yes") }</span> 
            </div>
            <div> 
              <RadioButton 
                name={ question.name } 
                onChange={() => {
                  setDataType(e._id, question._id, question.name, false)
                }}
              /> 
              <span className='margin-left-35'>{ t("evaluations:no") }</span> 
            </div>
          </>
        )  

      case "number":
        return ( 
          <div>
            <ImageQuestion question={question} />
            <Textarea 
              placeholder="" 
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                }
              }}
              onChange={({ target }) => {
                setDataType(e._id, question._id, question.name, target.value)
              }} 
            ></Textarea> 
          </div>
        ) 
      
      case "scale_linear":
        return (
          <>
            <ImageQuestion question={question} />
            <LinearScale question={question} />
          </>
        )
      default:
        break;
    }
  }

  return (
    <Paper square>
      <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example">
        <Tab className={classes.root} label={t("patient_file")} {...a11yProps(0)} />
        <Tab className={classes.root} label={t("patient_data")} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <div className="bg-white mt-4 mb-3">
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} md={6} className="pl-4">
              <Grid container direction="row">
                <Grid item xs={12} className="mb-3">
                  <Editor value={editor} onChange={setEditor} />
                </Grid>
                <UploadFiles onChange={setFiles} accept="image/png, image/jpeg,.pdf" formats={[ "application/pdf", "image/png", "image/jpeg" ]}/>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className="px-4">
              <Grid container className="head-table2" style={{ height: "184px" }}>
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  className="pl-4"
                >
                  <span style={{ marginRight: '10px' }}>{t('title_select_evaluation')}</span>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ paddingRight: '20px' }}
                >
                  <Grid item xs={12} sm={9} md={9}>
                    <FormControl style={{ width: "90%", marginLeft: '20px' }}>
                      <Dropdown
                        placeholder={t("select_evaluation")}
                        options={evaluaciones.map((e, i) => ({
                          value: e,
                          label: e.label,
                        }))}
                        value={evaluationCurrent}
                        onChange={(e) => {
                          setEvaluationCurrent(e.value);
                        }}
                        noOptionsMessage={t("no_options")}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3} sm={3}>
                    <div
                      style={{ textAlign: "center", minWidth: "100%" }}
                      className="btn-purple-tf"
                      onClick={() => {
                        if (evaluationCurrent) {
                          addEvaluation()
                        } else {
                          props.notify({ type: "error", title: "Error", text: t("notify.eval_req") });
                        }}}
                    >
                      {t("add")}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item xs={12} container direction="row" alignItems="center" className="pt-3">
                {variables.map((e, i) => {
                  const categorySelected = categoriesEvaluationsDropdown.filter((item) => item._id === e.cat)[0]
                  return (
                    <Container key={e.id + i} style={{width: 'inherit'}}>
                      <button
                        className="btn-carrusel d-flex align-items-center justify-content-center"
                        onClick={() => remove(e.id)}
                      >
                        <span className="icon-trash"></span>
                      </button>
                      <p className='__title'><strong>{ e.name }</strong></p>
                      <p><strong>{t("evaluations:category")}</strong>: { categorySelected.name } </p>
                      {e.type ? (
                        <p>
                          <strong>{t("evaluations:type")}</strong>: {modes[e.type].name}
                        </p>
                      ) : (
                        <p><strong>{t("evaluations:type")}</strong>: {t("evaluations:no_type")}</p> 
                      )}
                      <p><strong>{t("evaluations:instructions")}</strong>: { e.instructions ? e.instructions : <i>{t("evaluations:no_description")}</i> }</p>
                      <p><strong>{t("evaluations:desc")}</strong>: { e.desc ? e.desc : <i>{t("evaluations:no_description")}</i> }</p>

                      <section>
                        {
                          e.variables.map((question) => (
                            <div key={question._id} className='__box'>
                              <p className='margin-bottom-0'><strong>{ question.name } | </strong><i>{ EVAL_OPTIONS[question.type] }</i></p>
                              { typeRender(e, question) }
                            </div>
                          ))
                        }
                      </section>
                    </Container>
                  )
                })}
              </Grid>
            </Grid>
            <Grid
              className="p-4"
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <button
                className="btn-white-tf"
                name={t("save")}
                onClick={createRecord}
                variant="contained"
                color="primary"
              >
                {t("save")}
              </button>
            </Grid>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PatientsData id={props.id} />
      </TabPanel>
    </Paper>
  );
};

export default connect(DisabledTabs);

const Container = styled.div`
  p, span {
    font-size: 14px;
  }

  & > .__title {
    font-size: 16px;
  }

  label {
    margin-bottom: 15px;
  }

  .__box {
    border: 0.5px solid #9AA2A8;
    border-radius: 4px;
    padding: 15px 15px 20px 15px;
    margin-bottom: 12px;
  }

  .margin-left-35 {
    margin-left: 35px;
  }

  .margin-bottom-0 {
    margin-bottom: 5px;
  }
`;