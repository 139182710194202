import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from "../../store";
import endopint from "../../services/endpoint";
import "./style.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const onDocumentLoadError = (err) =>
    props.notify({ type: "error", title: "Error al cargar el documento", text: err });

  switch (props.file.format) {
    case ".pdf":
    case "pdf":
      return (
        <div style={{ overflowY: "none", height: 500 }}>
          <Document
            file={props.file.url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>
            Pagina {pageNumber} de {numPages}
          </p>
        </div>
      );

    case "png":
    case "jpg":
      return (
        <div style={{ width: "100%", height: 500 }}>
          <img
            alt=""
            src={endopint + props.file.url}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        </div>
      );

    default:
      return null;
  }
};

export const FileViewerThumnail = (props) => {
  const [, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const onDocumentLoadError = (err) => console.log(err);

  const download = () => {
    var a = document.createElement("a");
    a.href = "data:application/pdf;base64," + props.src;
    let d = new Date();
    let prefix = `Trainfes_files_${d.getDate()}-${d.getMonth()}-${d.getFullYear()}_${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
    a.download = prefix + ".pdf";
    a.click();
  };

  return (
    <div className="downl-container">
      <div className="downl">
        <p onClick={download}>Descargar</p>
      </div>
      <Document
        file={"data:application/pdf;base64," + props.src}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

export const PDFThumnail = (props) => {
  const [, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const onDocumentLoadError = (err) => console.log("Err ->", err);

  return (
    <div className={`${props.className} file-container`}>
      <Document
        file={props.src}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </div>
  );
};

PDFThumnail.defaultProps = {
  className: "",
};

export default connect(FileViewer);

// "data:application/pdf;base64," +
