export const LOADING = "[Evaluations] Loading";

export const SET_CATEGORIES = "[Evaluations] Set Categories";
export const SET_EVALUATIONS = "[Evaluations] Set Evaluations";

export const SET_VARS_DATA = "[Evaluations] Set Variables Data";

export const OPEN_MODAL = "[Evaluations] Open Modals";
export const CLOSE_MODAL = "[Evaluations] Close Modals";

export const OPEN_EDIT = "[Evaluations] Open Edit";
export const OPEN_PREVIEW = "[Evaluations] Open Preview";
export const CLOSE_EDIT = "[Evaluations] Close Edit";

export const REMOVE = "[Evaluations] Delete Evaluation";

export const CLEAR_STATE = "[Evaluations] Clear state";
export const CLEAR_FIELDS = "[Evaluations] Clear fields";
export const SET_INPUT_FIELD = "[Evaluations] Set Input fields";
export const SET_FIELDS = "[Evaluations] Set fields";
export const EDITING_STATE = "[Evaluations] EDITING_STATE";
