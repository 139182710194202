import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import registerModes from "./register";
import ModalModos from "../../components/modalExtended";
import AddSensor from "../../assets/img/bloques/icon-addsensor.svg";
import { connect } from "../../store";
import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";
import { Container, Col, Row } from "../../components/grilla/grid.component";
import { DaD } from "./dragAndDrop";
import {
  TitleBar,
  ColBloks,
  ColFunc,
  ItemBlock,
  IconUseFes,
  IconBlock,
  ItemFlux,
  IndexPosition,
  ColCenter,
  NameItem,
  TitleItems,
  TextItem,
  TextItemTime,
  ButtonIconClose,
  InfoDaDContainer,
  InfoDaDBox,
} from "./styles";
import { validations } from "./helpers/functionsValidations";

const SessionStudio = (props) => {
  const { t } = useTranslation("sessionStudio");
  const container = useRef();
  const icon = useRef();
  const text = useRef();
  // const history = useHistory();
  const [functions] = useState(registerModes(t));
  const [, setSessionId] = useState("");
  const [, setProtocol] = useState("");
  // const [, setDataFunc] = useState(null);
  const [flux, setFlux] = useState([]);
  // const [active, setActive] = useState(null);
  // const [position, setPosition] = useState(null);
  const [open, setOpen] = useState(false);
  const [modeModal, setModeModal] = useState("");
  const [data, setData] = useState(null);
  // const [desc, setDesc] = useState("");
  // const [addsensor, setAddsensor] = useState("");
  // const [name, setName] = useState("");
  const [dataModel, setDataModel] = useState(null);

  useEffect(() => getData(), []);

  const getData = () => {
    axios
      .get("/training/get/session/" + props.match.params.id)
      .then((res) => {
        setFlux(res.data.data.trainings);
        setProtocol(res.data.data.protocol);
        setSessionId(res.data.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeBackgroundColor = (open) => {
    if (open) {
      container.current.style.background = "#EEF2FF";
      if (icon.current != null) {
        icon.current.style.color = "#fff";
        text.current.style.color = "#fff";
      }
    } else {
      container.current.style.background = "#fff";
      if (icon.current != null) {
        icon.current.style.color = "#ccc";
        text.current.style.color = "#ccc";
      }
    }
  };

  const openParams = (data, typeAction = "create") => {
    data["typeAction"] = typeAction;
    setDataModel(null);
    setData(data);
    setOpen(true);
    setModeModal(data.mode);
  };

  const append = (data) => {
    props.setActivePromp(true);
    data["status"] = "modified";
    let _flux = Object.assign([], flux);

    if (data.typeAction === "create") _flux.push(data);

    if (data.typeAction === "edit") {
      _flux = _flux.map((e) => {
        if (e.id === data.id) return { ...e, ...data };
        else return e;
      });
    }

    setFlux(_flux);
    setOpen(false);
  };

  const saveModAndCreate = () => {
    // const validations = require(`../../features/modes/${data.mode}/validation.mode.js`).default;

    if (validations(dataModel, props.notify, t)) {
      data["params"] = dataModel;
      data["name"] = dataModel["name"];
      data["addsensor"] = dataModel["addsensor"];
      data["desc"] = dataModel["description"];
      data["files"] = dataModel["files"];
      if (data.mode === "video") data["videos"] = dataModel["videos"];
      append(data);
    }
  };

  // Revisar
  const titleModal = (mode) => {
    for (let i = 0; i < functions.length; i++)
      if (functions[i].mode === mode) return functions[i].name;
  };

  const Comp = () => {
    return (<></>)
  }

  const renderMode = () => {
    if (modeModal !== "") {
      // const Comp = require(`../../features/modes/${modeModal}/config.mode.js`).default;
      return <Comp values={(value) => setDataModel(value)} data={data} />
    }
  };

  const infoDragAndDrop = () => {
    return (
      <InfoDaDContainer>
        <InfoDaDBox>
          <span ref={icon} style={{ fontSize: 35 }} className="material-icons color-secundario">
            touch_app
          </span>
          <p className="color-primario" ref={text} style={{ fontSize: 14 }}>
            {t("dragBlock")}
          </p>
        </InfoDaDBox>
      </InfoDaDContainer>
    );
  };

  const drop = (data, target) => {
    if (data["type"] === "create") {
      openParams(data, "create");
    }

    if (data["type"] === "move") {
      const _flux = Object.assign([], flux);
      const to = _flux.findIndex((e) => e.id === target.id);
      const from = _flux.findIndex((e) => e.id === data.id);
      if (to >= 0 && from >= 0) {
        const _from = _flux.splice(from, 1);
        _flux.splice(to, 0, _from[0]);
        setFlux(_flux);
      }
    }
  };

  const removeItem = (id) => {
    const _flux = Object.assign([], flux);
    const result = _flux.filter((e) => e.id !== id);
    setFlux(result);
  };

  const getContainsFiles = (params) => {
    return params && params.files && params.files.length > 0
      ? t("optionsUI.yes")
      : t("optionsUI.no");
  };

  const getRepetitions = (params) => {
    return params.repeat ? params.repeat : "-";
  };

  const getSeries = (params) => {
    return params.series ? params.series : "-";
  };

  const getOthers = (params) => {
    const fz = params.frecuencia ? params.frecuencia : "-";
    const pwm = params.ancho_pulso ? params.ancho_pulso : "-";
    return `${fz}/${pwm}`;
  };

  const parseTime = ({ hours, minutes, seconds }) => {
    const normalize = (num) => `${num <= 9 ? `0${num}` : num}`;
    return `${normalize(hours)}:${normalize(minutes)}:${normalize(seconds)}`;
  };

  const getTime = (params) => {
    return params.tiempo_trabajo
      ? parseTime(params.tiempo_trabajo)
      : params.time
      ? params.time
      : "-";
  };

  const getNameTec = (params, e) => {
    console.log(e);
    return params.nameTec ? params.nameTec : params.namePublic ? params.namePublic : e.mode;
  };

  const getPublic = (params, e) => {
    return params.namePublic ? params.namePublic : params.nameTec ? params.nameTec : e.mode;
  };

  return (
    <Container style={{ padding: 10 }}>
      <Row gap={0}>
        <Col md={12}>
          <TitleBar>{t("workflow")}</TitleBar>
        </Col>
        <Col md={3}>
          <ColBloks>
            <Row>
              {functions &&
                functions.map((e, i) => {
                  return (
                    <Col
                      md={4}
                      key={i}
                      onDragStart={(ev) =>
                        DaD.onDragStart(ev, e, "create", () => changeBackgroundColor(true))
                      }
                      draggable={true}
                      onDragEnd={(ev) => DaD.onDragEnd(ev, () => changeBackgroundColor(false))}
                    >
                      <ItemBlock>
                        {e.useFes ? (
                          <IconUseFes>
                            <img src={AddSensor} alt=""></img>
                          </IconUseFes>
                        ) : null}
                        <p>{e.name}</p>
                        <IconBlock>
                          <span className={e.icon}></span>
                        </IconBlock>
                      </ItemBlock>
                    </Col>
                  );
                })}
            </Row>
          </ColBloks>
        </Col>
        <Col md={9}>
          <ColFunc
            ref={container}
            onDrop={(ev) => DaD.onDrop(ev, drop)}
            onDragOver={(ev) => DaD.onDragOver(ev)}
          >
            {flux &&
              flux.map((e, i) => {
                return (
                  <Col md={12}>
                    <ItemFlux
                      isNew={e.status && e.status === "modified"}
                      id={e.id}
                      onDragStart={(ev) => DaD.onDragStart(ev, e, "move")}
                      onDragEnd={(ev) => DaD.onDragEnd(ev)}
                      draggable={true}
                    >
                      <Row id={e.id} cols={18}>
                        <ColCenter id={e.id} md={0.5}>
                          <IndexPosition id={e.id}>{i + 1}</IndexPosition>
                        </ColCenter>
                        <ColCenter id={e.id} md={1}>
                          <span id={e.id} className={e.icon}></span>
                        </ColCenter>
                        <ColCenter id={e.id} md={4.5}>
                          <NameItem id={e.id}>{getNameTec(e.params, e)}</NameItem>
                          <TextItem id={e.id} aling="left">
                            {getPublic(e.params, e)}
                          </TextItem>
                        </ColCenter>
                        <ColCenter id={e.id} md={2}>
                          <TitleItems id={e.id}>Archivos</TitleItems>
                          <TextItem id={e.id}>{getContainsFiles(e.params)}</TextItem>
                        </ColCenter>
                        <ColCenter id={e.id} md={2}>
                          <TitleItems id={e.id}>Repeticiones</TitleItems>
                          <TextItem id={e.id}>{getRepetitions(e.params)}</TextItem>
                        </ColCenter>
                        <ColCenter id={e.id} md={2}>
                          <TitleItems id={e.id}>Series</TitleItems>
                          <TextItem id={e.id}>{getSeries(e.params)}</TextItem>
                        </ColCenter>
                        <ColCenter id={e.id} md={2}>
                          <TitleItems id={e.id}>Fr/Pwm</TitleItems>
                          <TextItem id={e.id}>{getOthers(e.params)}</TextItem>
                        </ColCenter>
                        <ColCenter id={e.id} md={2}>
                          <TitleItems id={e.id}>Tiempo</TitleItems>
                          <TextItemTime id={e.id}>{getTime(e.params)}</TextItemTime>
                        </ColCenter>
                        <ColCenter md={1}>
                          <ButtonIconClose onClick={() => openParams(e, "edit")}>
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.84186 1.57907L0 8.42089V9.9998H1.57887L8.42072 3.15794L6.84186 1.57907Z"
                                fill="#ACB7CB"
                              />
                              <path
                                d="M8.42074 0.000251646L7.36816 1.05283L8.94703 2.63169L9.99961 1.57911L8.42074 0.000251646Z"
                                fill="#ACB7CB"
                              />
                            </svg>
                          </ButtonIconClose>
                        </ColCenter>
                        <ColCenter md={1}>
                          <ButtonIconClose onClick={() => removeItem(e.id)}>
                            <span className="icon-close"></span>
                          </ButtonIconClose>
                        </ColCenter>
                      </Row>
                    </ItemFlux>
                  </Col>
                );
              })}

            {flux && flux.length === 0 ? infoDragAndDrop() : null}
          </ColFunc>
        </Col>
      </Row>

      <ModalModos
        open={open}
        setOpen={setOpen}
        callback={() => saveModAndCreate()}
        title={t("parameters") + " " + titleModal(modeModal)}
        btnText={t("save")}
      >
        {renderMode()}
      </ModalModos>
    </Container>
  );
};

export default connect(SessionStudio);
