import {
    UPDATE_VERSION,
    PUSH_NOTIFIACTION,
    REMOVE_NOTIFIACTION,
    USER_PROFILE,
    VIDEOS,
    CAT_MEDIA,
    DEVICE_MEDIA,
    FILES,
    IMAGES,
    GET_ROOMS,
    PROMPT_ACTIVE,
    PROMPT_MESSAGE
} from "./variables";

const defaultState = {
    name: "v1",
    notifications: [],
    user: {},
    videos: [],
    files: [],
    images: [],
    categories_media: [],
    devices_media: [],
    rooms: [],
    active_prompt: false,
    message_prompt: "Tienes datos que no se han guardado"
};

export default function (state = defaultState, { type, payload }) {
    switch (type) {
        case UPDATE_VERSION:
            return Object.assign({}, state, { name: payload });
        case USER_PROFILE:
            return Object.assign({}, state, { user: payload });
        case PUSH_NOTIFIACTION:
            payload['id'] = Math.random().toString(36).slice(-8);
            state.notifications.push(payload);
            return Object.assign({}, state, { notifications: state.notifications });
        case REMOVE_NOTIFIACTION:
            state.notifications.splice(state.notifications.findIndex(e => e.id === payload.id), 1)
            return Object.assign({}, state, { notifications: state.notifications });
        case VIDEOS:
            return Object.assign({}, state, { videos: payload });
        case FILES:
            return Object.assign({}, state, { files: payload });
        case IMAGES:
            return Object.assign({}, state, { images: payload });
        case CAT_MEDIA:
            return Object.assign({}, state, { categories_media: payload });
        case DEVICE_MEDIA:
            return Object.assign({}, state, { devices_media: payload });
        case GET_ROOMS:
            return Object.assign({}, state, { rooms: payload });
        case PROMPT_ACTIVE:
            return Object.assign({}, state, { active_prompt: payload });
        case PROMPT_MESSAGE:
            return Object.assign({}, state, { message_prompt: payload });
        default:
            return state;
    }
}

