import React from 'react';
import styled from 'styled-components';
import { Button } from "trainfes-components-library";

const Grid = ({mode, t, selectedDay}) => {
	const hours = [
		'8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '13 PM', '14 PM', '15 PM', '16 PM', '17 PM', '18 PM', '19 PM', '20 PM', '21 PM', '22 PM', '23 PM'
	]; // ajustar las horas

  const names = ['Francisca', 'Diego', 'Ruben', 'Victor', 'Victoria', 'Maida']; // Los nombres de las columnas
	const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']; // Los nombres de los días
	const selectedDayName = selectedDay.toString().split(' ')[0];
	
  return (
		<>
			<Header>
				<div></div>
				{mode === 'day' ? ( <> <h4>{selectedDayName}</h4> <Button onClick={() => alert("Button clicked!")} /> </> ) : null }
			</Header>

			<GridContainer>
				<CornerCell /> {/* This is for the top-left corner of the grid */}
				
				{/* Displaying Names on the top row */}
				{mode === 'day' ? (
					<>
						{names.map(name => (
						<NameHeader key={name}>
							{name}
						</NameHeader>
						))}
					</>
				) : (
					<>
						{days.map(day => (
							<NameHeader key={day}>
								{day}
							</NameHeader>
						))}
					</>
				)}

				{/* Rows for each hour */}
				{hours.map(hour => (
					<React.Fragment key={hour}>
						<TimeHeader key={hour}>
								{hour}
						</TimeHeader>
						{names.map(name => (
								<Cell key={name}>
										{/* Aquí es donde se colocan las cards (debe ser drag and drop) */}
								</Cell>
						))}
					</React.Fragment>
				))}
			</GridContainer>
		</>
  );
};

export default Grid;

const Header = styled.div`
  display: flex;
  justify-content: space-between;  // Separación máxima entre título y botón
  align-items: center;  // Centrar verticalmente
  padding: 10px 0;
  margin-bottom: 10px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 80px repeat(${props => props.nameCount || 6}, 1fr); 
  border: 1px #CED4DA solid;
`;

const CornerCell = styled.div`
	background-color: #ffffff;
	padding: 5px;
	text-align: center;
	border-right: 1px #CED4DA solid;
	::before {
		content: "HH:MM";
		color: rgba(0, 0, 0, 0.5);
	}
`;

const TimeHeader = styled.div`
  background-color: #ffffff;
	padding: 6px;
  text-align: center;
	border-right: 1px #CED4DA solid;
	color: rgba(0, 0, 0, 0.5);
`;

const Cell = styled.div`
  background-color: #ffffff; 
	border-right: 1px #CED4DA solid;
	border-bottom: 1px #CED4DA solid;
`;

const NameHeader = styled.div`
  background-color: #ffffff;
	color: #281B65;
	font-weight: bold;
  padding: 5px;
  text-align: center;
	border-right: 1px #CED4DA solid;
	border-bottom: 1px #CED4DA solid;
`;