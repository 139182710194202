import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import {
  Col,
  Container,
  Row,
  ButtonCircle,
  SearchBar,
  TableServices,
} from "trainfes-components-library";
import Pagination from "../../components/pagination";

import ModalModos from "../../components/modalExtended/";

import CardLayoutPlatform from "../../components/CardLayoutPlatform";
import * as S from "./users.styles";

import API from "../../services/api";

import { Edit } from "./assets";
import { NewUserModal } from "./components/NewUserModal";

import { initialState, reducer } from "./reducer/reducer";
import * as Action from "./reducer/actions"

import { useForm } from "react-hook-form";
import { fase1, formValidation, FORM_INITIAL_VALUE } from "./users.utils";
import { connect } from "../../store";
import { transformDate } from "../../lib/date.helpers";


const api = new API();

const Users = (props) => {
  const { t } = useTranslation(["users", "create_patient", "create_therapist", "sessionStudio"]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");

  const usersQuery = useQuery({
    queryKey: ["users"],
    queryFn: () => api.getAllUsersPaginated(page, limit, search).then((res) => {
      setTotalPages(res.totalPages)
      return res.data
    }),
    refetchOnWindowFocus: false,
  });

  useQuery({
    queryKey: ["specialities"],
    queryFn: () => api.getSpeciality().then((res) => {
      const formatData = res.data.map((elem) => ({ value: elem._id, label: elem.name }))
      dispatch({ type: Action.SET_FIELD, payload: { field: "specialities", value: formatData } })
      return res.data
    }),
    refetchOnWindowFocus: false,
  });

  if (usersQuery.error) return "An error has occurred: " + usersQuery.error.message;

  const getDataAddress = (payload) => {
    dispatch({ type: Action.GET_DATA_ADDRESS, payload })
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: FORM_INITIAL_VALUE,
  });

  const resetData = () => {
    reset(FORM_INITIAL_VALUE);
    dispatch({ type: Action.RESET_DATA })
  };

  const onSubmit = async (data) => {
    if ( state.isPosting ) return;
    if ( usersQuery.isLoading ) return;

    dispatch({ type: Action.POSTING_DATA, payload: true })

    if( !formValidation( state, props.notify, data, t ) ) return dispatch({ type: Action.SET_FIELD, payload: { field: "isPosting", value: false } })

    let dataToPost = {
      rut:            data.rut.trim(),
      name:           data.name,
      lastname:       data.lastname,
      email:          data.email,
      phone:          data.phone,
      genre:          data.genre,
      rol:            state.currentRol,
      address:        state.address,
      country:        state.country,
      region:         state.region,
      commune:        state.commune,
      specialty:      state.selectedSpecialities,
      other_address:  state.other_address,
      is_available:   state.isUserActive,
      birthdate:      data.birthdate.replaceAll("/", "-"),
    };

    if (state.currentRol === "therapist") {
      dataToPost["schedule"] = state.schedule
      dataToPost["specialities"] = state.selectedSpecialities
    }

    try {
      if (state.isEditing) {
        await api.updateProfesional(state.idToEdit, dataToPost);

        props.notify({
          type: "success",
          title: t("create_therapist:alert.success"),
          text: t("create_therapist:update_success"),
        });
      } else {
        await api.createPatien(dataToPost);

        props.notify({
          type: "success",
          title: t("create_therapist:alert.success"),
          text: t("create_therapist:success"),
        });
      }

      resetData();
      return usersQuery.refetch();

    } catch ({ data }) {
      let errorMessage;
      switch (data.err.code) {
        case 5000:
          errorMessage = t("errors.userExists");
          break;
        case 5002:
          errorMessage = t("errors.userNotExists");
          break;
        case 5001:
          errorMessage = t("errors.userErrDb");
          break;
        case 5020:
          errorMessage = t("errors.rolInvalid");
          break;
        case 5030:
          errorMessage = t("errors.statusPlanningNoValid");
          break;
        default:
          errorMessage = t("errors.unknownError", { code: data.err.code });
      }
      props.notify({
        type: "error",
        title: t("create_patient:notify.err"),
        text: `${errorMessage}`,
      });
    }
    
  };

  const columns = [
    {
      key: "avatar", type: "custom", text: t("users:avatar"), alignContent: "center", alignTitle: "center", width: "auto",
      elem: ({ avatar }) => (
        <S.Avatar effect="blur" height={30} width={30} src={avatar} />
      ),
    },
    {
      key: "rol", type: "custom", text: t("users:rol"), alignContent: "center", alignTitle: "center", maxWidth: "150px", elem: ({ rol }) => <>{t(rol)}</>, width: "auto",
    },
    {
      key: "rut", text: t("users:rut"), alignContent: "center", alignTitle: "center", width: "auto",
    },
    {
      key: "name", text: t("users:first_name"), alignContent: "center", alignTitle: "center", width: "auto",
    },
    {
      key: "lastname", text: t("users:last_name"), alignContent: "center", alignTitle: "center", width: "auto",
    },
    {
      key: "email", text: t("users:email"), alignContent: "center", alignTitle: "center", width: "200px",
    },
    {
      key: "state", type: "custom", text: t("users:state"), alignContent: "center", alignTitle: "center", width: "auto",
      elem: ({ is_available }) =>
        is_available 
        ? ( <S.ActiveChip>{t("users:active")}</S.ActiveChip> ) 
        : ( <S.InactiveChip>{t("users:inactive")}</S.InactiveChip> ),
    },
    {
      key: "controls", text: t("users:actions"), type: "custom", alignContent: "center", alignTitle: "center", width: "auto",
      elem: (data) => (
        <S.IconsContainer>
          <Edit onClick={() => handleEdit(data)} />
        </S.IconsContainer>
      ),
    },
  ];

  const INITIAL_TABLE = [
    {
      id: 0,
      day: t("create_therapist:table.mon"),
      fases: [
        { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
      ],
    },
    {
      id: 1,
      day: t("create_therapist:table.tue"),
      fases: [
        { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
      ],
    },
    {
      id: 2,
      day: t("create_therapist:table.wed"),
      fases: [
        { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
      ],
    },
    {
      id: 3,
      day: t("create_therapist:table.thu"),
      fases: [
        { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
      ],
    },
    {
      id: 4,
      day: t("create_therapist:table.fri"),
      fases: [
        { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
      ],
    },
    {
      id: 5,
      day: t("create_therapist:table.sat"),
      fases: [
        { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
      ],
    },
    {
      id: 6,
      day: t("create_therapist:table.sun"),
      fases: [
        { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
        { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
      ],
    },
  ]

  const [table, setTable] = useState(INITIAL_TABLE);

  const processTable = (schedule) => {
    let sch = [];
    let i = 0;

    for (let k in schedule) {
      sch.push({
        id: i,
        day: k.charAt(0).toUpperCase() + k.charAt(1),
        fases:
          schedule[k]["fases"].length === 0
            ? [
                { fase: 1, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
                { fase: 2, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
                { fase: 3, from: "09:00", to: "18:00", enabledsEnd: fase1, suc: "", active: false },
              ]
            : schedule[k]["fases"],
      });
      i++;
    }
    
    setTable(sch)
    return sch
  };
 
  const handleEdit = (payload) => {
    reset({ ...payload, birthdate: transformDate(payload.birthdate) });

    dispatch({ type: Action.SET_EDIT, payload })
    
    dispatch({ 
      type: Action.SET_FIELD, 
      payload: { 
          field: "schedule", 
          value: payload.schedule 
          ? !Object.keys(payload.schedule).some((e) => e === "dom" || e === "sun") 
            ? INITIAL_TABLE  
            : processTable( payload.schedule ) 
          : INITIAL_TABLE
        } 
    })

  };

  const handleClose = () => {
    reset(FORM_INITIAL_VALUE)
    dispatch({ type: Action.RESET_DATA })
    setTable(INITIAL_TABLE)
  };

  const handleOpenModal = () => dispatch({ type: Action.OPEN_MODAL });

  const handleChangeFilter = (e) => {
    dispatch({ type: Action.SET_FIELD, payload: {
      field: "filter",
      value: e.target.value
    }})

    setSearch(e.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPage(1)
      usersQuery.refetch()
    }, 800)
    return () => clearTimeout(timeoutId)
  }, [search])
 
  useEffect(() => {
    usersQuery.refetch()
  }, [page])

  return (
    <>
      <Container>
        <Row cols={12}>
          <Col xs={12}>
            <CardLayoutPlatform
              title={t("users:users")}
              loading={usersQuery.isLoading}
              floatButton={<ButtonCircle onClick={handleOpenModal} />}
              header={ 
                <S.HeaderContainer>
                  <SearchBar placeholder={t("users:search")} onChange={handleChangeFilter}/>
                </S.HeaderContainer> }
            >
              <S.ViewContainer>
                <TableServices
                  data={usersQuery.data}
                  columns={columns}
                  searchKeys="title mode"
                  autoHeight={false}
                  perPage={limit}
                  pagination={false}
                  filterSelect={false}
                  filterInput={false}
                  perPageSelect={false}
                  textEmptyData={t("users:no_data_to_show")}
                />
                <Pagination
                  currentPage={page}
                  totalPages={totalPages}
                  onPageChange={(newPage) => {
                    setPage(newPage);
                  }}
                />
              </S.ViewContainer>
            </CardLayoutPlatform>
          </Col>
        </Row>
      </Container>
      <ModalModos
        open={state.openModal}
        setOpen={handleOpenModal}
        onClose={handleClose}
        callback={handleSubmit(onSubmit)}
        title={state.isEditing ? t("users:edit_user") : t("users:create_new_user")}
        btnText={t("create_patient:btn_save")}
      >
        <NewUserModal
          t={t}
          watch={watch}
          errors={errors}
          control={control}
          state={state}
          dispatch={dispatch}
          getDataAddress={getDataAddress}
          notify={props.notify}
          table={table}
          setTable={setTable}
        />
      </ModalModos>
    </>
  );
};

export default connect(Users);
