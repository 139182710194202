import React, { useState } from "react";
import { Controller } from "react-hook-form";

import { Dropdown, InputBorder } from "trainfes-components-library";

import { DatePickerComponent } from "../../../../components/DatePickerv2";

import * as S from "../../ClinicalRecords.styles";
import Places from "../../../../components/places";
import { options, tenantIdOption } from "../../../../lib/validation.helpers";
import { FormValidationText } from "../../../../styles/validation.styles";

// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";

export const ModalNewOrEditUserComponent = ({
  isEditing,
  watch,
  handleSubmit,
  errors,
  onSubmit,
  control,
  stateAddress,
  setStateAddress,
  getDataAddress,
  defaultNationalitySelect,
  t,
}) => {
  const [focusPhone, setFocusPhone] = useState(false);

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <S.ModalNewUser>
        <Controller
          control={control}
          name="nationality"
          rules={{ required: isEditing ? false : t("create_patient:notify.state") }}
          render={({ field: { onChange }, value, name, ref }) => (
            <Dropdown
              inputRef={ref}
              name={name}
              placeholder={t("nationality")}
              defaultValue={isEditing ? defaultNationalitySelect : null}
              portal
              options={tenantIdOption}
              onChange={(option) => {onChange(option.value)}}
              value={value}
              movePlaceholderUp={true}
              noOptionsMessage={t("no_options")}
              disabled={isEditing}
            />
          )}
        />
        {errors.tenantId && (
          <FormValidationText>{errors.tenantId.message}</FormValidationText>
        )}

        <Controller
          name="rut"
          control={control}
          rules={{
            required: isEditing ? false : t("create_patient:notify.rut_required"),
          }}
          disabled={isEditing ? false : !watch("tenantId")}
          render={({ field }) => <InputBorder disabled={isEditing} placeholder="Rut" {...field} />}
        />
        {errors.rut && (
          <FormValidationText>{errors.rut.message}</FormValidationText>
        )}

        <Controller
          name="name"
          control={control}
          rules={{ required: t("create_patient:notify.name_required") }}
          render={({ field }) => (
            <InputBorder placeholder={t("name")} {...field} />
          )}
        />
        {errors.name && (
          <FormValidationText>{errors.name.message}</FormValidationText>
        )}

        <Controller
          name="lastname"
          control={control}
          rules={{ required: t("create_patient:notify.surname_required") }}
          render={({ field }) => (
            <InputBorder placeholder={t("last_name")} {...field} />
          )}
        />
        {errors.lastname && (
          <FormValidationText>{errors.lastname.message}</FormValidationText>
        )}

        <Controller
          name="email"
          control={control}
          rules={{
            required: t("create_patient:notify.email_required"),
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t("create_patient:notify.email_required"),
            },
          }}
          render={({ field }) => (
            <InputBorder placeholder={t("email_address")} {...field} />
          )}
        />
        {errors.email && (
          <FormValidationText>{errors.email.message}</FormValidationText>
        )}

        <Controller
          control={control}
          name="phone"
          rules={{
            required: t("create_patient:notify.phone_required"),
            minLength: {
              value: 7,
              message: t("create_patient:notify.phone_required"),
            },
          }}
          render={({ field: { ref, ...field } }) => (
            <S.PhoneInputStyled
              {...field}
              inputExtraProps={{
                ref,
                required: true,
              }}
              country={"cl"}
              countryCodeEditable={false}
              enableSearch
              searchPlaceholder={t("search")}
              specialLabel={t("phone")}
              focus={focusPhone}
              onFocus={() => setFocusPhone(true)}
              onBlur={() => setFocusPhone(false)}
            />
          )}
        />
        {errors.phone && (
          <FormValidationText>{errors.phone.message}</FormValidationText>
        )}

        <Places
          placeholder={t("address")}
          newInput
          label={""}
          value={stateAddress}
          onChangeCoords={getDataAddress}
          onChangeAddress={setStateAddress}
        />

        <Controller
          name="other_address"
          control={control}
          render={({ field }) => (
            <InputBorder
              showRequired={false}
              placeholder="Depto/Block"
              {...field}
            />
          )}
        />
        {errors.other_address && (
          <FormValidationText>
            {errors.other_address.message}
          </FormValidationText>
        )}

        <Controller
          control={control}
          rules={{ required: t("sessionStudio:validation.fields") }}
          name="birthdate"
          render={({ field: { onChange, value }, ref }) => (
            <DatePickerComponent
              asRequired
              inputRef={ref}
              width="100%"
              placeholder={t("birthday")}
              range={false}
              position="top"
              value={
                value && {
                  day: Number(value.split("/")[0]),
                  month: Number(value.split("/")[1]),
                  year: Number(value.split("/")[2]),
                }
              }
              onChange={(val) => {
                onChange(`${val.day}/${val.month}/${val.year}`);
              }}
            />
          )}
        />
        {errors.birthdate && (
          <FormValidationText>{errors.birthdate.message}</FormValidationText>
        )}

        <Controller
          control={control}
          name="genre"
          render={({ field, field: { onChange }, value: outsideValue, name, ref }) => {
            const actualValue = isEditing ? field.value : (field.value || outsideValue);
            return (
              <Dropdown
                inputRef={ref}
                name={name}
                placeholder={t("genre")}
                portal
                options={options(t)}
                onChange={(option) => onChange(option.value)}
                value={actualValue !== null && actualValue !== undefined ? { label: actualValue, value: actualValue } : { label: '', value: '' }}
                movePlaceholderUp={true}
                noOptionsMessage={t("no_options")}
              />
            );
          }}
        />
        {errors.genre && (
          <FormValidationText>{errors.genre.message}</FormValidationText>
        )}

        <Controller
          name="reference"
          control={control}
          render={({ field }) => (
            <InputBorder
              showRequired={false}
              placeholder={t("create_patient:reference")}
              {...field}
            />
          )}
        />
        {errors.reference && (
          <FormValidationText>{errors.reference.message}</FormValidationText>
        )}
      </S.ModalNewUser>
    </form>
  );
};
